import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// material
import { alpha, styled } from "@mui/material/styles";
import {
    Card,
    CardActionArea,
    Grid,
    // Avatar,
    Typography,
    CardContent,
    Tooltip,
} from "@mui/material";
// utils
import { fDate } from "../../../utils/formatTime";
// import { fShortenNumber } from "../../../utils/formatNumber";
//
import SvgIconStyle from "../../SvgIconStyle";
import axios from "axios";

// ----------------------------------------------------------------------

const CardMediaStyle = styled("div")({
    position: "relative",
    paddingTop: "calc(100% * 3 / 4)",
});

const TitleStyle = styled("div")({
    height: 44,
    overflow: "hidden",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
});

// const AvatarStyle = styled(Avatar)(({ theme }) => ({
//     zIndex: 9,
//     width: 32,
//     height: 32,
//     position: "absolute",
//     left: theme.spacing(3),
//     bottom: theme.spacing(-2),
// }));

// const InfoStyle = styled("div")(({ theme }) => ({
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "flex-end",
//     marginTop: theme.spacing(3),
//     color: theme.palette.text.disabled,
// }));

const CoverImgStyle = styled("img")({
    top: "1em",
    bottom: "1em",
    width: "100%",
    height: "100%",
    objectFit: "contain",
    position: "absolute",
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
    post: PropTypes.object.isRequired,
    index: PropTypes.number,
    route: PropTypes.string.isRequired,
};

export default function BlogPostCard({ post, index, route }) {
    const { slug, title } = post.content;
    const createdAt = post.content.created;
    // const author = post.uid;
    const cover = post.thumbnail ? `${post.thumbnail.filePath}` : null;

    const latestPostLarge = false; //index === 0;
    const latestPost = index === 1 || index === 2;

    return (
        <Grid
            item
            xs={12}
            sm={latestPostLarge ? 12 : 6}
            md={latestPostLarge ? 6 : 3}
        >
            <Tooltip title={post.tooltip ? post.tooltip : ""} placement="top">
                <Card sx={{ position: "relative" }}>
                    <CardActionArea component={Link} to={`${route}${slug}`}>
                        <CardMediaStyle
                            sx={
                                {
                                    // ...((latestPostLarge || latestPost) && {
                                    //     pt: "calc(100% * 4 / 3)",
                                    //     "&:after": {
                                    //         top: 0,
                                    //         content: "''",
                                    //         width: "100%",
                                    //         height: "100%",
                                    //         position: "absolute",
                                    //         bgcolor: (theme) =>
                                    //             alpha(theme.palette.grey[900], 0.5),
                                    //     },
                                    // }),
                                    // ...(latestPostLarge && {
                                    //     pt: {
                                    //         xs: "calc(100% * 4 / 3)",
                                    //         sm: "calc(100% * 3 / 4.66)",
                                    //     },
                                    // }),
                                }
                            }
                        >
                            {/* <AvatarStyle
                        alt={author.name}
                        src={author.avatarUrl}
                        sx={{
                            ...((latestPostLarge || latestPost) && {
                                zIndex: 9,
                                top: 24,
                                left: 24,
                                width: 40,
                                height: 40,
                            }),
                        }}
                    /> */}

                            {cover && <CoverImgStyle alt={title} src={cover} />}
                        </CardMediaStyle>

                        <CardContent
                            sx={{
                                pt: 4,
                                // ...((latestPostLarge || latestPost) && {
                                //     bottom: 0,
                                //     width: "100%",
                                //     position: "absolute",
                                // }),
                            }}
                        >
                            <Typography
                                color="text.disabled"
                                gutterBottom
                                variant="caption"
                                sx={{
                                    display: "block",
                                    // ...((latestPostLarge || latestPost) && {
                                    //     color: "common.white",
                                    // }),
                                }}
                            >
                                {fDate(createdAt)}
                                {/* {createdAt} */}
                            </Typography>

                            <TitleStyle
                                color="inherit"
                                variant="subtitle2"
                                underline="hover"
                                sx={
                                    {
                                        // ...(latestPostLarge && {
                                        //     typography: "h5",
                                        //     height: 60,
                                        // }),
                                        // ...((latestPostLarge || latestPost) && {
                                        //     color: "common.white",
                                        // }),
                                    }
                                }
                            >
                                {title}
                            </TitleStyle>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Tooltip>
        </Grid>
    );
}
