import { useNavigate } from "react-router";
// material
import { styled, alpha } from "@mui/material/styles";
import { BlogPostsSearch } from "../../components/dashboard/blog";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled("div")(({ theme }) => ({
    top: 0,
    left: 0,
    zIndex: 99,
    // width: "100%",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    height: APPBAR_MOBILE,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.up("md")]: {
        height: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
    const navigate = useNavigate();

    const handleSearch = (value) => {
        navigate(`/dashboard/search/${value}`);
    };

    return <BlogPostsSearch onSearch={handleSearch} placeholder="Suche..." />;
}
