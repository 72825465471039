import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// material
import {
    Card,
    Typography,
    CardContent,
    CardActionArea,
    CardMedia,
} from "@mui/material";
import { Box } from "@mui/system";
//`
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

RecentUsersCard.propTypes = {
    user: PropTypes.object.isRequired,
    index: PropTypes.number,
};

export default function RecentUsersCard({ user, index, route }) {
    const { name, uid, email } = user;
    // const author = user.uid;
    const cover = "/static/mock-images/avatars/avatar_default.jpg";

    return (
        <Card sx={{ display: "flex" }}>
            <CardActionArea component={Link} to={`/admin/activate/${uid}`}>
                <Box sx={{ display: "flex", p: 1 }}>
                    <CardContent sx={{ width: "100%", flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="div">
                            {name}
                        </Typography>

                        <Typography
                            gutterBottom
                            variant="caption"
                            sx={{ color: "text.disabled", display: "block" }}
                        >
                            {/* {fDate(createdAt)} */}
                            {email}
                        </Typography>
                    </CardContent>
                    {cover && (
                        <CardMedia
                            sx={{ width: "100%", objectFit: "cover" }}
                            component="img"
                            height="200"
                            image={cover}
                            alt="avatar"
                        />
                    )}
                </Box>
            </CardActionArea>
        </Card>
    );
}
