import TabbedPage from "src/components/TabbedPage";

// import User from "src/components/_ashboard/admin/user/User";
import Loadable from "src/components/loader/Loadable";
import { lazy } from "react";

import AdminContent from "src/components/dashboard/admin/Content/AdminContent";

import DashboardApp from "./DashboardApp";
import Emails from "src/pages/Emails";
import UserIssues from "../components/dashboard/admin/UserIssues";
import PopupConfig from "../components/dashboard/admin/Popup/PopupConfig";

const User = Loadable(
    lazy(() => import("src/components/dashboard/admin/user/User"))
);

const Admin = () => {
    return (
        <TabbedPage>
            <DashboardApp label="Dashboard" />
            <User label="Users" />
            <AdminContent label="Content">Content</AdminContent>
            <Emails label="E-mails" />
            <UserIssues label="Issues" />
            <PopupConfig label="Popup" />
        </TabbedPage>
    );
};
export default Admin;
