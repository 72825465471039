import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// material
import {
    Card,
    Typography,
    CardContent,
    CardActionArea,
    CardMedia,
} from "@mui/material";
import { Box } from "@mui/system";
import Truncate from "react-truncate-html";
// utils
import { fDateTime } from "../../../utils/formatTime";
//
// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

RecentCard.propTypes = {
    post: PropTypes.object.isRequired,
    index: PropTypes.number,
    route: PropTypes.string.isRequired,
};

export default function RecentCard({ post, index, route }) {
    const { slug, title, body } = post.content;
    const createdAt = post.content.created;
    // const author = post.uid;
    const cover = post.thumbnail ? `${post.thumbnail.filePath}` : null;

    return (
        <Card sx={{ display: "flex" }}>
            <CardActionArea component={Link} to={`${route}${slug}`}>
                <Box sx={{ display: "flex" }}>
                    <CardContent sx={{ width: "100%", flexGrow: 1 }}>
                        <Typography
                            gutterBottom
                            variant="caption"
                            sx={{ color: "text.disabled", display: "block" }}
                        >
                            {fDateTime(createdAt)}
                            {/* {createdAt} */}
                        </Typography>

                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>

                        <Truncate
                            lines={4}
                            dangerouslySetInnerHTML={{ __html: body }}
                        />
                    </CardContent>
                    {cover && (
                        <CardMedia
                            sx={{ width: "100%", objectFit: "contain" }}
                            component="img"
                            height="200"
                            image={cover}
                            alt={title}
                        />
                    )}
                </Box>
            </CardActionArea>
        </Card>
    );
}
