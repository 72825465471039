import React from "react";
// components
import Page from "../components/Page";
import TabbedPage from "../components/TabbedPage";
import NotificationsList from "./NotificationList";
import { useSelector } from "react-redux";

const NotificationsAll = () => {
    const { user } = useSelector((state) => state.auth);
    return (
        <Page title="Dashboard: All notifications | TZMO DE Extranet">
            {user && user.is_staff === true ? (
                <TabbedPage>
                    <NotificationsList label="Neu" category="new" />
                    <NotificationsList label="Geändert" category="modified" />
                    <NotificationsList
                        label="Neue Benutzeranfragen"
                        category="users"
                    />
                </TabbedPage>
            ) : (
                <TabbedPage>
                    <NotificationsList label="Neu" category="new" />
                    <NotificationsList label="Geändert" category="modified" />
                </TabbedPage>
            )}
        </Page>
    );
};

export default NotificationsAll;
