import axios from "axios";
import authHeader from "src/services/auth-header";
import { refreshAccess, logout } from "src/redux/slices/Auth";
import { showAlert } from "src/redux/slices/AlertSlice";

const dispatchError = (dispatch, error) => {
    const message =
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString();
    dispatch(
        showAlert({
            message: message,
            severity: "error",
        })
    );
};

export const post = async (dispatch, endpoint, body) => {
    const _post = (endpoint, body) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                ...authHeader(),
            },
        };
        return axios.post(`${endpoint}`, body, config).then((response) => {
            return response;
        });
    };

    try {
        const res = await _post(endpoint, body);
        return res;
    } catch (error) {
        if (!error.response) {
            dispatchError(dispatch, "Server unreachable");
            dispatch(logout);
        } else if (error.response.status === 401) {
            // unathorized
            try {
                await dispatch(refreshAccess());
                // re-try
                try {
                    const res = await _post(endpoint, body);
                    return res;
                } catch (error) {
                    dispatchError(dispatch, error);
                    dispatch(logout);
                    throw error;
                }
            } catch (rejectedValueOrSerializedError) {
                throw Error("Your session has expired");
            }
        } else {
            throw error;
        }
    }
};

export const put = async (dispatch, endpoint, body) => {
    const _put = (endpoint, body) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                ...authHeader(),
            },
        };
        return axios.put(`${endpoint}`, body, config).then((response) => {
            return response;
        });
    };

    try {
        const res = await _put(endpoint, body);
        return res;
    } catch (error) {
        if (!error.response) {
            dispatchError(dispatch, "Server unreachable");
            dispatch(logout);
        } else if (error.response.status === 401) {
            // unathorized
            try {
                await dispatch(refreshAccess());
                // re-try
                try {
                    const res = await _put(endpoint, body);
                    return res;
                } catch (error) {
                    dispatchError(dispatch, error);
                    dispatch(logout);
                    throw error;
                }
            } catch (rejectedValueOrSerializedError) {
                throw Error("Your session has expired");
            }
        } else {
            throw error;
        }
    }
};

export const get = async (dispatch, endpoint) => {
    const _get = (endpoint) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                ...authHeader(),
            },
        };
        return axios.get(`${endpoint}`, config).then((response) => {
            return response;
        });
    };

    try {
        const res = await _get(endpoint);
        return res;
    } catch (error) {
        if (!error.response) {
            dispatchError(dispatch, "Server unreachable");
            dispatch(logout);
        } else if (error.response.status === 401) {
            // unathorized
            try {
                await dispatch(refreshAccess());
                // re-try
                try {
                    const res = await _get(endpoint);
                    return res;
                } catch (error) {
                    dispatchError(dispatch, error);
                    dispatch(logout);
                    throw error;
                }
            } catch (rejectedValueOrSerializedError) {
                throw Error("Your session has expired");
            }
        } else {
            throw error;
        }
    }
};
