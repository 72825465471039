import React from "react";
import ChangePasswordForm from "./ChangePasswordForm";

// material
import { styled } from "@mui/material/styles";
import { Box, Card, Link, Container, Typography } from "@mui/material";

// const ContentStyle = styled("div")(({ theme }) => ({
//     maxWidth: 480,
//     margin: "auto",
//     display: "flex",
//     minHeight: "100vh",
//     flexDirection: "column",
//     justifyContent: "center",
//     padding: theme.spacing(12, 0),
// }));

const AccountInformation = () => {
    return (
        <Container maxWidth={false}>
            <ChangePasswordForm />
        </Container>
    );
};

export default AccountInformation;
