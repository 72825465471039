import React, { useState, useEffect } from "react";
// material
import { Alert, AlertTitle, Button } from "@mui/material";
// components
import Loader from "src/components/loader/Loader";
import Page from "../components/Page";
import { useDispatch } from "react-redux";
import { get, post } from "src/services/content.service";
import saveAs from "file-saver";
import { fDateTime } from "../utils/formatTime";
import axios from "axios";

// components

const LicenseGenerator = () => {
    const [license, setLicense] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchLicense = async () => {
            setLoading(true);
            try {
                const res = await get(dispatch, `/api/license/`);
                setLicense({
                    status: true,
                    ...res.data,
                });
            } catch (err) {
                setLicense({ status: false });
            } finally {
                setLoading(false);
            }
        };

        fetchLicense();
    }, []);

    const generate = async () => {
        setLoading(true);
        try {
            const res = await post(dispatch, `/api/license/`);
            setLicense({
                ...res.data,
                status: true,
            });
        } catch (err) {
            setLicense({ status: false });
        } finally {
            setLoading(false);
        }
    };

    const handleClick = () => {
        if (license?.status) {
            saveAs(`${axios.defaults.baseURL}${license.pdf}`, license.name);
        } else {
            generate();
        }
    };

    const renderAlert = () => {
        const valid = license?.status === true || false;
        const created =
            license && license.created ? fDateTime(license.created) : 0;

        return (
            <Alert
                severity={valid ? "success" : "warning"}
                action={
                    <Button color="inherit" size="small" onClick={handleClick}>
                        {valid ? "Herunterladen" : "Generieren"}
                    </Button>
                }
            >
                <AlertTitle>{`Status: ${
                    valid ? "aktiv" : "inaktiv"
                }`}</AlertTitle>
                {valid
                    ? `Die Lizenz ist aktiv. Erzeugt am ${created}`
                    : `Um eine Lizenz zu erstellen, klicken Sie bitte auf die
                Schaltfläche Generieren`}
            </Alert>
        );
    };

    return (
        <Page title="Nutzungslizenz-Generator | TZMO DE Extranet">
            {loading ? <Loader /> : renderAlert()}
        </Page>
    );
};

export default LicenseGenerator;
