import React, { useEffect, useState, useCallback, Fragment } from "react";
import AdminTable from "../utlis/AdminTable";
import Label from "src/components/Label";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "src/components/loader/Loader";
import MoreMenu from "../utlis/MoreMenu";
import ContentDialog from "./ContentDialog";
import { useDispatch } from "react-redux";
import { showAlert } from "src/redux/slices/AlertSlice";
import { post, get } from "src/services/content.service";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { fDateTime } from "../../../../utils/formatTime";

const TABLE_HEAD = [
    { id: "Id", label: "Id", alignRight: false, search: false },
    { id: "Title", label: "Title", alignRight: false, search: true },
    { id: "Type", label: "Type", alignRight: false, search: true },
    { id: "Status", label: "Status", alignRight: false },
    { id: "Created", label: "Created", alignRight: false },
    { id: "Author", label: "Author", alignRight: false },
    { id: "More", alignRight: true },
];

const AdminContent = () => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [contents, setContents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const [slug, setSlug] = useState(null);
    const [contentType, setContentType] = useState(null);
    const [submitStatus, setSubmitStatus] = useState();

    const dispatch = useDispatch();

    const clearDeletedRows = (ids) => {
        let tempContents = [...contents];

        for (const id of ids) {
            const tmpRowIdx = contents.findIndex(
                (content) => content.id === id
            );
            if (tmpRowIdx !== -1) {
                tempContents.splice(tmpRowIdx, 1);
            } else {
                console.log("Content idx not found");
            }
        }

        setContents(tempContents);
        setSelected([]);
    };

    const deleteContent = async (ids) => {
        const body = JSON.stringify({ ids });
        try {
            setLoading(true);
            await post(dispatch, "/api/content/admin/delete/", body);
            clearDeletedRows(ids);
        } catch (err) {
            dispatch(
                showAlert({
                    message: err,
                    severity: "error",
                })
            );
        } finally {
            setLoading(false);
        }
    };

    const editContent = (content) => {
        setSlug(content.slug);
        setContentType(content.type);
        setShowModal(true);
    };

    useEffect(() => {
        if (contents && contents.length) {
            setRows(
                contents.map((row) => ({
                    Slug: row.slug,
                    Id: row.id,
                    Title: row.title,
                    Body: row.body,
                    Type: row.type,
                    Status: (
                        <Label
                            variant="ghost"
                            color={row.status ? "success" : "error"}
                        >
                            {row.status ? "Active" : "Inactive"}
                        </Label>
                    ),
                    Created: fDateTime(row.created),
                    Author: row.uid.name,
                    More: (
                        <MoreMenu
                            options={[
                                {
                                    label: "Edit",
                                    icon: <EditOutlinedIcon />,
                                    handler: () => {
                                        editContent(row);
                                    },
                                },
                                {
                                    label: "Delete",
                                    icon: <DeleteOutlineOutlinedIcon />,
                                    handler: () => {
                                        deleteContent([row.id]);
                                    },
                                },
                            ]}
                        />
                    ),
                }))
            );
        }
    }, [contents]);

    useEffect(() => {
        if (submitStatus !== null && submitStatus !== undefined) {
            let message, severity;
            if (submitStatus) {
                message = "Content updated successfully";
                severity = "success";
            } else {
                message = "Failed to update content";
                severity = "error";
            }
            dispatch(
                showAlert({
                    message: message,
                    severity: severity,
                })
            );
        }
        return () => {
            setSubmitStatus(null);
        };
    }, [submitStatus, dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            setLoading(true);
            try {
                const res = await get(dispatch, `/api/content/admin/all/`);
                setContents(res.data);
            } catch (err) {
                dispatch(
                    showAlert({
                        message: err,
                        severity: "error",
                    })
                );
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dispatch]);

    const handleDeleteSelected = () => {
        deleteContent(selected);
    };

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <ContentDialog
                        type="edit"
                        open={showModal}
                        onClose={() => {
                            setShowModal(false);
                        }}
                        submitStatus={setSubmitStatus}
                        slug={slug}
                        contentType={contentType}
                    />
                    <AdminTable
                        searchPlaceholder="Suche..."
                        headers={TABLE_HEAD}
                        rows={rows}
                        selection={{
                            selected: selected,
                            setSelected: setSelected,
                        }}
                        showChildrenWhenSelected={true}
                        contextMenuOptions={[
                            {
                                label: "Edit",
                                icon: <EditOutlinedIcon />,
                                handler: (id) => {
                                    editContent(contents[id]);
                                },
                            },
                            {
                                label: "Delete",
                                icon: <DeleteOutlineOutlinedIcon />,
                                handler: (id) => {
                                    deleteContent([contents[id].id]);
                                },
                            },
                        ]}
                    >
                        <Tooltip title="Delete">
                            <IconButton
                                color="primary"
                                aria-label="Delete selected"
                                component="span"
                                onClick={handleDeleteSelected}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </AdminTable>
                </Fragment>
            )}
        </Fragment>
    );
};

export default AdminContent;
