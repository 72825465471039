import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Icon } from "@iconify/react";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";

const DownloadButton = ({ options }) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon icon={moreVerticalFill} width={20} height={20} />
            </IconButton>
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: "100%" },
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem
                            key={option.name}
                            onClick={() => {
                                setIsOpen(false);
                                option.handler(option.name);
                            }}
                            disableRipple
                        >
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};
DownloadButton.propTypes = {
    options: PropTypes.array,
};

export default DownloadButton;
