import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showAlert } from "./AlertSlice";
import authHeader from "src/services/auth-header";
import { handleChangePassowrdError } from "./serverErrors";

const loadUser = (token) => {
    // const token = JSON.parse(localStorage.getItem("access"));
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "JWT " + token,
            },
        };

        return axios.get("/auth/users/me/", config).then((response) => {
            return response.data;
        });
    }
};

const loadPopup = (token) => {
    if (token) {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: "JWT " + token,
            },
        };

        return axios.get("/api/popup/", config).then((response) => {
            return response.data;
        });
    }
};

const refreshAccessToken = (refreshToken) => {
    if (refreshToken) {
        const body = JSON.stringify({ refresh: refreshToken });
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        return axios
            .post("/auth/jwt/refresh/", body, config)
            .then((response) => {
                return response.data.access;
            });
    }
};

export const refreshAccess = createAsyncThunk(
    "auth, refreshAccess",
    async (thunkAPI) => {
        const refreshToken = localStorage.getItem("refresh");
        if (refreshToken) {
            try {
                const newAccessToken = await refreshAccessToken(refreshToken);
                const userData = await loadUser(newAccessToken);
                return {
                    user: userData,
                    access: newAccessToken,
                };
            } catch (error) {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                thunkAPI.dispatch(
                    showAlert({
                        message: message,
                        severity: "error",
                    })
                );
                return thunkAPI.rejectWithValue();
            }
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

export const checkAuthenticated = createAsyncThunk(
    "auth/checkAuthenticated",
    async (thunkAPI) => {
        if (localStorage.getItem("access")) {
            const token = localStorage.getItem("access");
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            };

            const body = JSON.stringify({
                token: token,
            });
            try {
                await axios.post("/auth/jwt/verify/", body, config);
                const userData = await loadUser(token);
                return {
                    user: userData,
                };
            } catch {
                const refreshToken = localStorage.getItem("refresh");
                if (refreshToken) {
                    try {
                        const newAccessToken = await refreshAccessToken(
                            refreshToken
                        );
                        const userData = await loadUser(newAccessToken);
                        return {
                            user: userData,
                            access: newAccessToken,
                        };
                    } catch (error) {
                        const message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                        thunkAPI.dispatch(
                            showAlert({
                                message: message,
                                severity: "error",
                            })
                        );
                        return thunkAPI.rejectWithValue();
                    }
                } else {
                    return thunkAPI.rejectWithValue();
                }
            }
        } else {
            return thunkAPI.rejectWithValue();
        }
    }
);

const mapErrorMessage = (message) => {
    if (message.includes("password is too common")) {
        return "Dieses Passwort ist zu häufig.";
    } else if (message.includes("already exists")) {
        return "Es existiert bereits ein Benutzer mit diesem Namen.";
    } else if (message.includes("User with given email does not exist")) {
        return "Der Benutzer mit der angegebenen E-Mail existiert nicht.";
    } else if (message.include("password is too similar to the email")) {
        return "Das Passwort ist der E-Mail zu ähnlich.";
    } else {
        return "Es ist ein Fehler aufgetreten.";
    }
};

export const register = createAsyncThunk(
    "auth/register",
    async (
        {
            name,
            last_name,
            email,
            password,
            re_password,
            customer_number,
            company_name,
            company_address,
            postcode_location,
            phone,
        },
        thunkAPI
    ) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const body = JSON.stringify({
            name,
            last_name,
            email,
            password,
            re_password,
            customer_number,
            company_name,
            company_address,
            postcode_location,
            phone,
        });

        try {
            const res = await axios.post("/auth/users/", body, config);

            return res.data;
        } catch (error) {
            const message =
                error.response && error.response.data
                    ? mapErrorMessage(JSON.stringify(error.response.data))
                    : error.message || error.toString();
            thunkAPI.dispatch(
                showAlert({
                    message: message,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue();
        }
    }
);

export const login = createAsyncThunk(
    "auth/login",
    async ({ username, password }, thunkAPI) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const body = JSON.stringify({ name: username, password });
        try {
            const tokenData = await axios.post(
                "/auth/jwt/create/",
                body,
                config
            );
            const userData = await loadUser(tokenData.data.access);
            const popup = await loadPopup(tokenData.data.access);
            return {
                token: tokenData.data,
                user: userData,
                popup: popup,
            };
        } catch (error) {
            const message = "Ungültiger Benutzername/E-Mail oder Passwort";
            thunkAPI.dispatch(
                showAlert({
                    message: message,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue();
        }
    }
);

export const changePassword = createAsyncThunk(
    "auth/changePassword",
    async ({ current_password, new_password, re_new_password }, thunkAPI) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
            },
        };
        const body = JSON.stringify({
            current_password,
            new_password,
            re_new_password,
        });
        try {
            const res = await axios.post(
                "/auth/users/set_password/",
                body,
                config
            );

            thunkAPI.dispatch(
                showAlert({
                    message: "Passwort erfolgreich geändert",
                    severity: "success",
                })
            );
            return res.data;
        } catch (error) {
            thunkAPI.dispatch(
                showAlert({
                    message: handleChangePassowrdError(error),
                    severity: "error",
                })
            );

            return thunkAPI.rejectWithValue();
        }
    }
);

export const resetPassword = createAsyncThunk(
    "auth/resetPassword",
    async ({ email }, thunkAPI) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
            },
        };
        const body = JSON.stringify({
            email,
        });
        try {
            const res = await axios.post(
                "/auth/users/reset_password/",
                body,
                config
            );

            thunkAPI.dispatch(
                showAlert({
                    message:
                        "Passwort-Reset erfolgreich angefordert.\nBitte überprüfen Sie Ihre Mailbox.",
                    severity: "success",
                })
            );
            return res.data;
        } catch (error) {
            const message =
                error.response && error.response.data
                    ? mapErrorMessage(JSON.stringify(error.response.data))
                    : error.message || error.toString();
            thunkAPI.dispatch(
                showAlert({
                    message: message,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue();
        }
    }
);

export const resetPasswordConfirm = createAsyncThunk(
    "auth/resetPasswordConfirm",
    async ({ uid, token, new_password, re_new_password }, thunkAPI) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                ...authHeader(),
            },
        };
        const body = JSON.stringify({
            uid,
            token,
            new_password,
            re_new_password,
        });
        try {
            const res = await axios.post(
                "/auth/users/reset_password_confirm/",
                body,
                config
            );

            thunkAPI.dispatch(
                showAlert({
                    message: "Das Passwort wurde erfolgreich zurückgesetzt.",
                    severity: "success",
                })
            );
            return res.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            thunkAPI.dispatch(
                showAlert({
                    message: message,
                    severity: "error",
                })
            );
            return thunkAPI.rejectWithValue();
        }
    }
);

const cleanState = (state) => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    state.access = null;
    state.refresh = null;
    state.isAuthenticated = null;
    state.user = null;
    state.popup = null;
    state.loading = false;
};

const initialState = {
    access: localStorage.getItem("access"),
    refresh: localStorage.getItem("refresh"),
    isAuthenticated: null,
    user: null,
    popup: null,
    loading: false,
};

const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            cleanState(state);
        },
        hideOfferPopup: (state) => {
            state.popup = { ...state.popup, active: false };
        },
    },
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isAuthenticated = false;
        },
        [register.rejected]: (state, action) => {
            cleanState(state);
        },
        [login.fulfilled]: (state, action) => {
            localStorage.setItem("access", action.payload.token.access);
            localStorage.setItem("refresh", action.payload.token.refresh);
            state.isAuthenticated = true;
            state.access = action.payload.token.access;
            state.refresh = action.payload.token.refresh;
            state.user = action.payload.user;
            state.popup = action.payload.popup;
            state.loading = false;
        },
        [login.rejected]: (state, action) => {
            cleanState(state);
        },
        [checkAuthenticated.pending]: (state, action) => {
            state.loading = true;
        },
        [checkAuthenticated.fulfilled]: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload.user;
            if (action.payload.access) {
                localStorage.setItem("access", action.payload.access);
                state.access = action.payload.access;
            }
            state.loading = false;
        },
        [checkAuthenticated.rejected]: (state, action) => {
            state.isAuthenticated = false;
            state.loading = false;
        },

        [refreshAccess.fulfilled]: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload.user;
            if (action.payload.access) {
                localStorage.setItem("access", action.payload.access);
                state.access = action.payload.access;
            }
        },
        [refreshAccess.rejected]: (state, action) => {
            cleanState(state);
        },
        [changePassword.rejected]: (state) => {},
        [changePassword.fulfilled]: (state) => {},
        [resetPassword.rejected]: (state) => {},
        [resetPassword.fulfilled]: (state) => {},
        [resetPasswordConfirm.rejected]: (state) => {},
        [resetPasswordConfirm.fulfilled]: (state) => {},
    },
});

export const { logout, hideOfferPopup } = AuthSlice.actions;

const { reducer } = AuthSlice;
export default reducer;
