import React, { useState, useRef } from "react";
import Page from "src/components/Page";
import Logo from "../components/Logo";
import { LoginForm } from "../components/authentication/login";
import { RegisterForm } from "../components/authentication/register";
import ResetPasswordForm from "../components/authentication/ResetPasswordForm";
import PasswordResetConfirmForm from "src/components/authentication/PasswordResetConfirmForm";
import Alert from "../utils/Alert";
import { Grid, Stack, Typography, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";

const brands = [
    {
        image: "/static/landing_page/LOGO Seni.png",
        url: "https://seni.de/de_DE",
    },
    {
        image: "/static/landing_page/LOGO Shop bella.png",
        url: "https://shop-bella.de/",
    },
    {
        image: "/static/landing_page/LOGO Bella.png",
        url: "https://bella-damenhygiene.de/de_DE",
    },
    {
        image: "/static/landing_page/LOGO bella baby Happy.jpg",
        url: "https://bella-happy.de/de_DE",
    },
    {
        image: "/static/landing_page/LOGO Matopat.png",
        url: "https://matopat.de/",
    },
    {
        image: "/static/landing_page/LOGO Kanion.jpg",
    },
    {
        image: "/static/landing_page/LOGO dr Max.png",
    },
];

const socials = [
    {
        image: "/static/landing_page/LOGO Seni.png",
        url: "https://seni.de/de_DE",
    },
    {
        image: "/static/landing_page/LOGO Shop bella.png",
        url: "https://shop-bella.de/",
    },
    {
        image: "/static/landing_page/LOGO Leben Ohne Druck.png",
        url: "https://www.instagram.com/seni.lebenohnedruck/",
    },
];

const LINKS = [
    { step: "login", label: "Zurück zur Anmeldung" },
    { step: "register", label: "Registrieren" },
    { step: "reset_password", label: "Passwort vergessen?" },
];

const LandingPage = () => {
    // const [authStep, setAuthStep] = useState("login");
    const { authStep, uid, token } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef();

    const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        );
    const mainColumnsSpacing = isMobile ? 6 : 3;

    const FORMS = [
        {
            step: "login",
            form: (
                <LoginForm
                    ref={formRef}
                    setSubmitting={(val) => {
                        setIsSubmitting(val);
                    }}
                />
            ),
            buttonLabel: "Login",
            links: [LINKS[2], LINKS[1]],
        },
        {
            step: "register",
            form: (
                <RegisterForm
                    ref={formRef}
                    setSubmitting={(val) => {
                        setIsSubmitting(val);
                    }}
                />
            ),
            buttonLabel: "Jetzt registrieren",
            links: [LINKS[0]],
        },
        {
            step: "reset_password",
            form: (
                <ResetPasswordForm
                    ref={formRef}
                    setSubmitting={(val) => {
                        setIsSubmitting(val);
                    }}
                />
            ),
            buttonLabel: "Passwort zurücksetzen",
            links: [LINKS[0]],
        },
        {
            step: "reset_password_confirm",
            form: (
                <PasswordResetConfirmForm
                    uid={uid}
                    token={token}
                    ref={formRef}
                    setSubmitting={(val) => {
                        setIsSubmitting(val);
                    }}
                />
            ),
            buttonLabel: "Passwort zurücksetzen",
            links: [LINKS[0]],
        },
    ];

    const renderAuthPart = () => {
        let authForm = null;
        if (uid && token) {
            authForm = FORMS.find(
                (form) => form.step === "reset_password_confirm"
            );
        } else {
            authForm = FORMS.find((form) => form.step === authStep);
        }
        if (!authForm) {
            authForm = FORMS.find((form) => form.step === "login");
        }
        let elements = [];
        elements.push(authForm.form);
        elements.push(
            <LoadingButton
                variant="contained"
                onClick={() => {
                    formRef.current.submitForm();
                }}
                loading={isSubmitting}
            >
                {authForm.buttonLabel}
            </LoadingButton>
        );
        for (const link of authForm.links) {
            elements.push(
                <Link href={`/${link.step}`} underline="none" color="inherit">
                    {link.label}
                </Link>
            );
        }

        return elements.map((element, index) => ({
            ...element,
            key: { index },
        }));
    };

    return (
        <Page title="TZMO DE Extranet">
            {/* {isWinter() && <Snowfall />} */}
            <Alert duration={20000} />

            <Stack
                justifyContent="center"
                sx={{
                    minHeight: "100vh",
                }}
                spacing={8}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        textAlign: "center",
                        // minHeight: "100vh",
                    }}
                >
                    <Grid item xs={mainColumnsSpacing}>
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Typography variant="h3">
                                Willkommen im <br />
                                TZMO Extranet
                            </Typography>
                            <Typography>
                                Online-Plattform für Pflegekräfte
                                <br />
                                und den Fachhandel
                            </Typography>
                            <Logo />
                        </Stack>
                    </Grid>
                    <Grid item xs={mainColumnsSpacing}>
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <Typography variant="h5">
                                Bitte geben Sie Ihre <br />
                                Daten ein:
                            </Typography>
                            {renderAuthPart()}
                        </Stack>
                    </Grid>
                </Grid>
                <Stack spacing={2}>
                    <Stack
                        direction="row"
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {brands.map((brand, index) => {
                            return (
                                <a
                                    href={brand.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                >
                                    <img
                                        src={brand.image}
                                        style={{
                                            width: 50,
                                            height: "auto",
                                        }}
                                        alt="Seni"
                                    />
                                </a>
                            );
                        })}
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={3}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <a
                            href="https://www.instagram.com/tzmodeutschlandgmbh/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/static/landing_page/IG.png"
                                style={{
                                    width: 35,
                                    height: "auto",
                                }}
                                alt="Seni"
                            />
                        </a>

                        <a
                            href="https://www.facebook.com/seni.deutschland/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/static/landing_page/FB.png"
                                style={{
                                    width: 35,
                                    height: "auto",
                                }}
                                alt="Seni"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/seni.lebenohnedruck/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src="/static/landing_page/LOGO Leben Ohne Druck.png"
                                style={{
                                    width: 35,
                                    height: "auto",
                                }}
                                alt="Seni"
                            />
                        </a>
                    </Stack>
                </Stack>
            </Stack>
        </Page>
    );
};

export default LandingPage;
