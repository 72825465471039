import React, { useState, useCallback } from "react";
import { Dialog } from "@mui/material";

import RichTextEditor from "../Content/RichTextEditor";

const RichTextDialog = ({ open, onClose, text, setText, position }) => {
    const [isOpened, setIsOpened] = useState(false);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    ...(position && {
                        position: "relative",
                        top: `calc(${position.y + 20}px)`,
                        left: -position.x,
                    }),
                    overflowY: "unset",
                    maxWidth: "100%",
                    maxHeight: "100%",
                },
            }}
        >
            <RichTextEditor value={text} setValue={(val) => setText(val)} />
        </Dialog>
    );
};

export default RichTextDialog;
