import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";

import { login } from "src/redux/slices/Auth";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";

import Loader from "src/components/loader/Loader";

// ----------------------------------------------------------------------
const LoginForm = forwardRef(({ setSubmitting }, ref) => {
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const LoginSchema = Yup.object().shape({
        username: Yup.string().required("*Pflichtfeld"),
        password: Yup.string().required("*Pflichtfeld"),
    });

    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            setLoading(true);
            setSubmitting(true);
            const { username, password } = values;
            dispatch(login({ username, password }))
                .unwrap()
                .then(() => {
                    setLoading(false);
                    setSubmitting(false);
                    actions.setSubmitting(false);
                })
                .catch(() => {
                    setLoading(false);
                    setSubmitting(false);
                    actions.setSubmitting(false);
                });
        },
    });

    const {
        errors,
        touched,
        values,
        isSubmitting,
        handleSubmit,
        getFieldProps,
    } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    useImperativeHandle(ref, () => ({
        submitForm() {
            formik.handleSubmit();
        },
    }));

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {loading ? (
                    <Loader />
                ) : (
                    <Stack spacing={2} sx={{ mb: 1 }}>
                        <TextField
                            fullWidth
                            autoComplete="username"
                            type="text"
                            label="Benutzername oder E-Mail "
                            {...getFieldProps("username")}
                            error={Boolean(touched.username && errors.username)}
                            helperText={touched.username && errors.username}
                        />

                        <TextField
                            fullWidth
                            autoComplete="current-password"
                            type={showPassword ? "text" : "password"}
                            label="Passwort"
                            {...getFieldProps("password")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleShowPassword}
                                            edge="end"
                                        >
                                            <Icon
                                                icon={
                                                    showPassword
                                                        ? eyeFill
                                                        : eyeOffFill
                                                }
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
});

export default LoginForm;
