import React, { useState, Fragment } from "react";
import {
    Button,
    Typography,
    Stack,
    Tooltip,
    Fab,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ImageList,
    ImageListItem,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import AddLinkIcon from "@mui/icons-material/AddLink";

import { Box } from "@mui/system";
import DragDropFiles from "./DragDropFiles";
import Loader from "src/components/loader/Loader";
import axios from "axios";

const IMAGE_TYPES = ["png", "jpg", "jpeg"];

const VIDEO_TYPES = ["mp4", "mov", "wmv", "avi", "flv", "mkv"];

const isMediaFile = (fileName, type) => {
    if (!fileName) {
        return false;
    }
    const fileExt = fileName.split(".").pop();
    let imageExts = VIDEO_TYPES.concat(IMAGE_TYPES);
    if (type && typeof type === "string") {
        if (type.toLowerCase() === "image") {
            imageExts = IMAGE_TYPES;
        } else if (type.toLowerCase() === "video") {
            imageExts = VIDEO_TYPES;
        }
    }
    return imageExts.includes(fileExt);
};

const ThumbnailStep = ({
    attachments,
    thumbnail,
    setThumbnail,
    showErrorMessage,
}) => {
    const [loading, setLoading] = useState(false);
    const mediaFiles = attachments.filter((file) => isMediaFile(file.FileName));
    const [open, setOpen] = useState(false);
    const [frames, setFrames] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const uploadThumbnail = async (files) => {
        let file = files[0];
        const name = file.name.replace(/\s+/g, "_").toLowerCase();
        const size = file.size;
        const type = file.type;
        const toBase64 = (file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        setLoading(true);
        try {
            file = await toBase64(file);
            setThumbnail({
                id: null,
                name: name,
                size: size,
                type: type,
                file: file,
            });
        } catch (err) {
            showErrorMessage(err);
        } finally {
            setLoading(false);
        }
    };
    const removeThumbnail = () => {
        setThumbnail(null);
    };

    const getFrameData = async (videoFiles) => {
        const fps = 1;
        let tempFrames = [];
        for (const videoFile of videoFiles) {
            const videoBlob =
                videoFile.Id >= 0
                    ? await fetch(
                          `${axios.defaults.baseURL}${videoFile.FilePath}`
                      ).then((r) => r.blob())
                    : videoFile.File;

            let videoObjectUrl = URL.createObjectURL(videoBlob);
            let video = document.createElement("video");

            let seekResolve;
            video.addEventListener("seeked", async function () {
                if (seekResolve) seekResolve();
            });

            video.src = videoObjectUrl;

            // workaround chromium metadata bug (https://stackoverflow.com/q/38062864/993683)
            while (
                (video.duration === Infinity || isNaN(video.duration)) &&
                video.readyState < 2
            ) {
                await new Promise((r) => setTimeout(r, 1000));
                video.currentTime = 10000000 * Math.random();
            }
            let duration = video.duration;

            let canvas = document.createElement("canvas");
            let context = canvas.getContext("2d");
            let [w, h] = [video.videoWidth, video.videoHeight];
            canvas.width = w;
            canvas.height = h;

            let interval = 1 / fps;
            let currentTime = 0;

            while (currentTime < duration) {
                video.currentTime = currentTime;
                await new Promise((r) => (seekResolve = r));

                context.drawImage(video, 0, 0, w, h);
                let base64ImageData = canvas.toDataURL();

                const stringLength =
                    base64ImageData.length - "data:image/png;base64,".length;

                const sizeInBytes =
                    4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
                tempFrames.push({
                    file: base64ImageData,
                    name:
                        videoFile.FileName.substr(
                            0,
                            videoFile.FileName.lastIndexOf(".")
                        ) + "png",
                    type: "image/png",
                    size: sizeInBytes,
                });

                currentTime += interval;
            }
        }
        return tempFrames;
    };

    const selectFromAttachments = async () => {
        const videoFiles = mediaFiles.filter((file) =>
            isMediaFile(file.FileName, "video")
        );
        if (videoFiles.length < 1) {
            return;
        }
        if (frames.length > 0) {
            setOpen(true);
            return;
        }
        setLoading(true);
        const tempFrames = await getFrameData(videoFiles);
        setFrames(tempFrames);
        setLoading(false);
        setOpen(true);
    };

    const handleSelectThumbnail = () => {
        setThumbnail({
            id: null,
            name: frames[selectedIndex].name,
            size: frames[selectedIndex].size,
            type: frames[selectedIndex].type,
            file: frames[selectedIndex].file,
        });
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>
                            Select Thumbnail from attached videos / images
                        </DialogTitle>
                        <DialogContent>
                            <ImageList
                                sx={{ width: 500 }}
                                cols={3}
                                // rowHeight={164}
                            >
                                {frames.map((item, index) => (
                                    <ImageListItem
                                        onClick={() => {
                                            setSelectedIndex(index);
                                        }}
                                        key={index}
                                        sx={{
                                            ":hover": {
                                                transform: "scale(1.1)",
                                            },
                                            ...(selectedIndex === index && {
                                                // borderColor: "blue",
                                                opacity: 0.5,
                                            }),
                                        }}
                                    >
                                        <img
                                            src={`${item.file}`}
                                            srcSet={`${item.file}`}
                                            // alt={item.title}
                                            loading="lazy"
                                            // onMouseOver={(e) =>
                                            //     style={{}}
                                            // }
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                disabled={selectedIndex < 0 ? true : false}
                                onClick={() => {
                                    handleSelectThumbnail();
                                }}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Typography>
                        {thumbnail && thumbnail.name
                            ? `Thumbnail:${thumbnail.name}`
                            : "No thumbnail"}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <Stack direction="column" spacing={2}>
                            {/* <DragDropFiles
                            text={`Drop file here`}
                            onDrop={uploadThumbnail}
                        /> */}
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Tooltip title="Upload new thumbnail">
                                <label htmlFor="upload-thumbnail">
                                    <input
                                        style={{ display: "none" }}
                                        id="upload-thumbnail"
                                        name="upload-thumbnail"
                                        type="file"
                                        onChange={(e) => {
                                            uploadThumbnail(e.target.files);
                                        }}
                                    />
                                    <Fab
                                        color="primary"
                                        size="small"
                                        component="span"
                                        aria-label="add"
                                        // variant="extended"
                                    >
                                        <UploadFileIcon />
                                    </Fab>
                                </label>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Fab
                                    color="primary"
                                    size="small"
                                    component="span"
                                    aria-label="add"
                                    onClick={removeThumbnail}
                                    // variant="extended"
                                >
                                    <DeleteIcon />
                                </Fab>
                            </Tooltip>
                            <Tooltip title="Select from attachments">
                                <Fab
                                    color="primary"
                                    size="small"
                                    component="span"
                                    aria-label="select"
                                    onClick={selectFromAttachments}
                                    // variant="extended"
                                >
                                    <AddLinkIcon />
                                </Fab>
                            </Tooltip>
                        </Stack>
                        {thumbnail && (
                            <img
                                style={{
                                    maxHeight: "200px",
                                }}
                                src={
                                    thumbnail.id
                                        ? `${axios.defaults.baseURL}${thumbnail.file}`
                                        : thumbnail?.file || null
                                }
                                alt="image"
                            />
                        )}
                    </Stack>
                </Fragment>
            )}
        </Box>
    );
};

export default ThumbnailStep;
