export const adminConfig = [
    {
        title: "Admin",
        path: "/admin/panel",
        icon: "account_circle",
        tooltip:
            "Verwaltungstafel. Ermöglicht die Bearbeitung von Inhalten, Benutzern usw.",
    },
];

export const sidebarConfig = [
    {
        title: "Kontakt",
        path: "/dashboard/contact",
        icon: "contact_mail",
        tooltip:
            "Sie haben Fragen oder Anregungen zu unserem TZMO-Extranet? Kein Problem, füllen sie einfach unser Kontaktformular aus und wir sind Ihnen gerne behilflich.",
    },
    {
        title: "Nutzungslizenz-Generator",
        path: "/dashboard/license",
        icon: "card_membership",
    },
];
