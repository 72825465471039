import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
// material
import { Container, Typography, Button, Box, Stack } from "@mui/material";
import downIcon from "@iconify/icons-eva/arrow-down-outline";
import { Icon } from "@iconify/react";
// components
import Page from "../components/Page";
import backIcon from "@iconify/icons-eva/arrow-back-fill";
import ImageGallery from "react-image-gallery";
import Gallery from "src/css/Gallery.css";
import Loader from "src/components/loader/Loader";
import { AdminTable } from "src/components/dashboard/admin/utlis";
import filesize from "filesize";
import saveAs from "file-saver";
import JSZipUtils from "jszip-utils";
import DownloadButton from "src/components/dashboard/admin/utlis/DownloadButton";
import { editContent, clearEditContent } from "src/redux/slices/Content";
import { useDispatch } from "react-redux";
import { get } from "src/services/content.service";

import axios from "axios";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: "FileName", label: "Dateiname", alignRight: false, search: true },
    { id: "FileSize", label: "Dateigröße", alignRight: false, search: false },
    {
        id: "Download",
        label: "Herunterladen",
        alignRight: false,
        search: false,
    },
];

ContentDetail.propTypes = {
    endpoint: PropTypes.string.isRequired,
    altSlug: PropTypes.string,
};

export default function ContentDetail({ endpoint, altSlug }) {
    const params = useParams();
    const slug = params.slug ? params.slug : altSlug;

    const [content, setContent] = useState(null);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);
    const [progress, setProgress] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const res = await get(dispatch, `${endpoint}${slug}`);
            setContent(res.data.content);
            dispatch(
                editContent({
                    slug: slug,
                    contentType: res.data.content.type,
                })
            );
            setFiles(res.data.files);
        } catch (err) {
            navigate("/404");
        } finally {
            setLoading(false);
        }
    }, [endpoint, navigate, slug, dispatch]);

    useEffect(() => {
        if (!content) {
            fetchData();
        }
    }, [content, fetchData]);

    useEffect(() => {
        return () => {
            dispatch(clearEditContent());
        };
    }, [dispatch]);

    const displayContentBody = () => {
        return { __html: content.body };
    };

    function urlToPromise(url) {
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    const isImageFile = (fileName) => {
        if (!fileName) {
            return false;
        }
        const fileExt = fileName.split(".").pop();
        const imageExts = ["png", "jpg", "jpeg"];
        if (imageExts.indexOf(fileExt) >= 0) {
            return true;
        }
        return false;
    };

    const displayImages = () => {
        const images = [];
        for (const file of files) {
            if (isImageFile(file.fileName)) {
                images.push({
                    original: `${axios.defaults.baseURL}${file.filePath}`,
                });
            }
        }
        if (images.length > 0) {
            return (
                <ImageGallery items={images} additionalClass={Gallery.image} />
            );
        }
    };

    const downloadFile = async (filePath, fileName) => {
        saveAs(`${axios.defaults.baseURL}${filePath}`, fileName);
    };
    const onDownloadClicked = async (downloadOption) => {
        const filesToDownload = [];
        for (const file of files) {
            const { filePath, fileName } = file;
            const isItemSelected = selected.indexOf(fileName) !== -1;
            if (downloadOption === "all" || isItemSelected) {
                filesToDownload.push({
                    fileName: fileName,
                    filePath: filePath,
                });
            }
        }
        if (filesToDownload.length === 1) {
            downloadFile(
                filesToDownload[0].filePath,
                filesToDownload[0].fileName
            );
        } else {
            const zip = require("jszip")();

            const zipFilename = "attachments.zip";
            for (const file of filesToDownload) {
                const url = `${axios.defaults.baseURL}${file.filePath}`;
                const filename = file.fileName;
                zip.file(filename, urlToPromise(url), { binary: true });
            }

            setProgress({
                show: true,
                value: 0,
            });
            // when everything has been downloaded, we can trigger the dl
            zip.generateAsync(
                { type: "blob" },
                function updateCallback(metadata) {
                    setProgress({
                        show: true,
                        value: metadata.percent.toFixed(2),
                    });
                }
            ).then(
                function callback(blob) {
                    // see FileSaver.js
                    saveAs(blob, zipFilename);
                    setProgress({
                        show: false,
                        value: 0,
                    });
                },
                function (e) {
                    console.log(e);
                }
            );
        }
    };

    const options =
        selected.length > 0
            ? [
                  {
                      name: "all",
                      label: "Alles herunterladen",
                      handler: onDownloadClicked,
                  },
                  {
                      name: "selected",
                      label: "Auswahl herunterladen",
                      handler: onDownloadClicked,
                  },
                  {
                      name: "clear",
                      label: "Auswahl löschen",
                      handler: () => {
                          setSelected([]);
                      },
                  },
              ]
            : [
                  {
                      name: "all",
                      label: "Alles herunterladen",
                      handler: onDownloadClicked,
                  },
              ];

    const displayAttachments = () => {
        if (files && files.length > 0) {
            let rows = [];
            for (const file of files) {
                const { fileName, fileSize, filePath } = file;
                rows.push({
                    FileName: fileName,
                    FileSize: filesize(fileSize),
                    Download: (
                        <Button
                            variant="contained"
                            onClick={() => {
                                downloadFile(filePath, fileName);
                            }}
                            startIcon={<Icon icon={downIcon} />}
                        >
                            Download
                        </Button>
                    ),
                });
            }
            return (
                <Box mt={2} sx={{ height: "100%", width: "100%" }}>
                    <AdminTable
                        searchPlaceholder="Suche..."
                        headers={TABLE_HEAD}
                        rows={rows}
                        selection={{
                            selected: selected,
                            setSelected: setSelected,
                        }}
                        progress={progress}
                    >
                        <DownloadButton options={options} />
                    </AdminTable>
                </Box>
            );
        }
    };

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <Page title={`${content.title} | TZMO DE Extranet`}>
                    <Container maxWidth={false}>
                        <Box mb={2}>
                            <Button
                                variant="contained"
                                to=""
                                onClick={() => navigate(-1)}
                                startIcon={<Icon icon={backIcon} />}
                            >
                                Zurück
                            </Button>
                        </Box>
                        <Typography variant="h4" sx={{ mb: 5 }}>
                            {content.title}
                        </Typography>

                        <div
                            className="mt-5 mb-5"
                            dangerouslySetInnerHTML={displayContentBody()}
                        />
                        <Stack
                            sx={{
                                mt: 4,
                                width: "100%",
                            }}
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            {displayImages()}
                            {displayAttachments()}
                        </Stack>
                    </Container>
                </Page>
            )}
        </>
    );
}
