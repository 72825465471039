import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box } from "@mui/material";
//
import { BaseOptionChart } from "../../charts";
import { format } from "date-fns";

// ----------------------------------------------------------------------

export default function AppWebsiteVisits({ chartData }) {
    const labels =
        chartData && chartData.length > 0
            ? chartData.map((item) => {
                  return format(new Date(item.month), "MMMM/yyyy");
              })
            : [];

    const series =
        chartData && chartData.length > 0
            ? [
                  {
                      name: "Visitors",
                      type: "column",
                      data: chartData.map((item) => {
                          return item.num_visits;
                      }),
                  },
              ]
            : [];

    const chartOptions = merge(BaseOptionChart(), {
        stroke: { width: [2] },
        plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
        fill: { type: ["solid"] },
        labels: labels,
        xaxis: { type: "datetime" },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== "undefined") {
                        return `${y.toFixed(0)} visits`;
                    }
                    return y;
                },
            },
        },
    });

    return (
        <Card>
            <CardHeader
                title="Website Visits"
                // subheader="(+43%) than last year"
            />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <ReactApexChart
                    type="line"
                    series={series}
                    options={chartOptions}
                    height={364}
                />
            </Box>
        </Card>
    );
}
