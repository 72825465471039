import React, { useState, useEffect, useCallback, Fragment } from "react";
import {
    FormLabel,
    FormControl,
    FormGroup,
    FormControlLabel,
    Switch,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Droppable, DragDropContext, Draggable } from "react-beautiful-dnd";

import { useDispatch } from "react-redux";
import { showAlert } from "src/redux/slices/AlertSlice";
import { post } from "src/services/content.service";

import Loader from "src/components/loader/Loader";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isEnabled, isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : isEnabled ? "darkgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250,
});
const StickyForm = ({ content, setSticky }) => {
    const [allSticky, setAllSticky] = useState([]);
    const [checked, setChecked] = useState(
        content && content.sticky && content.sticky > 0 ? true : false
    );
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const setStickyElements = (fetched) => {
        if (fetched && fetched.length > 0 && content) {
            if (content.id) {
                const editedStickyIdx = fetched.findIndex(
                    (element) => element.id === content.id
                );
                if (editedStickyIdx > -1) {
                    setAllSticky(fetched);
                } else {
                    fetched.unshift(content);
                    setAllSticky(fetched);
                }
            } else {
                fetched.unshift(content);
                setAllSticky(fetched);
            }
        }
    };

    useEffect(() => {
        const fetchSticky = async () => {
            const body = JSON.stringify({
                sort_by: "popular",
            });
            setLoading(true);
            try {
                const res = await post(dispatch, `/api/content/sticky/`, body);
                setStickyElements(res.data);
            } catch (err) {
            } finally {
                setLoading(false);
            }
        };

        fetchSticky();
    }, []);

    const handleSwitch = (newChecked) => {
        setChecked(newChecked);
        if (newChecked) {
            if (allSticky.length <= 1) {
                setSticky(1);
            } else {
                const editedStickyIdx = allSticky.findIndex(
                    (element) => element.id === content.id
                );
                if (editedStickyIdx >= 0) {
                    setSticky(editedStickyIdx + 1);
                } else {
                    setSticky(1);
                }
            }
        } else {
            setSticky(0);
        }
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            allSticky,
            result.source.index,
            result.destination.index
        );
        setAllSticky(items);

        const editedStickyIdx = items.findIndex(
            (element) => element.id === content.id
        );
        if (editedStickyIdx >= 0) {
            setSticky(editedStickyIdx + 1);
        }
    };

    return (
        <Fragment>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend">Sticky state</FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={content.sticky}
                                onChange={(e) => {
                                    handleSwitch(e.target.checked);
                                }}
                            />
                        }
                        label="Sticky"
                    />
                </FormGroup>
            </FormControl>
            {checked && allSticky.length > 1 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {allSticky.map((item, index) => (
                                    <Draggable
                                        key={item.id}
                                        draggableId={`item-${item.id}`}
                                        index={index}
                                        isDragDisabled={item.id !== content.id}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    item.id === content.id,
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                            >
                                                {item.title}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </Fragment>
    );
};

export default StickyForm;
