import { overArgs } from "lodash";
import React, { useState } from "react";
import Draggable from "react-draggable";

const MyDraggable = (props) => {
    const [dragging, setDragging] = useState(false);
    const { children } = props;

    const onClick = (...args) => {
        if (props.onClick && typeof props.onClick === "function") {
            props.onClick(...args);
        }
    };

    const onDrop = (...args) => {
        if (props.onDrop && typeof props.onDrop === "function") {
            props.onDrop(...args);
        }
    };

    const onDrag = (...args) => {
        setDragging(true);
        if (props.onDrag && typeof props.onDrag === "function") {
            props.onDrag(...args);
        }
    };

    const onStop = (...args) => {
        setDragging(false);
        if (dragging) {
            onDrop(...args);
        } else {
            onClick(...args);
        }
    };

    return (
        <Draggable {...props} onDrag={onDrag} onStop={onStop}>
            {children}
        </Draggable>
    );
};

export default MyDraggable;
