import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Container, Grid, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";

import Page from "../components/Page";
import { showAlert } from "src/redux/slices/AlertSlice";
import { useDispatch } from "react-redux";
import { post } from "src/services/content.service";

const VerifyAccount = () => {
    const params = useParams();
    const uid = params.uid;
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const showErrorMessage = useCallback(
        (error) => {
            dispatch(
                showAlert({
                    message: error,
                    severity: "error",
                })
            );
        },
        [dispatch]
    );

    const handleAcceptUser = async () => {
        const body = JSON.stringify({ uid: uid });
        try {
            setLoading(true);
            await post(dispatch, "/auth/users/activation/", body);
        } catch (err) {
            showErrorMessage(err);
        } finally {
            setLoading(false);
            navigate("/");
        }
    };

    const handleDeleteUser = async () => {
        const body = JSON.stringify({ ids: [user.id] });
        try {
            setLoading(true);
            await post(dispatch, "/api/accounts/user/delete", body);
        } catch (err) {
            showErrorMessage(err);
        } finally {
            setLoading(false);
            navigate("/");
        }
    };

    const renderUserInfo = () => {
        if (user) {
            let rows = [];
            for (var key in user) {
                if (user.hasOwnProperty(key)) {
                    rows.push(
                        // <Fragment>
                        //     <Grid item xs={6} md={4}>
                        //         <Item>{key}</Item>
                        //     </Grid>
                        //     <Grid item xs={6} md={8}>
                        //         <Item>{user[key]}</Item>
                        //     </Grid>
                        // </Fragment>
                        <Grid item xs={6} key={key}>
                            <TextField
                                id="outlined-read-only-input"
                                label={key}
                                defaultValue={user[key]}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    );
                }
            }
            return (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Grid container alignItems="center" spacing={2}>
                        {rows}
                    </Grid>
                    <Stack mt={2} direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteUser}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAcceptUser}
                        >
                            Accept
                        </Button>
                    </Stack>
                </Box>
                // </Box>
            );
        }
        return null;
    };

    useEffect(() => {
        const getUserInfo = async () => {
            const body = JSON.stringify({ uid });
            try {
                const res = await post(dispatch, `/auth/admin/user/`, body);

                setUser(res.data);
            } catch (error) {
                setUser(null);
                dispatch(
                    showAlert({
                        message:
                            "User with given uid does not exist or already activated!",
                        severity: "error",
                    })
                );
                navigate("/404");
            }
        };

        getUserInfo();
    }, [uid]);

    return (
        <Page title="Admin: Activate User | TZMO DE Extranet">
            <Container>{renderUserInfo()}</Container>
        </Page>
    );
};

export default VerifyAccount;
