import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
// material
import { styled } from "@mui/material/styles";
import {
    Card,
    CardMedia,
    // Avatar,
} from "@mui/material";
import { Box } from "@mui/system";
import MyDraggable from "../Content/MyDraggable";
import axios from "axios";
import RichTextDialog from "../Content/RichTextDialog";
import { getTextWidth } from "get-text-width";
import ConfirmDialog from "./ConfirmDialog";
// utils
// import { fShortenNumber } from "../../../utils/formatNumber";
// ----------------------------------------------------------------------

const CardMediaStyle = styled(CardMedia)({
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "100%",
});

// ----------------------------------------------------------------------

PopupCard.propTypes = {
    setText: PropTypes.func,
};

export default function PopupCard({
    image,
    text,
    setText,
    draggable,
    onPopupClicked,
    offer,
}) {
    const [showConfirm, setShowConfirm] = useState(false);
    const cardSize = {
        width: image ? image.width * image.scale : 500,
        height: image ? image.height * image.scale : 500,
    };

    const textSize = {
        width: text.positionX * image.scale,
        height: text.positionY * image.scale,
    };

    const offset = 1;

    function getText(html) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html;
        return divContainer.textContent || divContainer.innerText || "";
    }

    const scaleFont = (text) => {
        if (!text) {
            return "<p></p>";
        }
        const tag = "font-size: ";
        let startIndex = 0,
            index;
        let scaledFontText = text;
        while ((index = scaledFontText.indexOf(tag, startIndex)) > -1) {
            startIndex = index + tag.length;
            let endIndex = text.indexOf("px;", startIndex);
            if (endIndex < 0) {
                break;
            }
            let fontSize = Math.round(
                parseInt(text.substring(startIndex, endIndex).trim()) *
                    image.scale
            );
            scaledFontText = scaledFontText.replaceBetween(
                startIndex,
                endIndex,
                fontSize.toString()
            );
        }
        return scaledFontText;
    };
    const scaledText = scaleFont(text.body);

    const [textHeight, setTextHeight] = useState(0);
    const elementRef = useRef(null);
    const heightBoundary = cardSize.height - textHeight;
    const widthBoundary = cardSize.width - getTextWidth(getText(text.body));

    useEffect(() => {
        setTextHeight(elementRef.current.clientHeight);
    }, [text.body]); //empty dependency array so it only runs once at render

    const [richTextPosition, setRichTextPosition] = useState({ x: 0, y: 0 });
    const [showTextEditor, setShowTextEditor] = useState(false);

    const handleDrop = (event, dragElement) => {
        if (text) {
            const { x, y } = dragElement;
            // onTextPositionChanged(x, y);
            if (setText && typeof setText === "function") {
                setText("position", { x, y });
            } else {
            }
        }
    };

    const handlePopupClicked = () => {
        if (draggable && offer && offer.id) {
            console.log(offer);
            setShowConfirm(true);
        } else {
            onPopupClicked();
        }
    };

    return (
        <Box sx={{ height: "100%", width: "auto" }}>
            <ConfirmDialog
                open={showConfirm}
                onSubmit={onPopupClicked}
                onClose={() => {
                    setShowConfirm(false);
                }}
                title={"Sind Sie sicher, dass Sie fortfahren möchten?"}
                text={
                    "Wenn Sie bestätigen, werden alle Änderungen entfernt und Sie werden zu dem aktuell eingestellten Link weitergeleitet."
                }
            />
            <RichTextDialog
                open={showTextEditor}
                onClose={() => {
                    setShowTextEditor(false);
                }}
                text={text.body}
                setText={(val) => {
                    if (setText && typeof setText === "function") {
                        setText("body", val);
                    }
                }}
                position={richTextPosition}
            />

            <Card
                sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    // borderRadius: 90,
                    border: "none",
                    boxShadow: "none",
                }}
            >
                {image && (
                    <CardMediaStyle
                        media="picture"
                        alt="Poster image"
                        image={
                            image.id
                                ? `${axios.defaults.baseURL}${image.file}`
                                : image?.file || null
                        }
                        onClick={handlePopupClicked}
                    />
                )}
                <MyDraggable
                    disabled={draggable ? false : true}
                    bounds={{
                        left: 0,
                        top: 0,
                        right: widthBoundary,
                        bottom: heightBoundary,
                    }}
                    // scale={image.scale}
                    // onDrag={(e) => {
                    //     if (!draggable) {
                    //         e.preventDefault();
                    //     }
                    // }}
                    // bounds={"parent"}
                    // bounds="parent"
                    onDrop={handleDrop}
                    onClick={(e, dragElement) => {
                        const { x, y } = dragElement;
                        setRichTextPosition({ x: x, y: y });
                        setShowTextEditor(true);
                    }}
                    position={{ x: text.positionX, y: text.positionY }}
                    // position={{
                    //     x:
                    //         text &&
                    //         text.positionX * image.scale <= cardSize.width
                    //             ? text.positionX * image.scale
                    //             : 0,
                    //     y:
                    //         text &&
                    //         text.positionY * image.scale <= cardSize.height
                    //             ? text.positionY * image.scale
                    //             : 0,
                    // }}
                    // position={{
                    //     x: text.positionX * image.scale,
                    //     y: text.positionY * image.scale,
                    // }}
                >
                    <Box
                        ref={elementRef}
                        sx={{ display: "flex" }}
                        onClick={!draggable ? handlePopupClicked : undefined}
                    >
                        <div dangerouslySetInnerHTML={{ __html: scaledText }} />
                    </Box>
                </MyDraggable>
                {/* </div> */}
            </Card>
        </Box>
    );
}
