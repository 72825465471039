import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import { styled } from "@mui/material/styles";
import { TextField, Button, Box } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    "& .MuiAutocomplete-root": {
        width: 200,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
        }),
        "&.Mui-focused": {
            width: 240,
            "& .MuiAutocomplete-inputRoot": {
                boxShadow: theme.customShadows.z12,
            },
        },
    },
    "& .MuiAutocomplete-inputRoot": {
        "& fieldset": {
            borderWidth: `1px !important`,
            borderColor: `${theme.palette.grey[500_32]} !important`,
        },
    },
    "& .MuiAutocomplete-option": {
        "&:not(:last-child)": {
            borderBottom: `solid 1px ${theme.palette.divider}`,
        },
    },
}));

// ----------------------------------------------------------------------

BlogPostsSearch.propTypes = {
    onSearch: PropTypes.func,
};

export default function BlogPostsSearch({ onSearch, placeholder }) {
    const [value, setValue] = useState("");
    const handleTextChange = (e) => {
        if (e.target.value !== value) {
            setValue(e.target.value);
        }
    };
    const handleSearch = () => {
        if (value !== "") {
            onSearch(value);
            setValue("");
        }
    };
    return (
        <RootStyle>
            <Box sx={{ direction: "row" }}>
                <TextField
                    size="small"
                    placeholder={placeholder ? placeholder : "Suche im News..."}
                    sx={{ mr: 2 }}
                    value={value}
                    onChange={handleTextChange}
                    onKeyDown={(ev) => {
                        if (ev.key === "Enter") {
                            // Do code here
                            handleSearch();
                            ev.preventDefault();
                        }
                    }}
                />
                <Button
                    variant="contained"
                    startIcon={<Icon icon={searchFill} />}
                    onClick={handleSearch}
                >
                    Suche
                </Button>
            </Box>
        </RootStyle>
    );
}
