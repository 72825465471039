// ----------------------------------------------------------------------
import { Link } from "react-router-dom";

export default function Logo() {
    return (
        <Link to="/">
            <img
                src="/static/logo.svg"
                style={{
                    width: 160,
                    height: 160,
                }}
                alt="Logo"
            />
        </Link>
    );
}
