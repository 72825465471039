import React, { useEffect, useState, useCallback, Fragment } from "react";
import AdminTable from "./utlis/AdminTable";
import Label from "src/components/Label";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "src/components/loader/Loader";
import MoreMenu from "./utlis/MoreMenu";
// import ContentDialog from "./ContentDialog";
import { useDispatch } from "react-redux";
import { showAlert } from "src/redux/slices/AlertSlice";
import { post, get } from "src/services/content.service";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const TABLE_HEAD = [
    { id: "Id", label: "Id", alignRight: false, search: false },
    { id: "Category", label: "Category", alignRight: false, search: true },
    { id: "Subject", label: "Subject", alignRight: false, search: true },
    // { id: "Body", label: "Body", alignRight: false, search: true },
    { id: "Resolved", label: "Resolved", alignRight: false },
    { id: "User", label: "User", alignRight: false },
    { id: "Created", label: "Created", alignRight: false },
    { id: "Modified", label: "Modified", alignRight: false },
    { id: "More", alignRight: true },
];

const UserIssues = () => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [issues, setIssues] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState([]);
    const [currentIssue, setCurrentIssue] = useState(null);
    const [submitStatus, setSubmitStatus] = useState();

    const dispatch = useDispatch();

    const deleteContent = async (ids) => {
        const body = JSON.stringify({ ids });
        try {
            setLoading(true);
            await post(dispatch, "/api/content/admin/delete/", body);
        } catch (err) {
            dispatch(
                showAlert({
                    message: err,
                    severity: "error",
                })
            );
        } finally {
            setLoading(false);
        }
    };

    const editIssue = (issue) => {
        setCurrentIssue(issue.id);
        setShowModal(true);
    };

    useEffect(() => {
        if (submitStatus !== null && submitStatus !== undefined) {
            let message, severity;
            if (submitStatus) {
                message = "Issue updated successfully";
                severity = "success";
            } else {
                message = "Failed to update issue";
                severity = "error";
            }
            dispatch(
                showAlert({
                    message: message,
                    severity: severity,
                })
            );
        }
        return () => {
            setSubmitStatus(null);
        };
    }, [submitStatus, dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchIssues = async () => {
            setLoading(true);
            try {
                const res = await get(dispatch, `/api/contact/admin`);
                setIssues(res.data);
                setRows(
                    res.data.map((row, index) => ({
                        Id: row.id,
                        Category: row.category.label,
                        Subject: row.subject,
                        Body: row.body,
                        Type: row.type,
                        Resolved: (
                            <Label
                                variant="ghost"
                                color={row.resolved ? "success" : "error"}
                            >
                                {row.resolved ? "Yes" : "No"}
                            </Label>
                        ),
                        Created: row.created,
                        Modified: row.modified,
                        User: row.uid.name,
                        More: (
                            <MoreMenu
                                options={[
                                    {
                                        label: "Edit",
                                        icon: <EditOutlinedIcon />,
                                        handler: () => {
                                            editIssue(issues[index]);
                                        },
                                    },
                                    {
                                        label: "Delete",
                                        icon: <DeleteOutlineOutlinedIcon />,
                                        handler: () => {
                                            deleteContent([issues[index].id]);
                                        },
                                    },
                                ]}
                            />
                        ),
                    }))
                );
            } catch (err) {
                dispatch(
                    showAlert({
                        message: err,
                        severity: "error",
                    })
                );
            } finally {
                setLoading(false);
            }
        };

        fetchIssues();
    }, [dispatch]);

    const handleDeleteSelected = () => {
        const itemsToDelete = [];
        for (const content of issues) {
            const { Id } = content;
            const isItemSelected = selected.indexOf(Id) !== -1;
            if (isItemSelected) {
                itemsToDelete.push(Id);
            }
        }

        if (itemsToDelete.length > 0) {
            deleteContent(itemsToDelete);
        }
    };

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    {/* <ContentDialog
                        type="edit"
                        open={showModal}
                        onClose={() => {
                            setShowModal(false);
                        }}
                        submitStatus={setSubmitStatus}
                        slug={slug}
                    /> */}
                    <AdminTable
                        searchPlaceholder="Suche..."
                        headers={TABLE_HEAD}
                        rows={rows}
                        selection={{
                            selected: selected,
                            setSelected: setSelected,
                        }}
                        showChildrenWhenSelected={true}
                        contextMenuOptions={[
                            {
                                label: "Edit",
                                icon: <EditOutlinedIcon />,
                                handler: (id) => {
                                    editIssue(issues[id]);
                                },
                            },
                            {
                                label: "Delete",
                                icon: <DeleteOutlineOutlinedIcon />,
                                handler: (id) => {
                                    deleteContent([issues[id].id]);
                                },
                            },
                        ]}
                    >
                        <Tooltip title="Delete">
                            <IconButton
                                color="primary"
                                aria-label="Delete selected"
                                component="span"
                                onClick={handleDeleteSelected}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </AdminTable>
                </Fragment>
            )}
        </Fragment>
    );
};

export default UserIssues;
