import React, { useEffect, useState } from "react";
// material
import { Box, Grid, Typography } from "@mui/material";
// redux
import { useDispatch } from "react-redux";
import { showAlert } from "src/redux/slices/AlertSlice";
// components
import Page from "../components/Page";
import {
    // AppTasks,
    // AppNewUsers,
    // AppBugReports,
    // AppItemOrders,
    // AppNewsUpdate,
    // AppWeeklySales,
    // AppOrderTimeline,
    // AppCurrentVisits,
    AppWebsiteVisits,
    // AppTrafficBySite,
    // AppCurrentSubject,
    // AppConversionRates,
} from "../components/dashboard/app";

import StatsNumberCard from "src/components/dashboard/app/StatsNumberCard";
import StatsCircleDiagram from "src/components/dashboard/app/StatsCircleDiagram";

import { get } from "src/services/content.service";
import Loader from "../components/loader/Loader";

// ----------------------------------------------------------------------

export default function DashboardApp() {
    const [loading, setLoading] = useState(false);
    const [statistics, setStatistics] = useState([]);
    const dispatch = useDispatch();

    const getTop5 = (statistics, type) => {
        if (statistics && Object.keys(statistics).length > 0) {
            return {
                labels: statistics[type].map((item) => {
                    return item.title;
                }),
                values: statistics[type].map((item) => {
                    return item.num_visits;
                }),
            };
        }
        return {
            labels: [],
            values: [],
        };
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoading(true);
        const fetchStats = async () => {
            try {
                const res = await get(
                    dispatch,
                    `/api/content/admin/statistics/`
                );
                setStatistics(res.data);
                setLoading(false);
            } catch (error) {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
                dispatch(
                    showAlert({
                        message: message,
                        severity: "error",
                    })
                );
            }
        };
        fetchStats();
    }, [dispatch]);

    return (
        <Page title="Admin Dashboard | TZMO DE Extranet">
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <Box sx={{ pb: 5 }}>
                        <Typography variant="h4">Hi, Welcome back</Typography>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <StatsNumberCard
                                color="green"
                                value={
                                    statistics && statistics.activeLastWeek
                                        ? statistics.activeLastWeek.length
                                        : null
                                }
                                title="Users last week"
                            />
                            {/* <AppWeeklySales /> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <StatsNumberCard
                                color="yellow"
                                value={
                                    statistics && statistics.contentsLastWeek
                                        ? statistics.contentsLastWeek.length
                                        : null
                                }
                                title="contents viewed last week"
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <StatsCircleDiagram
                                title="Top 5 contents"
                                chartData={getTop5(
                                    statistics,
                                    "mostPopularContents"
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={12}>
                            <AppWebsiteVisits
                                chartData={statistics.visitsLastYear}
                            />
                        </Grid>
                    </Grid>
                </div>
            )}
        </Page>
    );
}
