import { filter } from "lodash";
import React, { useState } from "react";
import PropTypes from "prop-types";
// material
import {
    Card,
    Table,
    Stack,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    LinearProgress,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
// components
import Scrollbar from "src/components/Scrollbar";
import SearchNotFound from "src/components/SearchNotFound";
import ListHead from "./ListHead";
import SearchList from "src/components/SearchList";
import Box from "@mui/material/Box";
//

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, headers, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_el) =>
            // _el[column].toLowerCase().indexOf(query.toLowerCase()) !== -1
            headers.some(
                (header) =>
                    header.search &&
                    _el[header.id]
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) !== -1
            )
        );
    }
    return stabilizedThis.map((el) => el[0]);
}

const ROWS_PER_PAGE = [10, 20, 30];

const AdminTable = ({
    children,
    showChildrenWhenSelected,
    headers,
    rows,
    searchPlaceholder,
    selection,
    progress,
    contextMenuOptions,
    onSelectionChanged,
}) => {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState("asc");
    const { selected, setSelected } = selection;
    const [orderBy, setOrderBy] = useState("name");
    const [filterName, setFilterName] = useState("");
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
    const [contextMenu, setContextMenu] = useState(null);
    const [currentHoverId, setCurrentHoverId] = useState(null);
    const items = rows.map((row, index) => {
        return { ...row, index: index };
    });

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4,
                  }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const id = headers[0].id;
            const newSelecteds = items.map((n) => n[id]);
            if (onSelectionChanged) {
                onSelectionChanged(selected, newSelecteds);
            }

            setSelected(newSelecteds);
            return;
        }
        if (onSelectionChanged) {
            onSelectionChanged(selected, []);
        }

        setSelected([]);
    };

    const handleClick = (event, name) => {
        if (event.target.checked) {
            if (onSelectionChanged) {
                onSelectionChanged(selected, [...selected, name]);
            }

            setSelected([...selected, name]);
        } else {
            let tmpSelected = [...selected];
            const selectedIndex = selected.indexOf(name);
            if (selectedIndex !== -1) {
                tmpSelected.splice(selectedIndex, 1);
                if (onSelectionChanged) {
                    onSelectionChanged(selected, tmpSelected);
                }

                setSelected(tmpSelected);
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;

    const filteredData = applySortFilter(
        items,
        headers,
        getComparator(order, orderBy),
        filterName
    );

    const isDataNotFound = filteredData.length === 0;

    const renderRow = (row) => {
        let list = [];
        for (const key in headers) {
            const id = headers[key].id;
            const alignRight = headers[key].alignRight;
            let cell = row[id];
            list.push(
                <TableCell
                    key={key}
                    component="th"
                    scope="row"
                    padding="normal"
                    align={alignRight ? "right" : "left"}
                >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {typeof cell === "object" ? (
                            cell
                        ) : (
                            <Typography variant="subtitle2" noWrap>
                                {cell}
                            </Typography>
                        )}
                    </Stack>
                </TableCell>
            );
        }
        return list;
    };

    return (
        <Card sx={{ width: "100%" }}>
            <SearchList
                numSelected={selected.length}
                filterName={filterName}
                placeholder={searchPlaceholder}
                onFilterName={handleFilterByName}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                    }}
                >
                    {showChildrenWhenSelected
                        ? showChildrenWhenSelected &&
                          selected.length > 0 &&
                          children
                        : children}
                    {/* {children} */}
                </Box>
            </SearchList>
            {progress && progress.show && (
                <Box sx={{ width: "100%" }}>
                    <LinearProgress
                        variant="determinate"
                        value={progress.value}
                    />
                </Box>
            )}

            <Scrollbar>
                <div
                    onContextMenu={
                        contextMenuOptions ? handleContextMenu : null
                    }
                    style={
                        contextMenuOptions ? { cursor: "context-menu" } : null
                    }
                >
                    <TableContainer sx={{ minWidth: 600 }}>
                        <Table>
                            <ListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={headers}
                                rowCount={items.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {filteredData
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row) => {
                                        const id = headers[0].id;
                                        const isItemSelected =
                                            selected.indexOf(row[id]) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={row.index}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                                onMouseEnter={() => {
                                                    setCurrentHoverId(
                                                        row.index
                                                    );
                                                }}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onChange={(event) =>
                                                            handleClick(
                                                                event,
                                                                row[id]
                                                            )
                                                        }
                                                    />
                                                </TableCell>
                                                {renderRow(row)}
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{ height: 33 * emptyRows }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {isDataNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            align="center"
                                            colSpan={6}
                                            sx={{ py: 3 }}
                                        >
                                            <SearchNotFound
                                                searchQuery={filterName}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </div>
            </Scrollbar>

            <TablePagination
                labelRowsPerPage={"Zeilen pro Seite"}
                rowsPerPageOptions={ROWS_PER_PAGE}
                component="div"
                count={items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Menu
                open={contextMenu !== null}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                {contextMenuOptions &&
                    contextMenuOptions.map((option, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => {
                                option.handler(items[currentHoverId].index);
                                handleCloseContextMenu();
                            }}
                        >
                            <ListItemIcon>{option.icon}</ListItemIcon>
                            <ListItemText primary={option.label} />
                        </MenuItem>
                    ))}
            </Menu>
        </Card>
    );
};

AdminTable.propTypes = {
    headers: PropTypes.array,
    rows: PropTypes.array,
    searchPlaceholder: PropTypes.string,
    children: PropTypes.node,
    selection: PropTypes.object.isRequired,
    progress: PropTypes.object,
};

export default AdminTable;
