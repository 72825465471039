import { createSlice } from "@reduxjs/toolkit";

export const ContentSlice = createSlice({
    name: "content",
    initialState: {
        slug: null,
        contentType: null,
    },
    reducers: {
        editContent: (state, action) => {
            state.slug = action.payload.slug;
            state.contentType = action.payload.contentType;
        },
        clearEditContent: (state) => {
            state.slug = null;
            state.contentType = null;
        },
    },
});

export const { editContent, clearEditContent } = ContentSlice.actions;

const { reducer } = ContentSlice;
export default reducer;
