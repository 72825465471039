import TabbedPage from "src/components/TabbedPage";

import AdminSendEmailForm from "src/components/dashboard/admin/emails/AdminSendEmailForm";
import EmailTemplates from "src/components/dashboard/admin/emails/EmailTemplates";
import EmailSignature from "../components/dashboard/admin/emails/Signature";

const Emails = () => {
    return (
        <TabbedPage dialog={true}>
            <AdminSendEmailForm label="Send Email" />
            <EmailTemplates label="Templates" />
            <EmailSignature label="Signature" />
        </TabbedPage>
    );
};
export default Emails;
