import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
// import { set, sub, formatDistanceToNow } from "date-fns";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import doneAllFill from "@iconify/icons-eva/done-all-fill";
// material
import { alpha } from "@mui/material/styles";
import {
    Box,
    List,
    Badge,
    Button,
    Tooltip,
    Divider,
    IconButton,
    Typography,
    ListItemText,
    ListSubheader,
    ListItemButton,
} from "@mui/material";
// components
import Scrollbar from "../../components/Scrollbar";
import MenuPopover from "../../components/MenuPopover";

import { useDispatch } from "react-redux";
import { get, post } from "src/services/content.service";
import { showAlert } from "../../redux/slices/AlertSlice";

// utils
import { fToNow } from "../../utils/formatTime";

// ----------------------------------------------------------------------

const NotificationUserItem = ({ notification }) => {
    const { name, uid, email } = notification;
    return (
        <ListItemButton
            to={`/admin/activate/${uid}`}
            disableGutters
            component={RouterLink}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: "1px",
            }}
        >
            <ListItemText
                primary={name}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: "flex",
                            alignItems: "center",
                            color: "text.disabled",
                        }}
                    >
                        {email}
                    </Typography>
                }
            />
        </ListItemButton>
    );
};

NotificationUserItem.propTypes = {
    notification: PropTypes.object.isRequired,
};

function NotificationContentItem({ notification }) {
    const { slug, type, title, modified } = notification;
    return (
        <ListItemButton
            to={`/dashboard/${type}/${slug}`}
            disableGutters
            component={RouterLink}
            sx={{
                py: 1.5,
                px: 2.5,
                mt: "1px",
                // ...(notification.isUnRead && {
                //     bgcolor: "action.selected",
                // }),
            }}
        >
            {/* <ListItemAvatar>
                <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
            </ListItemAvatar> */}
            <ListItemText
                primary={title}
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: "flex",
                            alignItems: "center",
                            color: "text.disabled",
                        }}
                    >
                        <Box
                            component={Icon}
                            icon={clockFill}
                            sx={{ mr: 0.5, width: 16, height: 16 }}
                        />
                        {fToNow(modified)}
                    </Typography>
                }
            />
        </ListItemButton>
    );
}

NotificationContentItem.propTypes = {
    notification: PropTypes.object.isRequired,
};

const countNotifications = (notifications) => {
    if (notifications) {
        let total = 0;
        Object.keys(notifications).forEach(function (key) {
            total += notifications[key].length;
        });
        return total;
    }
    return 0;
};

export default function NotificationsPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [notifications, setNotifications] = useState(null);
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const fetchNotifications = async () => {
        try {
            const res = await get(dispatch, `/api/notifications/basic/`);
            setNotifications(res.data);
            setCount(countNotifications(res.data));
        } catch (err) {}
    };

    useEffect(() => {
        fetchNotifications();

        // return () => {
        //     setNotifications(null);
        // };
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMarkAllAsRead = async () => {
        try {
            setLoading(true);
            await post(dispatch, `/api/notifications/clear/`, null);
            fetchNotifications();
        } catch (err) {
            dispatch(
                showAlert({
                    message:
                        "Beim Markieren von Benachrichtigungen ist ein Fehler aufgetreten.",
                    severity: "error",
                })
            );
        } finally {
            setLoading(false);
        }
    };

    const countReadableNotifications = () => {
        let sum = 0;
        if (notifications) {
            if (notifications.new) {
                sum += notifications.new.length;
            }
            if (notifications.modified) {
                sum += notifications.modified.length;
            }
        }
        return sum;
    };

    const renderNotificationsList = (header, list, maxSize, userList) => {
        if (list) {
            if (list.length === 0) return null;

            let items;
            if (userList) {
                items = list.slice(0, maxSize).map((notification) => {
                    return (
                        <NotificationUserItem
                            key={notification.id}
                            notification={notification}
                        />
                    );
                });
            } else {
                items = list.slice(0, maxSize).map((notification) => {
                    return (
                        <NotificationContentItem
                            key={notification.id}
                            notification={notification}
                        />
                    );
                });
            }

            return (
                <List
                    disablePadding
                    subheader={
                        <ListSubheader
                            disableSticky
                            sx={{
                                py: 1,
                                px: 2.5,
                                typography: "overline",
                            }}
                        >
                            {header}
                        </ListSubheader>
                    }
                >
                    {items}
                </List>
            );
        }

        return null;
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                size="large"
                color={open ? "primary" : "default"}
                onClick={handleOpen}
                sx={{
                    ...(open && {
                        bgcolor: (theme) =>
                            alpha(
                                theme.palette.primary.main,
                                theme.palette.action.focusOpacity
                            ),
                    }),
                }}
            >
                <Badge badgeContent={count} color="error">
                    <Icon icon={bellFill} width={20} height={20} />
                </Badge>
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 360 }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        py: 2,
                        px: 2.5,
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">
                            Benachrichtigungen
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                        >
                            Du hast {count} ungelesene Nachrichten
                        </Typography>
                    </Box>

                    {countNotifications > 0 && (
                        <Tooltip title=" Mark all as read">
                            <IconButton
                                color="primary"
                                onClick={handleMarkAllAsRead}
                            >
                                <Icon
                                    icon={doneAllFill}
                                    width={20}
                                    height={20}
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>

                <Divider />

                {notifications && (
                    <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
                        {renderNotificationsList(
                            "Neu",
                            notifications.new,
                            3,
                            false
                        )}

                        {renderNotificationsList(
                            "Aktualisiert",
                            notifications.modified,
                            3,
                            false
                        )}

                        {renderNotificationsList(
                            "Benutzeranfragen",
                            notifications.newUserRequest,
                            3,
                            true
                        )}
                    </Scrollbar>
                )}

                <Divider />

                <Box sx={{ p: 1 }}>
                    <Button
                        fullWidth
                        disableRipple
                        component={RouterLink}
                        to="/dashboard/notifications"
                    >
                        Alles sehen
                    </Button>
                    {countReadableNotifications() > 0 && (
                        <Button
                            fullWidth
                            disableRipple
                            onClick={handleMarkAllAsRead}
                        >
                            Alle als gelesen markieren
                        </Button>
                    )}
                </Box>
            </MenuPopover>
        </>
    );
}
