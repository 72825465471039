import React, { useEffect, useState, useCallback, Fragment } from "react";
import {
    IconButton,
    Stack,
    styled,
    FormControlLabel,
    FormLabel,
    FormControl,
    Switch,
    Tooltip,
    Fab,
    TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Box } from "@mui/system";
import PopupPreview from "./PopupPreview";
// import PopupCard from "./PopupCard";
import { useDispatch } from "react-redux";
import { showAlert } from "src/redux/slices/AlertSlice";
import { post, get, put } from "src/services/content.service";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Page from "src/components/Page";
import RichTextEditor from "../Content/RichTextEditor";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider, Field } from "formik";
import Loader from "src/components/loader/Loader";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { fDateTime } from "../../../../utils/formatTime";
import axios from "axios";

const schema = Yup.object().shape({
    // signature: Yup.string().min(3).max(2000).required("Required"),
});

const PreviewButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.body2,
    color: "white",
    backgroundColor: theme.palette.info.dark,
    ":hover": {
        backgroundColor: theme.palette.info.light,
    },
}));

const SubmitButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.body2,
    color: "white",
    backgroundColor: theme.palette.success.dark,
    ":hover": {
        backgroundColor: theme.palette.success.light,
    },
}));

const PopupConfig = () => {
    const [loading, setLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorDate, setErrorDate] = useState(false);
    const [contents, setContents] = useState([]);
    const dispatch = useDispatch();

    const today = new Date();

    const filter = createFilterOptions();

    const formik = useFormik({
        initialValues: {
            active: false,
            deadline: new Date(),
            image: { scale: 1, width: 0, height: 0 },
            text: { body: "", positionX: 0, positionY: 0 },
            timer: { active: false, positionX: 0 },
            offer: null,
        },
        validationSchema: schema,
        onSubmit: async (values, actions) => {
            const { active, deadline, image, text, timer, offer } = values;
            setLoading(true);
            dispatch(
                showAlert({
                    message: "Popup updated",
                    severity: "success",
                })
            );
            const requestBody = JSON.stringify({
                active: active,
                deadline: new Date(deadline).toISOString(),
                image: image,
                text: text,
                timer: timer,
                offer: offer,
            });

            try {
                await post(dispatch, `/api/popup/`, requestBody);
                dispatch(
                    showAlert({
                        message: "Popup updated",
                        severity: "success",
                    })
                );
            } catch (err) {
            } finally {
                setLoading(false);
                actions.setSubmitting(false);
            }
        },
    });

    const {
        errors,
        touched,
        handleSubmit,
        values,
        setFieldValue,
        isSubmitting,
    } = formik;

    const uploadImage = async (files) => {
        let file = files[0];
        const name = file.name.replace(/\s+/g, "_").toLowerCase();
        const size = file.size;
        const type = file.type;
        const toBase64 = (file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    // console.log(reader.result);
                    const img = new Image();

                    img.onload = () => {
                        resolve({
                            width: img.width,
                            height: img.height,
                            base64: reader.result,
                        });
                    };

                    img.src = reader.result;
                    // resolve(reader.result);
                };
                reader.onerror = (error) => reject(error);
            });
        setLoading(true);
        try {
            file = await toBase64(file);
            setFieldValue("image", {
                scale:
                    values.image && values.image.scale
                        ? values.image.scale
                        : 1.0,
                id: null,
                name: name,
                size: size,
                type: type,
                file: file.base64,
                width: file.width,
                height: file.height,
            });
        } catch (err) {
            dispatch(
                showAlert({
                    message: err,
                    severity: "error",
                })
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchPopup = async () => {
            try {
                setLoading(true);
                const res = await get(dispatch, `/api/popup/`);
                setFieldValue("active", res.data.active);
                setFieldValue("deadline", res.data?.deadline || new Date());
                setFieldValue(
                    "image",
                    res.data.image
                        ? res.data.image
                        : { scale: 1, width: 0, height: 0 }
                );

                setFieldValue(
                    "text",
                    res.data?.text || { body: "", positionX: 0, positionY: 0 }
                );
                setFieldValue(
                    "timer",
                    res.data?.timer || { active: false, positionX: 0 }
                );
                setFieldValue(
                    "offer",
                    res.data.offer
                        ? res.data.offer
                        : {
                              id: null,
                              slug: "",
                              title: "",
                              body: "",
                              type: null,
                              status: null,
                              created: fDateTime(today),
                              author: "",
                          }
                );
            } catch (err) {
                // console.log(err);
            } finally {
                setLoading(false);
            }
        };

        const fetchContent = async () => {
            setLoading(true);
            try {
                const res = await get(dispatch, `/api/content/admin/all/`);
                setContents(
                    res.data
                        .filter(
                            (option) =>
                                // [
                                //     "article",
                                //     "blog",
                                //     "product",
                                // ].some(
                                //     (type) =>
                                //         type === option.type
                                // )
                                (option.type === "article" ||
                                    option.type === "blog" ||
                                    option.type === "product") &&
                                option.status
                        )
                        .map((data) => {
                            const titleFirstLetter =
                                data.title[0].toUpperCase();
                            const typeFirstLetter = data.type[0].toUpperCase();
                            return {
                                ...data,
                                titleFirstLetter: /[0-9]/.test(titleFirstLetter)
                                    ? "0-9"
                                    : titleFirstLetter,
                                typeFirstLetter: /[0-9]/.test(typeFirstLetter)
                                    ? "0-9"
                                    : typeFirstLetter,
                            };
                        })
                );
            } catch (err) {
                dispatch(
                    showAlert({
                        message: err,
                        severity: "error",
                    })
                );
            } finally {
                setLoading(false);
            }
        };

        fetchContent();
        fetchPopup();
    }, []);

    return (
        <Page title={`Popup Config | TZMO DE Extranet`}>
            <Box sx={{ display: "flex" }}>
                {loading ? (
                    <Loader />
                ) : (
                    <Fragment>
                        {values.image && (
                            <PopupPreview
                                open={showPreview}
                                editable={true}
                                onClose={() => {
                                    setShowPreview(false);
                                }}
                                data={formik.values}
                                setData={setFieldValue}
                            />
                        )}
                        <FormikProvider value={formik}>
                            <Form
                                autoComplete="off"
                                noValidate
                                onSubmit={handleSubmit}
                            >
                                <Stack spacing={3}>
                                    <FormControl>
                                        <FormLabel>Ist Popup aktiv</FormLabel>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    label="Remember Me"
                                                    name="rememberMe"
                                                    component={Switch}
                                                    onChange={(event) => {
                                                        setFieldValue(
                                                            "active",
                                                            event.target.checked
                                                        );
                                                    }}
                                                    checked={values.active}
                                                />
                                            }
                                            label={
                                                values.active === true
                                                    ? "Aktiv"
                                                    : "Inaktiv"
                                            }
                                        />
                                    </FormControl>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DateTimePicker
                                            label="Gültig bis"
                                            value={formik.values.deadline}
                                            onChange={(newValue) => {
                                                formik.setFieldValue(
                                                    "deadline",
                                                    newValue
                                                );
                                            }}
                                            onError={(reason, value) => {
                                                if (reason) {
                                                    // reason is the error , which it will be displayed as
                                                    // default error message ,you can also  pass your own error
                                                    // message depending on the error
                                                    setErrorDate(true);
                                                } else {
                                                    setErrorDate(false);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    required
                                                    {...params}
                                                    error={errorDate}
                                                    helperText={
                                                        errorDate &&
                                                        "Das Datum muss später als die aktuelle Uhrzeit sein"
                                                    }
                                                />
                                            )}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            minDateTime={today}
                                            ampm={false}
                                            error
                                        />
                                    </LocalizationProvider>
                                    <FormControl>
                                        <FormLabel>Timer anzeigen</FormLabel>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    label="Timer anzeigen"
                                                    name="timer"
                                                    component={Switch}
                                                    onChange={(event) => {
                                                        setFieldValue("timer", {
                                                            ...values.timer,
                                                            active: event.target
                                                                .checked,
                                                        });
                                                    }}
                                                    checked={
                                                        values.timer.active
                                                    }
                                                />
                                            }
                                            label={
                                                values.timer.active === true
                                                    ? "Aktiv"
                                                    : "Inaktiv"
                                            }
                                        />
                                    </FormControl>
                                    <FormLabel>
                                        Redaktionelle Bearbeitung des Inhalts
                                    </FormLabel>
                                    <RichTextEditor
                                        value={values.text.body}
                                        setValue={(val) =>
                                            formik.setFieldValue("text", {
                                                ...values.text,
                                                body: val,
                                            })
                                        }
                                    />
                                    <FormLabel>
                                        Laden/Löschen eines Fotos
                                    </FormLabel>
                                    {/* <TextareaAutosize value={values.body} /> */}
                                    <Stack direction="row" spacing={2}>
                                        <Tooltip title="Upload new image">
                                            <label htmlFor="upload-image">
                                                <input
                                                    style={{ display: "none" }}
                                                    id="upload-image"
                                                    name="upload-image"
                                                    type="file"
                                                    onChange={(e) => {
                                                        uploadImage(
                                                            e.target.files
                                                        );
                                                    }}
                                                />
                                                <Fab
                                                    color="primary"
                                                    size="small"
                                                    component="span"
                                                    aria-label="add"
                                                    // variant="extended"
                                                >
                                                    <UploadFileIcon />
                                                </Fab>
                                            </label>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <Fab
                                                color="secondary"
                                                size="small"
                                                component="span"
                                                aria-label="add"
                                                onClick={() => {
                                                    setFieldValue(
                                                        "image",
                                                        null
                                                    );
                                                }}
                                                disabled={
                                                    values.image &&
                                                    !values.image.file
                                                }
                                                // variant="extended"
                                            >
                                                <DeleteIcon />
                                            </Fab>
                                        </Tooltip>

                                        {values.image &&
                                            (values.image.id ||
                                                values.image.file) && (
                                                <img
                                                    style={{
                                                        maxWidth: "200px",
                                                        maxHeight: "200px",
                                                    }}
                                                    src={
                                                        values.image.id
                                                            ? `${axios.defaults.baseURL}${values.image.file}`
                                                            : values.image
                                                                  ?.file || null
                                                    }
                                                    alt="image"
                                                />
                                            )}
                                    </Stack>
                                    <FormLabel>
                                        Weiterleitung zum Artikel auswählen
                                    </FormLabel>
                                    <Autocomplete
                                        value={values.offer}
                                        onChange={(event, newValue) => {
                                            if (typeof newValue === "string") {
                                                // timeout to avoid instant validation of the dialog's form.
                                                setTimeout(() => {
                                                    // toggleOpen(true);
                                                    // setDialogValue({
                                                    //     title: newValue,
                                                    //     year: "",
                                                    // });
                                                });
                                            } else if (
                                                newValue &&
                                                newValue.inputValue
                                            ) {
                                                // toggleOpen(true);
                                                // setDialogValue({
                                                //     title: newValue.inputValue,
                                                //     year: "",
                                                // });
                                            } else {
                                                setFieldValue(
                                                    "offer",
                                                    newValue
                                                );
                                            }
                                        }}
                                        // filterOptions={(options, params) => {
                                        //     // const filtered = options.filter(
                                        //     //     (option) =>
                                        //     //         // [
                                        //     //         //     "article",
                                        //     //         //     "blog",
                                        //     //         //     "product",
                                        //     //         // ].some(
                                        //     //         //     (type) =>
                                        //     //         //         type === option.type
                                        //     //         // )
                                        //     //         (option.type ===
                                        //     //             "article" ||
                                        //     //             option.type ===
                                        //     //                 "blog" ||
                                        //     //             option.type ===
                                        //     //                 "product") &&
                                        //     //         option.status
                                        //     // );
                                        //     const filtered = [...options];
                                        //     // if (params.inputValue !== "") {
                                        //     //     filtered.push({
                                        //     //         inputValue:
                                        //     //             params.inputValue,
                                        //     //         title: `Add "${params.inputValue}"`,
                                        //     //     });
                                        //     // }

                                        //     return filtered;
                                        // }}
                                        id="popup-content-list"
                                        groupBy={(option) => option.type}
                                        options={contents
                                            .sort(
                                                (a, b) =>
                                                    -b.titleFirstLetter.localeCompare(
                                                        a.titleFirstLetter
                                                    )
                                            )
                                            .sort(
                                                (a, b) =>
                                                    -b.typeFirstLetter.localeCompare(
                                                        a.typeFirstLetter
                                                    )
                                            )}
                                        getOptionLabel={(content) => {
                                            // e.g value selected with enter, right from the input
                                            // if (typeof option === "string") {
                                            //     return option;
                                            // }
                                            if (content.inputValue) {
                                                return content.inputValue;
                                            }
                                            return content.title;
                                        }}
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.id}>
                                                {option.title}
                                            </li>
                                        )}
                                        sx={{ width: 300 }}
                                        freeSolo
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Liste der Artikel"
                                            />
                                        )}
                                    />

                                    {/* <Box my={4}>
                                        <PopupCard
                                            textPosition={{
                                                x: values.text.positionX,
                                                y: values.text.positionY,
                                            }}
                                            onTextPositionChanged={(x, y) => {
                                                setFieldValue("text", {
                                                    ...values.text,
                                                    positionX: x,
                                                    positionY: y,
                                                });
                                            }}
                                            text={values.text.body}
                                            image={values.image}
                                            draggable={true}
                                        />
                                    </Box> */}
                                    <FormLabel>Vorschau/Abnahme</FormLabel>
                                    <Stack direction="row" spacing={2}>
                                        <Tooltip
                                            title={
                                                values.image &&
                                                values.image.file
                                                    ? ""
                                                    : "Sie müssen zuerst das Bild laden"
                                            }
                                        >
                                            <span>
                                                <PreviewButton
                                                    disabled={
                                                        values.image &&
                                                        !values.image.file
                                                    }
                                                    onClick={() => {
                                                        setShowPreview(true);
                                                    }}
                                                >
                                                    <VisibilityIcon />
                                                </PreviewButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                errorDate
                                                    ? "Geben Sie das richtige Datum ein"
                                                    : ""
                                            }
                                        >
                                            <span>
                                                <SubmitButton
                                                    type="submit"
                                                    disabled={errorDate}
                                                >
                                                    <CheckIcon />
                                                </SubmitButton>
                                            </span>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Fragment>
                )}
            </Box>
        </Page>
    );
};

export default PopupConfig;
