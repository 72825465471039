const changePasswordMap = new Map([
    [
        "This password is too short. It must contain at least 8 characters.",
        "Dieses Passwort ist zu kurz. Sie muss mindestens 8 Zeichen enthalten.",
    ],
    ["This password is too common.", "Dieses Passwort ist zu häufig."],
    [
        "This password is entirely numeric.",
        "Dieses Passwort ist ausschließlich numerisch.",
    ],
    ["Invalid password.", "Ungültiges Passwort."],
    [
        "The password is too similar to the email.",
        "Das Passwort ist der E-Mail zu ähnlich.",
    ],
]);

export const handleChangePassowrdError = (error) => {
    let message = null;
    if (error.response && error.response.data) {
        if (error.response.data.new_password) {
            message = error.response.data.new_password.map((val) => {
                if (changePasswordMap.has(val)) {
                    return changePasswordMap.get(val);
                }
                return "Fehler";
            });
            message = message.join("\n");
        } else if (error.response.data.current_password) {
            message = error.response.data.current_password.map((val) => {
                if (changePasswordMap.has(val)) {
                    return changePasswordMap.get(val);
                }
                return "Fehler";
            });
            message = message.join("\n");
        } else {
            message = error.message || error.toString();
        }
    }

    return message;
};
