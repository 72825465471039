import { Icon } from "@iconify/react";
import PropTypes from "prop-types";
import { useState } from "react";
import chevronUpFill from "@iconify/icons-eva/chevron-up-fill";
import chevronDownFill from "@iconify/icons-eva/chevron-down-fill";
// material
import { Menu, Button, MenuItem, Typography } from "@mui/material";

// ----------------------------------------------------------------------

ContentSortMenu.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    onSort: PropTypes.func,
    initialValue: PropTypes.object.isRequired,
};
export default function ContentSortMenu({
    title,
    options,
    onSort,
    initialValue,
}) {
    const [value, setValue] = useState(initialValue);
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleOptionClicked = (option) => {
        setValue(option);
        setOpen(null);
        onSort(option.value);
    };

    return (
        <>
            <Button
                color="inherit"
                disableRipple
                onClick={handleOpen}
                endIcon={<Icon icon={open ? chevronUpFill : chevronDownFill} />}
            >
                {title}:&nbsp;
                <Typography
                    component="span"
                    variant="subtitle2"
                    sx={{ color: "text.secondary" }}
                >
                    {value.label}
                </Typography>
            </Button>
            <Menu
                keepMounted
                anchorEl={open}
                open={Boolean(open)}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option.value}
                        // value={option}
                        value="Twoja stara"
                        selected={option.value === value.value}
                        onClick={() => {
                            handleOptionClicked(option);
                        }}
                        sx={{ typography: "body2" }}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
