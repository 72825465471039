import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useSelector, useDispatch } from "react-redux";
import {
    hideAlert,
    selectOpen,
    selectSeverity,
    selectMessage,
} from "src/redux/slices/AlertSlice";

const Alert = ({ duration }) => {
    const open = useSelector(selectOpen);
    const severity = useSelector(selectSeverity);
    const message = useSelector(selectMessage);
    const dispatch = useDispatch();

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(hideAlert());
    };
    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            onClose={handleClose}
            autoHideDuration={duration ? duration : 10000}
        >
            <MuiAlert
                onClose={handleClose}
                severity={severity}
                sx={{ width: "100%" }}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default Alert;
