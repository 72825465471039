import React, { useState, useEffect, useCallback, Fragment } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Stack,
    FormControlLabel,
    Switch,
    Button,
} from "@mui/material";
import PropTypes from "prop-types";

import { Box } from "@mui/system";

import RichTextEditor from "./RichTextEditor";
import Loader from "src/components/loader/Loader";
import Stepper from "./Stepper";

import { useDispatch } from "react-redux";
import { showAlert } from "src/redux/slices/AlertSlice";
import { useSelector } from "react-redux";

import { post, get } from "src/services/content.service";

import AttachmentsStep from "./AttachmentsStep";
import NavigationStep from "./Navigation/NavigationStep";
import filesize from "filesize";
import ThumbnailStep from "./ThumbnailStep";
import StickyForm from "./StickyForm";

const NAV_TYPES = ["content_list", "nested_list", "tab"];

const ContentDialog = ({
    open,
    onClose,
    type,
    contentType,
    slug,
    submitStatus,
}) => {
    const { user } = useSelector((state) => state.auth);
    const [data, setData] = useState({
        userId: user.id,
        id: null,
        type: contentType ? contentType : null,
        status: false,
        title: "",
        body: null,
        sticky: 0,
    });
    const [navAll, setNavAll] = useState(null);
    const [removedNodes, setRemovedNodes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [thumbnail, setThumbnail] = useState(null);
    const [dialogTitle, setDialogTitle] = useState("");
    const [isOpened, setIsOpened] = useState(false);
    const [newNav, setNewNav] = useState(null);
    const [navElements, setNavElements] = useState(null);

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [removedFiles, setRemovedFiles] = useState([]);
    const [modifiedFiles, setModifiedFiles] = useState([]);
    const [rows, setRows] = useState([]);

    const dispatch = useDispatch();

    const cleanup = () => {
        setData({
            userId: null,
            id: null,
            type: null,
            status: false,
            title: "",
            body: null,
            sticky: 0,
        });
        setNavAll(null);
        setLoading(false);
        setThumbnail(null);
        setDialogTitle("");
        setIsOpened(false);
        setNewNav(null);
        setRemovedNodes([]);
        setUploadedFiles([]);
        setRemovedFiles([]);
        setModifiedFiles([]);
        setRows([]);
    };

    const capitalizeFirstLetter = (word) => {
        if (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return "";
    };

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    const findPromotedNav = (fetchedNavs, content) => {
        if (fetchedNavs) {
            let tempNavAll = fetchedNavs;
            for (let el of tempNavAll) {
                const childIdx = el.children.findIndex(
                    (child) =>
                        child.title === content.title &&
                        (child.id ? child.id === content.id : true) //for new nav item we do not have an id, return true
                );
                el.children[childIdx] = {
                    ...el.children[childIdx],
                    promoted: childIdx >= 0,
                };
            }

            setNavAll(tempNavAll);
        }
    };

    function prepareNavData(array) {
        if (array) {
            var result = [];
            array.forEach(function (a) {
                result.push({
                    id: a.id,
                    parent: a.parent,
                    originalParent: a.parent,
                    contentId: a.content.id,
                    title: a.content.title,
                    originalTitle: a.content.title,
                    type: a.content.type,
                    position: a.position,
                    originalPosition: a.position,
                    children: Array.isArray(a.children)
                        ? prepareNavData(a.children)
                        : [],
                });
            });
            return result;
        }
    }

    function findNavElements(array, result) {
        let navElements = result ? result : [];
        if (array) {
            array.forEach(function (a) {
                let obj = {
                    id: a.id,
                    parent: a.parent,
                    originalParent: a.parent,
                    contentId: a.content.id,
                    title: a.content.title,
                    originalTitle: a.content.title,
                    type: a.content.type,
                    position: a.position,
                    originalPosition: a.position,
                };

                if (NAV_TYPES.includes(obj.type)) {
                    navElements.push(obj);
                }

                if (Array.isArray(a.children) && a.children.length > 0) {
                    findNavElements(a.children, navElements);
                }
            });
        }
        return navElements;
    }

    const fetchData = useCallback(async () => {
        const updateRows = (files) => {
            if (files && files.length > 0) {
                let tempRows = [];
                for (const file of files) {
                    tempRows.push({
                        Id: file.id,
                        FileName: file.fileName,
                        FilePath: file.filePath,
                        FileSize: filesize(file.fileSize),
                        Type: file.type,
                    });
                }
                setRows(tempRows);
            }
        };
        try {
            setLoading(true);
            let res = null;
            let navData = null;
            if (slug && type === "edit") {
                const res = await get(
                    dispatch,
                    `/api/content/admin/edit/${slug}`
                );

                updateRows(res.data.files);
                setData({
                    ...data,
                    id: res.data.content.id,
                    type: res.data.content.type,
                    status: res.data.content.status,
                    news: false,
                    title: res.data.content.title,
                    body: res.data.content.body,
                    sticky: res.data.content.sticky,
                });
                setDialogTitle(
                    `${capitalizeFirstLetter(type)} ${res.data.content.type}`
                );
                if (res.data.thumbnail) {
                    setThumbnail({
                        id: res.data.thumbnail.id,
                        name: res.data.thumbnail.fileName,
                        size: res.data.thumbnail.fileSize,
                        type: res.data.thumbnail.type,
                        file: res.data.thumbnail.filePath,
                    });
                }
                navData = res.data.navAll;
            } else if (contentType && type === "add") {
                setData({
                    ...data,
                    id: -1,
                    type: contentType,
                    news: true,
                });
                setDialogTitle(`${capitalizeFirstLetter(type)} ${contentType}`);
                // new content
                res = await get(
                    dispatch,
                    `/api/content/admin/add/${contentType}`
                );
                navData = res.data;
                if (contentType !== "navigation") {
                    setNewNav({
                        id: -1,
                        title: "",
                        type: contentType,
                    });
                }
            } else {
                dispatch(
                    showAlert({
                        message: "Invalid input",
                        severity: "error",
                    })
                );
                handleClose();
            }
            setNavAll(prepareNavData(navData));
            if (contentType !== "navigation") {
                setNavElements(findNavElements(navData));
            }
        } catch (error) {
            dispatch(
                showAlert({
                    message: error,
                    severity: "error",
                })
            );
            handleClose();
        } finally {
            setLoading(false);
        }
    }, [slug, type, handleClose, data, contentType, dispatch]);

    useEffect(() => {
        if (open && !isOpened) {
            setIsOpened(true);
            // setData({
            //     ...data,
            //     userId: user.id,
            // });
            fetchData();
        } else if (!open && isOpened) {
            cleanup();
        }
    }, [open, isOpened, fetchData]);

    useEffect(() => {
        return () => {
            cleanup();
        };
    }, []);

    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const updateTitle = (e) => {
        const newTitle = e.target.value;
        if ((newNav && newNav.title === "") || newNav) {
            //update newNav if not dragged yet
            setNewNav({
                ...newNav,
                title: newTitle,
            });
        }
        if (navAll) {
            let tempNavAll = [...navAll];

            for (const el of tempNavAll) {
                const childIdx = el.children.findIndex(
                    (child) => child.title === data.title
                );
                if (childIdx >= 0) {
                    // found element
                    el.children[childIdx].title = newTitle;
                    break;
                }
            }

            setNavAll(tempNavAll);
        }

        onChange(e);
    };

    const updateBody = (newBody) => {
        setData({ ...data, body: newBody });
    };

    function replacer(key, value) {
        if (value instanceof Map) {
            return {
                dataType: "Map",
                // value: Array.from(value.entries()), // or with spread: value: [...value]
                value: [...value],
            };
        } else {
            return value;
        }
    }

    function prepareOutput(array) {
        var result = [];
        array.forEach(function (a, index) {
            result.push({
                ...a,
                position: index,
                changed:
                    a.originalParent !== a.parent ||
                    a.originalTitle !== a.title ||
                    a.originalPosition !== index,
            });
            if (Array.isArray(a.children)) {
                result = result.concat(prepareOutput(a.children));
            }
        });
        return result;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const body = JSON.stringify(
            {
                data: { ...data, userId: user.id },
                uploadedFiles: uploadedFiles,
                removedFiles: removedFiles,
                modifiedFiles: modifiedFiles,
                thumbnail: thumbnail,
                nav: {
                    modified:
                        navAll && navAll.length
                            ? prepareOutput(navAll).filter((nav) => nav.changed)
                            : [],
                    removed: removedNodes,
                },
            },
            replacer
        );

        setLoading(true);
        try {
            await post(dispatch, `/api/content/admin/${type}/`, body);

            submitStatus(true);
            window.location.reload();
        } catch (err) {
            dispatch(
                showAlert({
                    message: err,
                    severity: "error",
                })
            );
            submitStatus(false);
        } finally {
            setLoading(false);
        }
        // force to refetch data when dialog is reopened
        handleClose();
    };

    const getTitle = () => (
        <TextField
            id="outlined-basic"
            label="Title"
            variant="outlined"
            name="title"
            value={data && data.title ? data.title : ""}
            onChange={(e) => updateTitle(e)}
            fullWidth
        />
    );

    const getContent = () => (
        <RichTextEditor
            value={data && data.body ? data.body : null}
            setValue={updateBody}
            sx={{ margin: "2px" }}
        />
    );

    const getPublished = () => (
        <Stack spacing={2}>
            <FormControlLabel
                control={
                    <Switch
                        checked={data.status}
                        // value="active"
                        onChange={(e) => {
                            setData({
                                ...data,
                                status: e.target.checked,
                            });
                        }}
                    />
                }
                label="Published"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={data.news}
                        // value="active"
                        onChange={(e) => {
                            setData({
                                ...data,
                                news: e.target.checked,
                            });
                        }}
                    />
                }
                label="Visible in news"
            />
            <StickyForm
                content={data}
                setSticky={(newSticky) => {
                    setData({ ...data, sticky: newSticky });
                }}
            />
        </Stack>
    );

    const getNavigation = (id) => (
        <NavigationStep
            currentNav={id}
            navAll={navAll}
            setNavAll={(newNav) => {
                setNavAll(newNav);
            }}
            removeNode={{
                removedNodes: removedNodes,
                setRemovedNodes: setRemovedNodes,
            }}
            newNav={{
                newNode: newNav,
                setNewNode: setNewNav,
            }}
            setNewNav={setNewNav}
            navElements={navElements}
        />
    );

    const commonSteps = [
        { label: "Edit Title", content: getTitle(), optional: true },
        { label: "Edit Content", content: getContent(), optional: true },
        {
            label: "Attachments",
            content: (
                <AttachmentsStep
                    slug={slug}
                    showErrorMessage={(err) =>
                        dispatch(
                            showAlert({
                                message: err,
                                severity: "error",
                            })
                        )
                    }
                    upload={{
                        uploadedFiles: uploadedFiles,
                        setUploadedFiles: setUploadedFiles,
                    }}
                    remove={{
                        removedFiles: removedFiles,
                        setRemovedFiles: setRemovedFiles,
                    }}
                    modified={{
                        modifiedFiles: modifiedFiles,
                        setModifiedFiles: setModifiedFiles,
                    }}
                    tableRows={{
                        rows: rows,
                        setRows: setRows,
                    }}
                />
            ),
            optional: true,
        },
        {
            label: "Thumbnail",
            content: (
                <ThumbnailStep
                    setThumbnail={setThumbnail}
                    attachments={rows}
                    thumbnail={thumbnail}
                    showErrorMessage={(err) =>
                        dispatch(
                            showAlert({
                                message: err,
                                severity: "error",
                            })
                        )
                    }
                />
            ),
            optional: true,
        },
    ];
    const getSteps = () => {
        if (navAll) {
            return commonSteps.concat([
                {
                    label: "Edit Navigation",
                    content: getNavigation(data),
                    optional: true,
                },
            ]);
        }
        return commonSteps;
    };

    const isSingleStep = () => {
        return (
            contentType &&
            (contentType === "navigation" || contentType === "filters") &&
            type === "add"
        );
    };
    const getSingleStepContent = () => {
        if (contentType === "navigation") {
            return getNavigation();
        } else {
            return <></>;
        }
    };
    const renderDialogContent = () => {
        if (isSingleStep()) {
            return (
                <Fragment>
                    <Box sx={{ display: "flex", m: 2 }}>
                        {getSingleStepContent()}
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </Box>
                </Fragment>
            );
        }
        return (
            <Stepper
                steps={getSteps()}
                finalStep={getPublished()}
                onCancel={handleClose}
                onSubmit={handleSubmit}
            />
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="lg"
            height={"80vh"}
        >
            <DialogTitle>{dialogTitle && dialogTitle}</DialogTitle>
            <DialogContent>
                {loading ? <Loader /> : renderDialogContent()}
            </DialogContent>
        </Dialog>
    );
};

ContentDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string.isRequired,
    contentType: PropTypes.string,
    slug: PropTypes.string,
    submitStatus: PropTypes.func,
};

export default ContentDialog;
