import React, { useState } from "react";
import Page from "src/components/Page";

//material
import { Tabs, Tab } from "@mui/material";
import { Box } from "@mui/system";

import AccountInformation from "src/components/Profile/AccountInformation";

const tabs = [
    { label: "Kontoinformation", tabContent: <AccountInformation /> },
];

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Profile = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Page title="Profil | TZMO DE Extranet">
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        {tabs.map((child, index) => {
                            return (
                                <Tab
                                    label={child.label}
                                    {...a11yProps(index)}
                                    key={index}
                                />
                            );
                        })}
                    </Tabs>
                </Box>
                {tabs.map((child, index) => {
                    return (
                        <TabPanel value={value} index={index} key={index}>
                            {child.tabContent}
                        </TabPanel>
                    );
                })}
            </Box>
        </Page>
    );
};

export default Profile;
