import React, { useState, useEffect } from "react";
// material
import { Stack } from "@mui/material";
// components
import Loader from "src/components/loader/Loader";
import Page from "../components/Page";
import { useDispatch } from "react-redux";
import { get } from "src/services/content.service";
import RecentCard from "src/components/dashboard/recent/RecentCard";
import RecentUsersCard from "../components/dashboard/recent/RecentUsersCard";

import { motion } from "framer-motion";
// material
import { Box, Typography, Container } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "../components/animate";

const NotificationsList = ({ category }) => {
    const [notifications, setNotifications] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchNotifications = async () => {
            setLoading(true);
            try {
                let endpoint =
                    category === "users"
                        ? `/api/notifications/users`
                        : `/api/notifications/all/${category}/`;
                const res = await get(dispatch, endpoint);
                setNotifications(res.data);
            } catch (err) {
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();

        // return () => {
        //     setNotifications(null);
        // };
    }, []);

    const renderEmptyNotifications = () => {
        return (
            <Container>
                <MotionContainer initial="initial" open>
                    <Box
                        sx={{
                            maxWidth: 480,
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        <motion.div variants={varBounceIn}>
                            <Typography variant="h3" paragraph>
                                {"Sie haben keine Benachrichtigungen"}
                            </Typography>
                        </motion.div>
                        {/* <Typography sx={{ color: "text.secondary" }}>
                        {text ? text : defaultText}
                    </Typography> */}

                        <motion.div variants={varBounceIn}>
                            <Box
                                component="img"
                                src="/static/illustrations/empty.svg"
                                sx={{
                                    height: 260,
                                    mx: "auto",
                                    my: { xs: 5, sm: 10 },
                                }}
                            />
                        </motion.div>
                    </Box>
                </MotionContainer>
            </Container>
        );
    };

    const renderNotificationsList = () => {
        if (loading) {
            return <Loader />;
        }
        if (notifications && notifications.length > 0) {
            if (category === "users") {
                return (
                    <Stack spacing={3}>
                        {notifications.map((notification, index) => (
                            <RecentUsersCard
                                key={notification.uid}
                                user={notification}
                                index={index}
                            />
                        ))}
                    </Stack>
                );
            } else {
                return (
                    <Stack spacing={3}>
                        {notifications.map((notification, index) => (
                            <RecentCard
                                key={notification.content.id}
                                post={notification}
                                index={index}
                                route="/dashboard/content/"
                            />
                        ))}
                    </Stack>
                );
            }
        }
        return renderEmptyNotifications();
    };

    return (
        <Page title="Dashboard: All notifications | TZMO DE Extranet">
            {renderNotificationsList()}
        </Page>
    );
};

export default NotificationsList;
