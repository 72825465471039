import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
// components
import { MotionContainer, varBounceIn } from "../components/animate";
import PropTypes from "prop-types";
import Page from "../components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    display: "flex",
    minHeight: "100%",
    alignItems: "center",
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
}));

Page404.propTypes = {
    pageTitle: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    disableGoBack: PropTypes.bool,
};

// ----------------------------------------------------------------------

export default function Page404({ pageTitle, title, text, disableGoBack }) {
    const defaultTitle = "Sorry, Seite nicht gefunden!";
    const defaultText = `Leider konnten wir die von Ihnen gesuchte Seite nicht finden. Vielleicht haben Sie sich bei der URL vertippt? Überprüfen Sie bitte Ihre Rechtschreibung.`;
    const defaultPageTitle = `404 Seite nicht gefunden`;
    return (
        <RootStyle title={pageTitle ? pageTitle : defaultPageTitle}>
            <Container maxWidth={false}>
                <MotionContainer initial="initial" open>
                    <Box
                        sx={{
                            maxWidth: 480,
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        <motion.div variants={varBounceIn}>
                            <Typography variant="h3" paragraph>
                                {title ? title : defaultTitle}
                            </Typography>
                        </motion.div>
                        <Typography sx={{ color: "text.secondary" }}>
                            {text ? text : defaultText}
                        </Typography>

                        <motion.div variants={varBounceIn}>
                            <Box
                                component="img"
                                src="/static/illustrations/illustration_404.svg"
                                sx={{
                                    height: 260,
                                    mx: "auto",
                                    my: { xs: 5, sm: 10 },
                                }}
                            />
                        </motion.div>
                        {!disableGoBack && (
                            <Button
                                to=""
                                size="large"
                                variant="contained"
                                component={RouterLink}
                            >
                                Zurück zur Startseite
                            </Button>
                        )}
                    </Box>
                </MotionContainer>
            </Container>
        </RootStyle>
    );
}
