// material-ui
import PropagateLoader from "react-spinners/PropagateLoader";
import { styled, useTheme } from "@mui/material/styles";

const RootStyle = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    alignItems: "center",
    justifyContent: "center",
}));

// ==============================|| LOADER ||============================== //
const Loader = () => {
    const theme = useTheme();
    return (
        <RootStyle>
            <PropagateLoader color={theme.palette.primary.main} />
        </RootStyle>
    );
};

export default Loader;
