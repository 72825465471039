import * as Yup from "yup";
import { useState, forwardRef, useImperativeHandle } from "react";
import { Icon } from "@iconify/react";
import { useFormik, Form, FormikProvider } from "formik";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import { useNavigate } from "react-router-dom";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";

import { useDispatch } from "react-redux";

import { register } from "src/redux/slices/Auth";
import { showAlert } from "../../../redux/slices/AlertSlice";

// ----------------------------------------------------------------------

const RegisterForm = forwardRef(({ setSubmitting }, ref) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();

    const RegisterSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, "Zu kurz!")
            .max(50, "Zu lang!")
            .required("*Pflichtfeld"),
        last_name: Yup.string()
            .min(2, "Zu kurz!")
            .max(50, "Zu lang!")
            .required("*Pflichtfeld"),
        email: Yup.string()
            .email("Email must be a valid email address")
            .required("*Pflichtfeld"),
        password: Yup.string().required("*Pflichtfeld"),
        re_password: Yup.string()
            .required("*Pflichtfeld")
            .oneOf(
                [Yup.ref("password"), null],
                "Die Passwörter müssen übereinstimmen"
            ),
        company_name: Yup.string()
            .min(2, "Zu kurz!")
            .max(50, "Zu lang!")
            .required("*Pflichtfeld"),
        company_address: Yup.string()
            .min(2, "Zu kurz!")
            .max(50, "Zu lang!")
            .required("*Pflichtfeld"),
        postcode_location: Yup.string()
            .min(2, "Zu kurz!")
            .max(50, "Zu lang!")
            .required("*Pflichtfeld"),
        customer_number: Yup.string()
            .min(1, "Zu kurz!")
            .max(50, "Zu lang!")
            .required("*Pflichtfeld"),
        phone: Yup.string()
            .min(1, "Zu kurz!")
            .max(50, "Zu lang!")
            .required("*Pflichtfeld"),
    });

    const formik = useFormik({
        initialValues: {
            name: "",
            last_name: "",
            company_name: "",
            company_address: "",
            postcode_location: "",
            customer_number: "",
            email: "",
            password: "",
            re_password: "",
            phone: "",
        },
        validationSchema: RegisterSchema,
        onSubmit: (values, actions) => {
            setLoading(true);
            setSubmitting(true);
            const {
                name,
                last_name,
                company_name,
                company_address,
                postcode_location,
                customer_number,
                email,
                password,
                re_password,
                phone,
            } = values;
            dispatch(
                register({
                    name,
                    last_name,
                    email,
                    password,
                    re_password,
                    customer_number,
                    company_name,
                    company_address,
                    postcode_location,
                    phone,
                })
            )
                .unwrap()
                .then(() => {
                    setLoading(false);
                    setSubmitting(false);
                    actions.setSubmitting(false);
                    dispatch(
                        showAlert({
                            message:
                                "Ihre Anmeldung wird bearbeitet, in Kürze erhalten Sie eine Bestätigungsmail.",
                            severity: "success",
                        })
                    );
                    navigate("/dashboard", { replace: true });
                })
                .catch(() => {
                    setLoading(false);
                    setSubmitting(false);
                    actions.setSubmitting(false);
                });
        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } =
        formik;

    useImperativeHandle(ref, () => ({
        submitForm() {
            formik.handleSubmit();
        },
    }));

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2} sx={{ mb: 1 }}>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Benutzername"
                            {...getFieldProps("name")}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />

                        <TextField
                            fullWidth
                            label="Vor- und Nachname"
                            {...getFieldProps("last_name")}
                            error={Boolean(
                                touched.last_name && errors.last_name
                            )}
                            helperText={touched.last_name && errors.last_name}
                        />
                    </Stack>

                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="E-Mail Adresse"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                        <TextField
                            fullWidth
                            autoComplete="current-password"
                            type={showPassword ? "text" : "password"}
                            label="Passwort"
                            {...getFieldProps("password")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={() =>
                                                setShowPassword((prev) => !prev)
                                            }
                                        >
                                            <Icon
                                                icon={
                                                    showPassword
                                                        ? eyeFill
                                                        : eyeOffFill
                                                }
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                        />
                        <TextField
                            fullWidth
                            autoComplete="re_password"
                            type={showPassword ? "text" : "password"}
                            label="Passwort bestätigen"
                            {...getFieldProps("re_password")}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            edge="end"
                                            onClick={() =>
                                                setShowPassword((prev) => !prev)
                                            }
                                        >
                                            <Icon
                                                icon={
                                                    showPassword
                                                        ? eyeFill
                                                        : eyeOffFill
                                                }
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={Boolean(
                                touched.re_password && errors.re_password
                            )}
                            helperText={
                                touched.re_password && errors.re_password
                            }
                        />
                    </Stack>

                    <TextField
                        fullWidth
                        label="Firmenname"
                        {...getFieldProps("company_name")}
                        error={Boolean(
                            touched.company_name && errors.company_name
                        )}
                        helperText={touched.company_name && errors.company_name}
                    />

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Firmenanschrift"
                            {...getFieldProps("company_address")}
                            error={Boolean(
                                touched.company_address &&
                                    errors.company_address
                            )}
                            helperText={
                                touched.company_address &&
                                errors.company_address
                            }
                        />

                        <TextField
                            fullWidth
                            label="PLZ / Ort"
                            {...getFieldProps("postcode_location")}
                            error={Boolean(
                                touched.postcode_location &&
                                    errors.postcode_location
                            )}
                            helperText={
                                touched.postcode_location &&
                                errors.postcode_location
                            }
                        />
                    </Stack>

                    <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                        <TextField
                            fullWidth
                            label="Kunden-Nr"
                            {...getFieldProps("customer_number")}
                            error={Boolean(
                                touched.customer_number &&
                                    errors.customer_number
                            )}
                            helperText={
                                touched.customer_number &&
                                errors.customer_number
                            }
                        />

                        <TextField
                            fullWidth
                            label="Telefon"
                            {...getFieldProps("phone")}
                            error={Boolean(touched.phone && errors.phone)}
                            helperText={touched.phone && errors.phone}
                        />
                    </Stack>
                </Stack>
            </Form>
        </FormikProvider>
    );
});

export default RegisterForm;
