import { format, formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

// ----------------------------------------------------------------------
export function fDate(date) {
    return format(new Date(date), "dd MMMM yyyy", { locale: de });
}

export function fDateTime(date) {
    return format(new Date(date), "dd MMM yyyy HH:mm", { locale: de });
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), "dd/MM/yyyy HH:mm", { locale: de });
}

export function fCountdownTimerFormat(date) {
    return format(new Date(date), "yyyy-MM-dd HH:mm:ss", { locale: de });
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: de,
    });
}

export function isWinter() {
    const dt = new Date();
    const m = dt.getMonth();
    return m === 11 ? dt.getDate() >= 6 : m === 2 ? dt.getDate() < 21 : m < 2;
}
