import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// material
import { Grid, Container, Stack, Typography, Pagination } from "@mui/material";
import { Box } from "@mui/system";
// components
import Page from "src/components/Page";
import { BlogPostCard } from "src/components/dashboard/blog";
import ContentSortMenu from "src/components/ContentSortMenu";
import NotFound from "src/pages/Page404";
import Loader from "src/components/loader/Loader";

import { useDispatch } from "react-redux";
import { post } from "src/services/content.service";

const RESULTS_PER_PAGE = [
    { value: "12", label: "12" },
    { value: "24", label: "24" },
    { value: "36", label: "36" },
];

const Search = () => {
    const { searchPhrase } = useParams();

    const [articles, setArticles] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(RESULTS_PER_PAGE[0].value);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (event, value) => {
        setPage(value);
        visitPage(value);
    };

    const handlePageCountChanged = (newVal) => {
        setPageCount(newVal);
    };

    const visitPage = async (page) => {
        window.scrollTo(0, 0);

        const body = JSON.stringify({
            page_count: pageCount,
            sort_by: "latest",
            search: searchPhrase,
        });

        try {
            const res = await post(
                dispatch,
                `/api/content/search/?page=${page}`,
                body
            );
            setArticles(res.data.results);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const renderArticles = () => {
        if (articles && articles.length > 0) {
            return (
                <>
                    {" "}
                    <Grid container spacing={3}>
                        {articles.map((article, index) => (
                            <BlogPostCard
                                key={article.content.slug}
                                post={article}
                                index={index}
                                route="/dashboard/content/"
                            />
                        ))}
                    </Grid>
                    <Box
                        sx={{
                            mt: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Pagination
                            count={Math.ceil(count / pageCount)}
                            page={page}
                            onChange={handleChange}
                        />
                    </Box>
                </>
            );
        }
        return (
            <NotFound
                title="Sorry, keine Ergebnisse gefunden"
                text="Wir konnten leider keine Ergebnisse finden, die Ihren Kriterien entsprechen. Überprüfen Sie bitte Ihre Schreibweise."
                disableGoBack={true}
            />
        );
    };

    useEffect(() => {
        const fetchArticles = async () => {
            setLoading(true);

            const body = JSON.stringify({
                page_count: pageCount,
                sort_by: "latest",
                search: searchPhrase,
            });
            try {
                const res = await post(
                    dispatch,
                    `/api/content/search/?page=1`,
                    body
                );
                setArticles(res.data.results);
                setCount(res.data.count);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [searchPhrase, pageCount, dispatch]);

    return (
        <Page title="Dashboard: Search | TZMO DE Extranet">
            <Container maxWidth={false}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={5}
                >
                    <Typography variant="h4" gutterBottom>
                        Suchergebnisse für {searchPhrase}
                    </Typography>
                </Stack>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mb: 5,
                    }}
                >
                    <Box sx={{ direction: "row" }}>
                        <ContentSortMenu
                            initialValue={RESULTS_PER_PAGE[0]}
                            title="Ergebnisse pro Seite"
                            options={RESULTS_PER_PAGE}
                            onSort={handlePageCountChanged}
                        />
                    </Box>
                </Box>

                {loading ? <Loader /> : renderArticles()}
            </Container>
        </Page>
    );
};

export default Search;
