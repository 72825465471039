import React, { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import { Stepper, Step, StepButton, Button, Typography } from "@mui/material";

export default function HorizontalLinearStepper({
    steps,
    finalStep,
    onCancel,
    onSubmit,
}) {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [completed, setCompleted] = useState({});

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const totalSteps = () => {
        return steps.length;
    };

    // const completedSteps = () => {
    //     return Object.keys(completed).length;
    // };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const allStepsCompleted = () => {
        //return completedSteps() === totalSteps();
        return true; //TODO: temporary
    };
    // const handleNext = () => {
    //     let newSkipped = skipped;
    //     if (isStepSkipped(activeStep)) {
    //         newSkipped = new Set(newSkipped.values());
    //         newSkipped.delete(activeStep);
    //     }

    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //     setSkipped(newSkipped);
    // };

    // const handleComplete = () => {
    //     const newCompleted = completed;
    //     newCompleted[activeStep] = true;
    //     setCompleted(newCompleted);
    // };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                  steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!steps[activeStep].optional) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        } else {
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel nonLinear>
                {steps.map((step, index) => {
                    const { label } = step;
                    const stepProps = {};
                    // const labelProps = {};

                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} completed={completed[index]}>
                            <StepButton
                                color="inherit"
                                // onClick={handleStep(index)}
                                onClick={handleStep(index)}
                            >
                                {label}
                            </StepButton>
                            {/* <StepLabel {...labelProps}>{label}</StepLabel> */}
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <Fragment>
                    {finalStep ? (
                        finalStep
                    ) : (
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - can submit changes
                        </Typography>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={onCancel}>Cancel</Button>
                        <Button onClick={onSubmit}>Submit</Button>
                    </Box>
                </Fragment>
            ) : (
                <Fragment>
                    <Box sx={{ display: "flex", m: 2 }}>
                        {steps && steps[activeStep] && steps[activeStep].content
                            ? steps[activeStep].content
                            : null}
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Zurück
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        {steps &&
                            steps[activeStep] &&
                            steps[activeStep].optional && (
                                <Button
                                    color="inherit"
                                    onClick={handleSkip}
                                    sx={{ mr: 1 }}
                                >
                                    überspringen
                                </Button>
                            )}
                        {activeStep < steps.length - 1 && (
                            <Button onClick={handleNext}>Next</Button>
                        )}
                        <Button
                            onClick={() => {
                                setActiveStep(steps.length);
                            }}
                        >
                            Finish
                        </Button>
                    </Box>
                </Fragment>
            )}
        </Box>
    );
}
