import React, { useState, useCallback, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
    Dialog,
    Slider,
    Typography,
    IconButton,
    Typoraphy,
} from "@mui/material";

import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
// import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { fCountdownTimerFormat } from "../../../../utils/formatTime";
import PopupCard from "./PopupCard";

const CloseButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.body2,
    color: "white",
    position: "absolute",

    backgroundColor: theme.palette.error.dark,
    ":hover": {
        backgroundColor: theme.palette.error.light,
    },
}));

const SubmitButton = styled(IconButton)(({ theme }) => ({
    ...theme.typography.body2,
    color: "white",
    position: "absolute",
    left: "92%",
    bottom: "-6%",
    backgroundColor: theme.palette.success.dark,
    ":hover": {
        backgroundColor: theme.palette.success.light,
    },
}));

const ScaleSlider = styled(Slider)(({ theme }) => ({
    // ...theme.typography.body2,
    // color: "white",
    position: "absolute",
    // left: "96%",
    bottom: "-20px",
    // ":hover": {
    //     backgroundColor: theme.palette.success.light,
    // },
}));

const PopupPreview = ({ open, onClose, data, setData, editable }) => {
    let navigate = useNavigate();
    const [showTextEditor, setShowTextEditor] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const dispatch = useDispatch();
    let maxScale = 0;
    let imageWidth = 0;
    let imageHeight = 0;
    if (
        data &&
        data.image &&
        data.image.width &&
        data.image.height &&
        data.image.scale
    ) {
        maxScale =
            data.image.width > data.image.height
                ? window.innerWidth / data.image.width
                : window.innerHeight / data.image.height;
        imageWidth = data.image.width * data.image.scale;
        imageHeight = data.image.height * data.image.scale;
    }

    String.prototype.replaceBetween = function (
        startIndex,
        endIndex,
        newValue
    ) {
        return (
            this.substring(0, startIndex) + newValue + this.substring(endIndex)
        );
    };

    const setText = (type, val) => {
        if (type === "position") {
            const { x, y } = val;
            setData("text", {
                ...data.text,
                positionX: x,
                positionY: y,
            });
        } else if (type === "body") {
            setData("text", {
                ...data.text,
                body: val,
            });
        }
    };

    const cleanup = () => {
        setIsOpened(false);
    };
    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    overflowY: "unset",
                    maxWidth: "100%",
                    maxHeight: "100%",
                },
            }}
        >
            <Box
                sx={{
                    width: imageWidth,
                    height: imageHeight,
                }}
            >
                <PopupCard
                    text={data.text}
                    setText={setText}
                    image={data.image}
                    draggable={editable}
                    offer={data.offer}
                    onPopupClicked={() => {
                        handleClose();
                        if (
                            data.offer &&
                            data.offer.id &&
                            data.offer.type &&
                            data.offer.slug
                        ) {
                            navigate(
                                `/dashboard/${data.offer.type}/${data.offer.slug}`
                            );
                        }
                    }}
                />
            </Box>
            {editable && (
                <Box
                    sx={{
                        position: "fixed",
                        top: 50,
                        right: 50,
                        width: "200px",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "auto",
                        zIndex: 1000,
                    }}
                >
                    <Typography color="white">Bildmaßstab</Typography>
                    <Slider
                        value={data.image.scale}
                        onChange={(e, newValue) => {
                            setData("image", {
                                ...data.image,
                                scale: newValue,
                            });
                        }}
                        min={1}
                        max={maxScale}
                        step={maxScale / 10}
                        valueLabelDisplay="auto"
                    />
                    <Typography color="white">
                        Position der Zeitschaltuhr --
                    </Typography>
                    <Slider
                        value={data.timer.positionX}
                        onChange={(e, newValue) => {
                            setData("timer", {
                                ...data.timer,
                                positionX: newValue,
                            });
                        }}
                        min={0}
                        max={window.innerHeight}
                        step={window.innerHeight / 20}
                        valueLabelDisplay="auto"
                    />
                </Box>
            )}

            <CloseButton
                sx={{
                    right: "-20px",
                    // bottom: "-6%",
                    top: "-20px",
                }}
                onClick={() => {
                    handleClose();
                }}
            >
                <CloseIcon />
            </CloseButton>
            {data.timer && (
                <Box
                    sx={{
                        position: "fixed",
                        top: `${data.timer.positionX}px`,
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        left: 0,
                        right: 0,
                        margin: "auto",
                    }}
                >
                    <FlipClockCountdown
                        onComplete={!editable ? onClose : undefined}
                        to={fCountdownTimerFormat(data.deadline)} // Date/Time
                        labels={["TAGEN", "STUNDEN", "MINUTEN", "SEKUNDEN"]}
                    />
                </Box>
            )}
        </Dialog>
    );
};

export default PopupPreview;
