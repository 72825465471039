import PropTypes from "prop-types";
// material
import { Grid } from "@mui/material";
import { BlogPostCard } from "../blog";

// ----------------------------------------------------------------------

ProductList.propTypes = {
    products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {
    return (
        <Grid container spacing={3} {...other}>
            {products.map((product, index) => (
                // <Grid key={product.content.slug} item xs={12} sm={6} md={3}>

                // </Grid>
                <BlogPostCard
                    key={product.content.slug}
                    post={product}
                    index={index}
                    route="/dashboard/product/"
                />
            ))}
        </Grid>
    );
}
