import React from "react";
import { useDrag } from "react-dnd";
import { Box } from "@mui/system";
export const externalNodeType = "nodeType";

const ExternalNode = ({ node, onNodeDropped }) => {
    const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
        type: externalNodeType,
        item: { node },
        end: (item, monitor) => {
            if (monitor.didDrop()) {
                onNodeDropped();
            }
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <Box sx={{ display: "flex" }}>
            {isDragging ? (
                <div ref={dragPreview} />
            ) : (
                <div
                    ref={drag}
                    style={{
                        opacity: isDragging ? 0.5 : 1,
                        fontSize: 25,
                        fontWeight: "bold",
                        cursor: "move",
                        border: "1px dashed grey",
                    }}
                >
                    {node.title}
                </div>
            )}
        </Box>
    );
};

export default ExternalNode;
