import React, { useState, useEffect, useCallback } from "react";
import RichTextEditor from "../components/dashboard/admin/Content/RichTextEditor";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import Loader from "src/components/loader/Loader";

// Formik
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import { useDispatch } from "react-redux";
import { get, post } from "src/services/content.service";
import Page from "src/components/Page";

import { showAlert } from "../redux/slices/AlertSlice";

const schema = Yup.object().shape({
    subject: Yup.string().min(3).max(2000).required("*Pflichtfeld"),
    body: Yup.string().min(10).max(2000).required("*Pflichtfeld"),
});

const Contact = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            category: [],
            subject: "",
            body: "",
        },
        validationSchema: schema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const { category, subject, body } = values;

            const reqBody = JSON.stringify({
                category: category,
                subject: subject,
                body: body,
            });
            setLoading(true);

            try {
                await post(dispatch, `/api/contact/`, reqBody);
                dispatch(
                    showAlert({
                        message: "E-Mail wurde gesendet",
                        severity: "success",
                    })
                );
            } catch (err) {
                dispatch(
                    showAlert({
                        message:
                            "Beim Senden einer E-Mail ist ein Fehler aufgetreten",
                        severity: "error",
                    })
                );
            } finally {
                setLoading(false);
                setSubmitting(false);
                formik.setFieldValue("body", "");
                resetForm();
            }
        },
    });

    const {
        errors,
        touched,
        values,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        getFieldProps,
    } = formik;

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchCategories = async () => {
            try {
                setLoading(true);
                const res = await get(dispatch, `/api/contact/`);
                setCategories(res.data);
                setFieldValue("category", res.data[0]);
            } catch (err) {
                // console.log(err);
            } finally {
                setLoading(false);
            }
        };
        fetchCategories();
    }, []);

    return (
        <Page title={`Kontakt | TZMO DE Extranet`}>
            <Box sx={{ display: "flex" }}>
                {loading && values["category"] ? (
                    <Loader />
                ) : (
                    <FormikProvider value={formik}>
                        <Form
                            autoComplete="off"
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <Stack spacing={2}>
                                <Autocomplete
                                    limitTags={2}
                                    options={categories}
                                    // groupBy={(user) => user.firstLetter}
                                    getOptionLabel={(category) =>
                                        category.label
                                    }
                                    defaultValue={categories[0]}
                                    // value={values["category"]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Betroffene Kategorie"
                                            placeholder="Betroffene Kategorie"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        setFieldValue("category", value);
                                    }}
                                    // value={values.category}
                                />
                                <TextField
                                    fullWidth
                                    label="Betreff"
                                    {...getFieldProps("subject")}
                                    error={Boolean(
                                        touched.subject && errors.subject
                                    )}
                                    helperText={
                                        touched.subject && errors.subject
                                    }
                                />

                                <RichTextEditor
                                    value={formik.values.body}
                                    setValue={(val) =>
                                        formik.setFieldValue("body", val)
                                    }
                                    error={Boolean(touched.body && errors.body)}
                                />

                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Absenden
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                )}
            </Box>
        </Page>
    );
};

export default Contact;
