// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";

// redux store
import Store from "./redux/Store";
import { Provider } from "react-redux";

import axios from "axios";

if (window.location.origin.includes("localhost")) {
    axios.defaults.baseURL = "http://127.0.0.1:8000";
} else {
    axios.defaults.baseURL = window.location.origin;
}

// ----------------------------------------------------------------------

export default function App() {
    return (
        <ThemeConfig>
            <ScrollToTop />
            <GlobalStyles />
            <BaseOptionChartStyle />
            <Provider store={Store}>
                <Router />
            </Provider>
        </ThemeConfig>
    );
}
