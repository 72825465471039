import React, { useState, useEffect, Fragment } from "react";
import RichTextEditor from "../Content/RichTextEditor";
import { Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";

// Formik
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import TabbedPage from "../../../TabbedPage";

import { useDispatch } from "react-redux";
import { get, put } from "src/services/content.service";
import Page from "src/components/Page";

import { showAlert } from "../../../../redux/slices/AlertSlice";
const schema = Yup.object().shape({
    subject: Yup.string().min(3).max(200).required("Subject is required"),
    body: Yup.string().min(10).max(2000).required("Body field is required"),
});

const EmailTemplateForm = ({ template, onUpdate }) => {
    const { id, label, subject, body } = template;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: { subject: subject, body: body },
        validationSchema: schema,
        onSubmit: async (values, actions) => {
            const body = JSON.stringify({
                id: id,
                subject: values.subject,
                body: values.body,
            });

            try {
                await put(dispatch, `/api/admin/email/templates`, body);
                dispatch(
                    showAlert({
                        message: `template ${label} updated`,
                        severity: "success",
                    })
                );
            } catch (err) {
                dispatch(
                    showAlert({
                        message: err,
                        severity: "error",
                    })
                );
            } finally {
                actions.setSubmitting(false);
            }
        },
    });

    const { errors, touched, handleSubmit, isSubmitting } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                    <TextField
                        fullWidth
                        label="Subject"
                        value={subject}
                        onChange={(e) => {
                            formik.setTouched("subject", true, false);
                            formik.setFieldValue("subject", e.target.value);
                            onUpdate("subject", e.target.value);
                        }}
                        error={Boolean(touched.subject && errors.subject)}
                        helperText={touched.subject && errors.subject}
                    />
                    <RichTextEditor
                        value={body}
                        setValue={(val) => {
                            formik.setTouched("body", true, false);
                            formik.setFieldValue("body", val);
                            onUpdate("body", val);
                        }}
                        error={Boolean(touched.body && errors.body)}
                    />

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Submit
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
};

const EmailTemplates = () => {
    const [emails, setEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const handleUpdateBody = () => {};

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchEmails = async () => {
            try {
                setLoading(true);
                const res = await get(dispatch, `/api/admin/email/templates`);
                setEmails(res.data);
            } catch (err) {
                dispatch(
                    showAlert({
                        message: err,
                        severity: "error",
                    })
                );
            } finally {
                setLoading(false);
            }
        };
        fetchEmails();
    }, []);

    return (
        <Page title={`E-Mail templates | TZMO DE Extranet`}>
            <Box sx={{ display: "flex" }}>
                <TabbedPage orientation="vertical">
                    {emails &&
                        emails.map((email, index) => (
                            <EmailTemplateForm
                                key={email.id}
                                label={email.label}
                                template={email}
                                onUpdate={(field, val) => {
                                    let newEmails = emails;
                                    if (field === "body") {
                                        newEmails[index].body = val;
                                    } else if (field === "subject") {
                                        newEmails[index].subject = val;
                                    }
                                    setEmails([...newEmails]);
                                }}
                            />
                        ))}
                </TabbedPage>
            </Box>
        </Page>
    );
};

export default EmailTemplates;
