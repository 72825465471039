import React, { useState, useEffect, Fragment } from "react";
import {
    Box,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    styled,
} from "@mui/material";

import EditIconOutlined from "@mui/icons-material/EditOutlined";
import ArticleIconOutlined from "@mui/icons-material/ArticleOutlined";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";

import ContentDialog from "./ContentDialog";
import { useSelector } from "react-redux";
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    zIndex: 999,
    right: 0,
    display: "flex",
    cursor: "pointer",
    position: "fixed",
    alignItems: "center",
    bottom: theme.spacing(25),
    height: theme.spacing(5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    // paddingBottom: theme.spacing(1.25),
}));

// ----------------------------------------------------------------------

const AdminSpeedDial = () => {
    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);
    const [dialogOperationType, setDialogOperationType] = useState("add");
    const { user } = useSelector((state) => state.auth);
    const { contentType, slug } = useSelector((state) => state.content);
    const [type, setType] = useState(null);

    const genericActions = [
        {
            icon: <ArticleIconOutlined />,
            name: "Add content",
            onClick: () => {
                setDialogOperationType("add");
                setType("content");
            },
        },
        {
            icon: <AltRouteIcon />,
            name: "Edit menu",
            onClick: () => {
                setDialogOperationType("add");
                setType("navigation");
            },
        },
    ];

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        return () => {
            setShowModal(null);
        };
    }, []);

    const getActions = () => {
        if (slug && contentType) {
            return [
                {
                    icon: <EditIconOutlined />,
                    name: "Edit " + contentType,
                    onClick: () => {
                        setDialogOperationType("edit");
                        setType(contentType);
                    },
                },
            ].concat(genericActions);
        }

        return genericActions;
    };

    return (
        <Fragment>
            {user.is_staff === true && (
                <RootStyle>
                    <Box
                        sx={{
                            height: 320,
                            transform: "translateZ(0px)",
                            flexGrow: 1,
                        }}
                    >
                        <SpeedDial
                            ariaLabel="SpeedDial openIcon example"
                            icon={
                                <SpeedDialIcon
                                    openIcon={<EditIconOutlined />}
                                />
                            }
                            onClose={handleClose}
                            onOpen={handleOpen}
                            open={open}
                        >
                            {getActions().map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleClose();
                                        if (action.onClick) {
                                            action.onClick();
                                        }
                                        setShowModal(true);
                                    }}
                                />
                            ))}
                        </SpeedDial>
                        <ContentDialog
                            open={showModal && type !== null}
                            onClose={() => {
                                setShowModal(false);
                            }}
                            type={dialogOperationType}
                            contentType={type}
                            slug={slug}
                            submitStatus={setSubmitStatus}
                        />
                    </Box>
                </RootStyle>
            )}
        </Fragment>
    );
};

export default AdminSpeedDial;
