import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import TabbedPage from "src/components/TabbedPage";
import ContentDetail from "src/pages/ContentDetail";
import ContentList from "src/pages/ContentList";
import { useDispatch } from "react-redux";
import { post } from "src/services/content.service";

const TabLayoutArticles = ({ category, tab }) => {
    const [articles, setArticles] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchArticles = async () => {
            const body = JSON.stringify({
                category: category,
                sort_by: "popular",
            });
            try {
                const res = await post(dispatch, `/api/content/content/`, body);
                setArticles(res.data);
            } catch (err) {
                console.log(err);
            }
        };

        fetchArticles();
    }, [category, dispatch]);

    return (
        <TabbedPage tab={tab}>
            {articles.map((article, index) => {
                return article.content.type === "content_list" ? (
                    <ContentList
                        key={article.content.slug}
                        label={article.content.title}
                        tooltip={article.content.tooltip}
                        altSlug={article.content.slug}
                        endpoint="/api/content/list/"
                        tab={index}
                    />
                ) : (
                    <ContentDetail
                        key={article.content.slug}
                        label={article.content.title}
                        tooltip={article.content.tooltip}
                        altSlug={article.content.slug}
                        endpoint="/api/content/list/"
                    />
                );
            })}
        </TabbedPage>
    );
};

TabLayoutArticles.propTypes = {
    category: PropTypes.string.isRequired,
};

export default TabLayoutArticles;
