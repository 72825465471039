import { createSlice } from "@reduxjs/toolkit";

const parseErrorMessage = (error) => {
    return (
        (error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString()
    );
};

export const AlertSlice = createSlice({
    name: "alert",
    initialState: {
        open: false,
        severity: undefined,
        message: undefined,
    },
    reducers: {
        hideAlert: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.open = false;
            state.severity = undefined;
            state.message = undefined;
        },
        showAlert: (state, action) => {
            state.severity = action.payload.severity;
            state.message = parseErrorMessage(action.payload.message);
            state.open = true;
        },
    },
});

export const { hideAlert, showAlert } = AlertSlice.actions;

export const autoHide = (duration) => (dispatch) => {
    setTimeout(() => {
        dispatch(hideAlert());
    }, duration);
};

export const selectOpen = (state) => state.alert.open;
export const selectMessage = (state) => state.alert.message;
export const selectSeverity = (state) => state.alert.severity;

export default AlertSlice.reducer;
