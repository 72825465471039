import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { changePassword } from "src/redux/slices/Auth";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
    Stack,
    TextField,
    IconButton,
    InputAdornment,
    Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Loader from "src/components/loader/Loader";
import ContentCard from "./ContentCard";

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const LoginSchema = Yup.object().shape({
        current_password: Yup.string().required(
            "Aktuelles Passwort ist erforderlich"
        ),
        new_password: Yup.string().required("Neues Passwort ist erforderlich"),
        re_new_password: Yup.string()
            .required("Passwort-Bestätigung ist erforderlich")
            .oneOf(
                [Yup.ref("new_password"), null],
                "Passwörter müssen übereinstimmen"
            ),
    });

    const formik = useFormik({
        initialValues: {
            current_password: "",
            new_password: "",
            re_new_password: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            setLoading(true);
            const { current_password, new_password, re_new_password } = values;
            dispatch(
                changePassword({
                    current_password,
                    new_password,
                    re_new_password,
                })
            )
                .unwrap()
                .then(() => {
                    setLoading(false);
                    actions.setSubmitting(false);
                    actions.resetForm();
                })
                .catch(() => {
                    setLoading(false);
                    actions.setSubmitting(false);
                });
        },
    });

    const {
        errors,
        touched,
        values,
        isSubmitting,
        handleSubmit,
        getFieldProps,
    } = formik;

    return (
        <ContentCard>
            <Typography variant="h4" gutterBottom>
                Aktuelles Passwort ändern
            </Typography>
            <Typography sx={{ color: "text.secondary" }} mb={1}>
                Bitte trage dein neues Passwort ein
            </Typography>

            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <Stack
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            mb={2}
                        >
                            <TextField
                                fullWidth
                                autoComplete="current_password"
                                type={showPassword ? "text" : "password"}
                                label="Aktuelles Passwort"
                                {...getFieldProps("current_password")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() =>
                                                    setShowPassword(
                                                        (prev) => !prev
                                                    )
                                                }
                                            >
                                                <Icon
                                                    icon={
                                                        showPassword
                                                            ? eyeFill
                                                            : eyeOffFill
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={Boolean(
                                    touched.current_password &&
                                        errors.current_password
                                )}
                                helperText={
                                    touched.current_password &&
                                    errors.current_password
                                }
                            />

                            <TextField
                                fullWidth
                                autoComplete="new_password"
                                type={showPassword ? "text" : "password"}
                                label="Neues Passwort"
                                {...getFieldProps("new_password")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() =>
                                                    setShowPassword(
                                                        (prev) => !prev
                                                    )
                                                }
                                            >
                                                <Icon
                                                    icon={
                                                        showPassword
                                                            ? eyeFill
                                                            : eyeOffFill
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={Boolean(
                                    touched.new_password && errors.new_password
                                )}
                                helperText={
                                    touched.new_password && errors.new_password
                                }
                            />
                            <TextField
                                fullWidth
                                autoComplete="re_new_password"
                                type={showPassword ? "text" : "password"}
                                label="Wiederhole dein Passwort"
                                {...getFieldProps("re_new_password")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                edge="end"
                                                onClick={() =>
                                                    setShowPassword(
                                                        (prev) => !prev
                                                    )
                                                }
                                            >
                                                <Icon
                                                    icon={
                                                        showPassword
                                                            ? eyeFill
                                                            : eyeOffFill
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                error={Boolean(
                                    touched.re_new_password &&
                                        errors.re_new_password
                                )}
                                helperText={
                                    touched.re_new_password &&
                                    errors.re_new_password
                                }
                            />
                        </Stack>
                    )}

                    <LoadingButton
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Passwort ändern
                    </LoadingButton>
                </Form>
            </FormikProvider>
        </ContentCard>
    );
}
