import React, { useState, useEffect } from "react";
import {
    useParams,
    useSearchParams,
    useNavigate,
    useLocation,
} from "react-router-dom";
// material
import { Container, Stack, Typography, Pagination } from "@mui/material";
import { Box } from "@mui/system";
// components
import Page from "../components/Page";
import { ProductList } from "../components/dashboard/products";

import ContentSortMenu from "../components/ContentSortMenu";
import Loader from "src/components/loader/Loader";

import { useDispatch } from "react-redux";
import { post } from "src/services/content.service";

import Page404 from "./Page404";

const CONTENT_PER_PAGE = [
    { value: "12", label: "12" },
    { value: "24", label: "24" },
    { value: "36", label: "36" },
];
// ----------------------------------------------------------------------

export default function ContentList({ endpoint, altSlug, label }) {
    const params = useParams();
    const slug = params.slug ? params.slug : altSlug;

    const [content, setContent] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(-1);
    const [pageCount, setPageCount] = useState(CONTENT_PER_PAGE[1].value);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(label);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");

    const handleChange = (event, value) => {
        setPage(value);
        updateHistory(value);
    };

    const handlePageCountChanged = (newVal) => {
        setPageCount(newVal);
    };

    const updateHistory = (pageVal) => {
        const tabQuery = tab ? `tab=${tab}&` : "";
        window.history.pushState(
            "",
            "",
            `${location.pathname}?${tabQuery}page=${pageVal}`
        );
    };

    // useEffect(() => {
    //     console.log("eldo");
    //     const postData = async () => {
    //         const body = JSON.stringify({
    //             page_count: pageCount,
    //             sort_by: "latest",
    //         });
    //         try {
    //             const res = await post(
    //                 dispatch,
    //                 `${endpoint}${slug}?page=1`,
    //                 body
    //             );
    //             if (res.data.results.length > 0) {
    //                 setContent(res.data.results);
    //                 setCount(res.data.count);
    //                 if (
    //                     label == null &&
    //                     res.data.results[0].parent &&
    //                     res.data.results[0].parent.title
    //                 ) {
    //                     setTitle(res.data.results[0].parent.title);
    //                 }
    //             }
    //             // updateHistory(1);
    //         } catch (err) {}
    //     };
    //     postData();
    // }, [pageCount, dispatch, slug]);

    const visitPage = async () => {
        window.scrollTo(0, 0);

        const body = JSON.stringify({
            page_count: pageCount,
            sort_by: "latest",
        });

        try {
            setLoading(true);
            const res = await post(
                dispatch,
                `${endpoint}${slug}?page=${page}`,
                body
            );
            setContent(res.data.results);
            setCount(res.data.count);
            if (
                label == null &&
                res.data.results[0].parent &&
                res.data.results[0].parent.title
            ) {
                setTitle(res.data.results[0].parent.title);
            }
        } catch (err) {
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const setInitialPage = () => {
            const pageParam = searchParams.get("page");
            if (
                pageParam !== null &&
                pageParam !== undefined &&
                !isNaN(pageParam) &&
                Number(pageParam) !== page
            ) {
                setPage(Number(pageParam));
            } else {
                setPage(1);
            }
        };

        setInitialPage();
    }, []);

    useEffect(() => {
        if (page !== undefined && page !== null && page >= 0) {
            visitPage();
        }
    }, [page]);

    const renderContents = () => {
        if (loading) {
            return <Loader />;
        }
        if (content && content.length > 0) {
            return (
                <>
                    {content[0].parent && content[0].parent.body && (
                        <div
                            className="mt-5 mb-5"
                            dangerouslySetInnerHTML={{
                                __html: content[0].parent.body,
                            }}
                        />
                    )}
                    <Stack
                        direction="row"
                        flexWrap="wrap-reverse"
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{ mb: 5 }}
                    >
                        <Stack
                            direction="row"
                            spacing={1}
                            flexShrink={0}
                            sx={{ my: 1 }}
                        >
                            <ContentSortMenu
                                title="Inhalte pro Seite"
                                initialValue={CONTENT_PER_PAGE[1]}
                                options={CONTENT_PER_PAGE}
                                onSort={handlePageCountChanged}
                            />
                        </Stack>
                    </Stack>
                    <ProductList products={content} />{" "}
                    <Box
                        sx={{
                            mt: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Pagination
                            count={Math.ceil(count / pageCount)}
                            page={page}
                            onChange={handleChange}
                        />
                    </Box>{" "}
                </>
            );
        } else {
            return <Page404 />;
        }
    };

    return (
        <Page title={`Dashboard: ${title} | TZMO DE Extranet`}>
            <Container maxWidth={false}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    {title}
                </Typography>
                {renderContents()}
            </Container>
        </Page>
    );
}
