import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// material
import { Box, Card, CardActionArea, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";

// ----------------------------------------------------------------------

const ProductImgStyle = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
    product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
    const { slug, title } = product.content;
    const cover = product.thumbnail ? `${product.thumbnail.filePath}` : null;

    return (
        <Card>
            <CardActionArea component={Link} to={`/dashboard/product/${slug}`}>
                <Box sx={{ pt: "100%", position: "relative" }}>
                    <ProductImgStyle alt={title} src={cover} />
                </Box>

                <Stack spacing={2} sx={{ p: 3 }}>
                    <Typography variant="subtitle2" noWrap>
                        {title}
                    </Typography>
                </Stack>
            </CardActionArea>
        </Card>
    );
}
