import React, { useState } from "react";
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Stack,
} from "@mui/material";

const NAV_TYPES = [
    { value: "content_list", label: "Content list" },
    { value: "tab", label: "Tab" },
    { value: "nested_list", label: "Nested list" },
];

const AddNodeDialog = ({
    title,
    contentText,
    textLabel,
    onSubmit,
    open,
    onClose,
    validationList,
    errorText,
    parentNodeTitle,
}) => {
    const [nodeName, setNodeName] = useState("");
    const [errorState, setErrorState] = useState(1);
    const [position, setPosition] = useState(0);
    const [nodeType, setNodeType] = useState(NAV_TYPES[0].value);

    const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue === "") {
            setErrorState(1);
        } else if (
            validationList.some(
                (x) => x.toLowerCase() === newValue.toLowerCase()
            )
        ) {
            setErrorState(2);
        } else {
            setErrorState(0);
        }
        setNodeName(newValue);
    };

    const getHelperText = () => {
        switch (errorState) {
            case 1:
                return errorText.empty;
            case 2:
                return errorText.alreadyExists;
            default:
                return "";
        }
    };

    const handleSubmit = () => {
        onSubmit({ nodeName, nodeType, position });
        setNodeName("");
        setPosition(0);
        setNodeType(NAV_TYPES[0].value);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{contentText}</DialogContentText>
                <Stack spacing={2}>
                    <TextField
                        error={errorState === 2 ? true : false}
                        autoFocus
                        margin="dense"
                        id="name"
                        value={nodeName}
                        onChange={handleChange}
                        label={textLabel}
                        type="text"
                        fullWidth
                        variant="standard"
                        helperText={getHelperText()}
                    />
                    <Stack spacing={2} direction="row">
                        <FormControl fullWidth>
                            <InputLabel>Insert location</InputLabel>
                            <Select
                                value={position}
                                label="Position"
                                onChange={(event) =>
                                    setPosition(event.target.value)
                                }
                            >
                                <MenuItem
                                    value={0}
                                >{`under ${parentNodeTitle}`}</MenuItem>
                                <MenuItem
                                    value={1}
                                >{`inside ${parentNodeTitle}`}</MenuItem>
                                {/* <MenuItem
                                    value={1}
                                >{`above ${parentNodeTitle}`}</MenuItem> */}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Node type</InputLabel>
                            <Select
                                value={nodeType}
                                label="Node type"
                                onChange={(event) =>
                                    setNodeType(event.target.value)
                                }
                            >
                                {NAV_TYPES.map((item) => (
                                    <MenuItem value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={errorState > 0 ? true : false}
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default AddNodeDialog;
