import React, { useRef } from "react";
import { Box } from "@mui/system";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const RichTextEditor = ({ value, setValue, error }) => {
    const handleChange = (content) => {
        setValue(content);
    };
    const editor = useRef();

    const getSunEditorInstance = (sunEditor) => {
        editor.current = sunEditor;
    };

    return (
        <Box sx={{ mb: 2 }}>
            <SunEditor
                height="200px"
                width="100%"
                // setAllPlugins={true}
                setOptions={{
                    buttonList: buttonList.complex, // Or Array of button list, eg. [['font', 'align'], ['image']]
                    // plugins: [font] set plugins, all plugins are set by default
                    // Other option
                }}
                onChange={handleChange}
                setContents={value}
                setDefaultStyle="font-family: Calibri, sans-seri; font-size: 14px;"
                getSunEditorInstance={getSunEditorInstance}
            />
        </Box>
    );
};

export default RichTextEditor;
