import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import {
    NavLink as RouterLink,
    matchPath,
    useLocation,
} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
// import materialIcons from "material-design-icons/iconfont/material-icons.css";
import "material-design-icons/iconfont/material-icons.css";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
    Box,
    List,
    Collapse,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    Tooltip,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { get } from "src/services/content.service";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
    <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: "relative",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    "&:before": {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: "none",
        position: "absolute",
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: theme.palette.primary.main,
    },
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
    active: PropTypes.func,
};

function NavItem({ item, active }) {
    const theme = useTheme();
    const isActiveRoot = active(item.path);
    const { title, path, icon, info, children, tooltip } = item;
    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = () => {
        setOpen((prev) => !prev);
    };

    const activeRootStyle = {
        color: "primary.main",
        fontWeight: "fontWeightMedium",
        bgcolor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
        ),
        "&:before": { display: "block" },
    };

    const activeSubStyle = {
        color: "text.primary",
        fontWeight: "fontWeightMedium",
    };

    if (children) {
        return (
            <>
                <ListItemStyle
                    onClick={handleOpen}
                    sx={{
                        ...(isActiveRoot && activeRootStyle),
                    }}
                >
                    <ListItemIconStyle>
                        <span className="material-icons">{icon}</span>
                    </ListItemIconStyle>
                    <Tooltip title={tooltip ? tooltip : ""} placement="right">
                        <ListItemText disableTypography primary={title} />
                    </Tooltip>
                    {info && info}
                    <Box
                        component={Icon}
                        icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {children.map((item) => {
                            const { title, path } = item;
                            const isActiveSub = active(path);

                            return (
                                <ListItemStyle
                                    key={title}
                                    component={RouterLink}
                                    to={path}
                                    sx={{
                                        ...(isActiveSub && activeSubStyle),
                                    }}
                                >
                                    <ListItemIconStyle>
                                        <Box
                                            component="span"
                                            sx={{
                                                width: 4,
                                                height: 4,
                                                display: "flex",
                                                borderRadius: "50%",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                bgcolor: "text.disabled",
                                                transition: (theme) =>
                                                    theme.transitions.create(
                                                        "transform"
                                                    ),
                                                ...(isActiveSub && {
                                                    transform: "scale(2)",
                                                    bgcolor: "primary.main",
                                                }),
                                            }}
                                        />
                                    </ListItemIconStyle>
                                    <ListItemText
                                        disableTypography
                                        primary={title}
                                    />
                                </ListItemStyle>
                            );
                        })}
                    </List>
                </Collapse>
            </>
        );
    }

    return (
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle>
                <span className="material-icons">{icon}</span>
            </ListItemIconStyle>
            <Tooltip title={tooltip ? tooltip : ""} placement="right">
                <ListItemText disableTypography primary={title} />
            </Tooltip>
            {info && info}
        </ListItemStyle>
    );
}

NavSection.propTypes = {
    navConfig: PropTypes.array,
};

// const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
// const getIcon = (icon) => <Icon>{icon}</Icon>;
export default function NavSection({ navConfig, adminConfig, ...other }) {
    const { pathname } = useLocation();
    const match = (path) =>
        path ? !!matchPath({ path, end: false }, pathname) : false;

    const [customNavItems, setCustomNavItems] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchCustomNavItems = async () => {
            try {
                const res = await get(dispatch, `/api/content/nav/items`);
                let tempNavArray = [];
                for (const navItem of res.data) {
                    const navType = navItem.content.type;
                    let path = null;
                    let children = null;
                    if (navType === "nested_list") {
                        children = navItem.children.map((child) => {
                            const queryParam =
                                child.content.type === "tab" ? "tab" : "page";
                            return {
                                title: child.content.title,
                                path:
                                    child.content.type === "content"
                                        ? `/dashboard/content/${child.content.slug}/`
                                        : `/dashboard/contents/${child.content.slug}?${queryParam}=1`,
                            };
                        });
                    } else {
                        const queryParam =
                            navItem.content.type === "tab" ? "tab" : "page";
                        path = `/dashboard/contents/${navItem.content.slug}?${queryParam}=1`;
                    }
                    tempNavArray.push({
                        title: navItem.content.title,
                        path: path,
                        icon: navItem.icon,
                        children: children,
                        tooltip: navItem.content.tooltip,
                    });
                }
                setCustomNavItems(tempNavArray);
            } catch (err) {
                console.log(err);
            }
        };
        fetchCustomNavItems();
        return () => {
            setCustomNavItems([]);
        };
    }, [dispatch]);

    return (
        <Box {...other}>
            <List disablePadding>
                {adminConfig.map((item) => (
                    <NavItem key={item.title} item={item} active={match} />
                ))}
                {navConfig.map((item) => (
                    <NavItem key={item.title} item={item} active={match} />
                ))}
                {customNavItems.map((item) => (
                    <NavItem key={item.title} item={item} active={match} />
                ))}
            </List>
        </Box>
    );
}
