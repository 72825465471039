import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const InputTextDialog = ({
    title,
    contentText,
    initialValue,
    textLabel,
    onSubmit,
    open,
    onClose,
    validationList,
    errorText,
}) => {
    const [value, setValue] = useState("");
    const [errorState, setErrorState] = useState(0);
    const handleChange = (event) => {
        const newValue = event.target.value;
        if (newValue === "") {
            setErrorState(1);
        } else if (
            validationList &&
            validationList.some(
                (x) => x.toLowerCase() === newValue.toLowerCase()
            )
        ) {
            setErrorState(2);
        } else {
            setErrorState(0);
        }
        setValue(newValue);
    };

    useEffect(() => {
        setValue(initialValue);
        //   return () => {
        //     second
        //   }
    }, [initialValue]);

    const getHelperText = () => {
        if (errorText === undefined || errorText === null) {
            return "";
        }
        switch (errorState) {
            case 1:
                return errorText.empty;
            case 2:
                return errorText.alreadyExists;
            default:
                return "";
        }
    };

    const handleSubmit = (value) => {
        onSubmit(value);
        setValue("");
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{contentText}</DialogContentText>
                <TextField
                    error={errorState === 2 ? true : false}
                    autoFocus
                    margin="dense"
                    id="name"
                    value={value}
                    onChange={handleChange}
                    label={textLabel}
                    type="text"
                    fullWidth
                    variant="standard"
                    helperText={getHelperText()}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    disabled={errorState > 0 ? true : false}
                    onClick={() => {
                        handleSubmit(value);
                    }}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default InputTextDialog;
