import { Icon } from "@iconify/react";
import androidFilled from "@iconify/icons-ant-design/android-filled";
// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------

// const customStyles = {
//     {"green": {
//         rootColor: `theme.palette.primary.darker`,
//         rootBgColor: ` theme.palette.info.lighter`,
//         iconColor: `theme.palette.primary.dark`,
//     }},
//     {"blue": {
//         rootColor: `theme.palette.info.darker`,
//         rootBgColor: `theme.palette.primary.lighter`,
//         iconColor: `theme.palette.info.dark`,
//     }},
//     {"yellow": {
//         rootColor: `theme.palette.warning.darker`,
//         rootBgColor: `theme.palette.warning.lighter`,
//         iconColor: `theme.palette.warning.dark`,
//     }},
//     {"red": {
//         rootColor: `theme.palette.error.darker`,
//         rootBgColor: `theme.palette.error.lighter`,
//         iconColor: `theme.palette.error.dark`,
//     }},
// };

// ----------------------------------------------------------------------

export default function StatsNumberCard({
    title,
    value,
    icon,
    color,
    opacity,
}) {
    const getRootColor = (theme, color) => {
        switch (color) {
            case "green":
                return theme.palette.primary.darker;
            case "blue":
                return theme.palette.info.darker;
            case "yellow":
                return theme.palette.warning.darker;
            case "red":
                return theme.palette.error.darker;
            default:
                return theme.palette.primary.darker;
        }
    };

    const getRootBgColor = (theme, color) => {
        switch (color) {
            case "green":
                return theme.palette.primary.lighter;
            case "blue":
                return theme.palette.info.lighter;
            case "yellow":
                return theme.palette.warning.lighter;
            case "red":
                return theme.palette.error.lighter;
            default:
                return theme.palette.info.lighter;
        }
    };

    const getIconColor = (theme, color) => {
        switch (color) {
            case "green":
                return theme.palette.primary.dark;
            case "blue":
                return theme.palette.info.dark;
            case "yellow":
                return theme.palette.warning.dark;
            case "red":
                return theme.palette.error.dark;
            default:
                return theme.palette.primary.dark;
        }
    };

    const RootStyle = styled(Card)(({ theme, color }) => ({
        boxShadow: "none",
        textAlign: "center",
        padding: theme.spacing(5, 0),
        color: getRootColor(theme, color),
        backgroundColor: getRootBgColor(theme, color),
    }));

    const IconWrapperStyle = styled("div")(({ theme, color }) => ({
        margin: "auto",
        display: "flex",
        borderRadius: "50%",
        alignItems: "center",
        width: theme.spacing(8),
        height: theme.spacing(8),
        justifyContent: "center",
        marginBottom: theme.spacing(3),
        color: getIconColor(theme, color),
        backgroundImage: `linear-gradient(135deg, ${alpha(
            getIconColor(theme, color),
            0
        )} 0%, ${alpha(getIconColor(theme, color), 0.24)} 100%)`,
    }));

    return (
        <RootStyle color={color}>
            <IconWrapperStyle color={color}>
                <Icon icon={androidFilled} width={24} height={24} />
            </IconWrapperStyle>
            <Typography variant="h3">{fShortenNumber(value)}</Typography>
            <Typography
                variant="subtitle2"
                sx={{ opacity: opacity ? opacity : 0.72 }}
            >
                {title}
            </Typography>
        </RootStyle>
    );
}
