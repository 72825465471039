import { useState, useEffect } from "react";
import {
    SortableTreeWithoutDndContext as SortableTree,
    insertNode,
    find,
    map,
    walk,
    changeNodeAtPath,
    removeNodeAtPath,
} from "@nosferatu500/react-sortable-tree";
import "@nosferatu500/react-sortable-tree/style.css"; // This only needs to be imported once in your app
import { TextField, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import AddNodeDialog from "./AddNodeDialog";

import ExternalNode, { externalNodeType } from "./ExternalNode";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TabbedPage from "../../../../TabbedPage";
import MoreMenu from "../../utlis/MoreMenu";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { AdminTable } from "../../utlis";

const TABLE_HEAD = [
    { id: "Id", label: "Id", alignRight: false, search: false },
    { id: "Title", label: "Title", alignRight: false, search: true },
    { id: "Type", label: "Type", alignRight: false, search: true },
    { id: "More", alignRight: true },
];

const droppableTypes = ["content_list", "nested_list", "tab", "list"];

const NavigationStep = ({
    currentNav,
    navAll,
    setNavAll,
    removeNode,
    newNav,
    navElements,
}) => {
    const [currentRowInfo, setCurrentRowInfo] = useState(null);
    const [showAddNodeDialog, setShowAddNodeDialog] = useState(false);
    const [maxId, setMaxId] = useState(0);
    const [rows, setRows] = useState([]);
    const [selected, setSelected] = useState([]);

    const getNodeKey = ({ node }) => node.id;
    useEffect(() => {
        if (navAll) {
            let tmpSelected = [];
            let currMaxId = maxId;
            walk({
                treeData: navAll,
                getNodeKey: getNodeKey,
                callback: ({ node }) => {
                    if (node.id > currMaxId) {
                        currMaxId = node.id;
                    }
                    if (currentNav) {
                        if (node.contentId === currentNav.id && node.parent) {
                            tmpSelected.push(
                                node.parent.id ? node.parent.id : node.parent
                            );
                        }
                    }
                },
                ignoreCollapsed: false,
            });
            setMaxId(currMaxId);
            setSelected(tmpSelected);
        }
    }, [navAll]);

    useEffect(() => {
        if (
            navElements &&
            Array.isArray(navElements) &&
            navElements.length > 0
        ) {
            setRows(
                navElements.map((row, index) => ({
                    Id: row.id,
                    Title: row.title,
                    Type: row.type,
                    More: (
                        <MoreMenu
                            options={[
                                {
                                    label: "Edit",
                                    icon: <EditOutlinedIcon />,
                                    handler: () => {
                                        // editIssue(issues[index]);
                                    },
                                },
                                {
                                    label: "Delete",
                                    icon: <DeleteOutlineOutlinedIcon />,
                                    handler: () => {
                                        // deleteContent([
                                        //     issues[index].id,
                                        // ]);
                                    },
                                },
                            ]}
                        />
                    ),
                }))
            );
        }
    }, [navElements]);

    const { newNode, setNewNode } = newNav;
    const { removedNodes, setRemovedNodes } = removeNode;

    const handleOnMoveNode = (treeData) => {
        treeData.node.parent = treeData.nextParentNode?.id || null;
        const updatedPosition = treeData.nextParentNode.children.findIndex(
            (val) => val.contentId === treeData.node.contentId
        );
        if (updatedPosition !== -1) {
            setNavAll(
                changeNodeAtPath({
                    treeData: navAll,
                    path: treeData.path,
                    getNodeKey: getNodeKey,
                    newNode: {
                        ...treeData.node,
                        position: updatedPosition,
                    },
                })
            );
        }
    };

    const handleDelete = ({ node, path }) => {
        setNavAll(
            removeNodeAtPath({
                treeData: navAll,
                path: path,
                getNodeKey: getNodeKey,
            })
        );
        removeNode(node);
    };

    const handleAddNode = ({ nodeName, nodeType, position }) => {
        const depth = currentRowInfo.path.length - 1 + position;
        let treeIndex = -1;
        if (
            !currentRowInfo.node.children ||
            currentRowInfo.node.children.length < 1
        ) {
            treeIndex = currentRowInfo.treeIndex;
        } else {
            if (position === 0) {
                treeIndex =
                    currentRowInfo.treeIndex +
                    currentRowInfo.node.children.length;
            } else {
                treeIndex = currentRowInfo.treeIndex + 1;
            }
        }
        setNavAll(
            insertNode({
                treeData: navAll,
                depth: depth,
                minimumTreeIndex: treeIndex,
                newNode: {
                    id: maxId + 1,
                    title: nodeName,
                    type: nodeType,
                    parent:
                        position === 1
                            ? currentRowInfo.node
                            : currentRowInfo.node.parent,
                    children: [],
                },
                getNodeKey: getNodeKey,
                expandParent: true,
                // ignoreCollapsed: false,
            }).treeData
        );

        // reset current row info
        setCurrentRowInfo(null);
    };

    const handleSelectionChanged = (oldSelection, newSelection) => {
        const added = newSelection.filter((x) => !oldSelection.includes(x));
        const removed = oldSelection.filter((x) => !newSelection.includes(x));

        let newRemovedNodes = [...removedNodes];
        for (let i = 0; i < newRemovedNodes.length; ++i) {
            if (added.includes(newRemovedNodes[i])) {
                newRemovedNodes.splice(i, 1);
            }
        }
        newRemovedNodes = newRemovedNodes.concat(removed);
        setRemovedNodes(newRemovedNodes);

        let newMaxId = maxId;
        if (currentNav) {
            const toAdd = find({
                getNodeKey: getNodeKey,
                treeData: navAll,
                searchMethod: (searchData) => {
                    const { node } = searchData;
                    if (added && added.length > 0) {
                        const addedIdx = added.findIndex(
                            (val) => val === node.id
                        );
                        if (addedIdx !== -1) {
                            added.splice(addedIdx, 1);
                            return true;
                        }
                        return false;
                    }
                },
                expandAllMatchPaths: false,
                expandFocusMatchPaths: false,
            });

            let newTree = [...navAll];
            for (const match of toAdd.matches) {
                newTree = changeNodeAtPath({
                    treeData: newTree,
                    path: match.path,
                    getNodeKey: getNodeKey,
                    newNode: {
                        ...match.node,
                        children: [
                            ...match.node.children,
                            {
                                id: ++newMaxId,
                                contentId: currentNav.id,
                                title: currentNav.title,
                                type: currentNav.type,
                                parent: match.node,
                                children: [],
                            },
                        ],
                    },
                    ignoreCollapsed: false,
                });
            }

            const toRemove = find({
                getNodeKey: getNodeKey,
                treeData: navAll,
                searchMethod: (searchData) => {
                    const { node } = searchData;
                    if (removed && removed.length > 0) {
                        const removedIdx = removed.findIndex(
                            (val) => val === node.id
                        );
                        if (removedIdx !== -1) {
                            removed.splice(removed, 1);
                            return true;
                        }
                        return false;
                    }
                },
                expandAllMatchPaths: false,
                expandFocusMatchPaths: false,
            });

            for (const match of toRemove.matches) {
                const removeIdx = match.node.children.findIndex(
                    (val) => val.contentId === currentNav.id
                );
                if (removeIdx !== -1) {
                    let newChildren = [...match.node.children];
                    newChildren.splice(removeIdx, 1);
                    newTree = changeNodeAtPath({
                        treeData: newTree,
                        path: match.path,
                        getNodeKey: getNodeKey,
                        newNode: {
                            ...match.node,
                            children: [...newChildren],
                        },
                        ignoreCollapsed: false,
                    });
                }
            }

            setNavAll(newTree);
        }
    };

    return (
        <TabbedPage orientation="horizontanl" dialog={true}>
            <AdminTable
                label="Flat view"
                searchPlaceholder="Suche..."
                headers={TABLE_HEAD}
                rows={rows}
                selection={{
                    selected: selected,
                    setSelected: setSelected,
                }}
                onSelectionChanged={handleSelectionChanged}
                showChildrenWhenSelected={true}
                contextMenuOptions={[
                    {
                        label: "Edit",
                        icon: <EditOutlinedIcon />,
                        handler: (id) => {
                            // editIssue(issues[id]);
                        },
                    },
                    {
                        label: "Delete",
                        icon: <DeleteOutlineOutlinedIcon />,
                        handler: (id) => {
                            // deleteContent([issues[id].id]);
                        },
                    },
                ]}
            >
                <Tooltip title="Delete">
                    <IconButton
                        color="primary"
                        aria-label="Delete selected"
                        component="span"
                        // onClick={handleDeleteSelected}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </AdminTable>
            <div style={{ height: 400, width: "100%" }} label="Tree view">
                <DndProvider backend={HTML5Backend}>
                    <SortableTree
                        treeData={navAll}
                        onChange={(newTree) => {
                            setNavAll(newTree);
                        }}
                        searchMethod={
                            currentNav && currentNav.id
                                ? (searchData) => {
                                      const { node } = searchData;
                                      return node.contentId === currentNav.id;
                                  }
                                : undefined
                        }
                        onlyExpandSearchedNodes={true}
                        getNodeKey={getNodeKey}
                        onMoveNode={handleOnMoveNode}
                        dndType={externalNodeType}
                        generateNodeProps={(rowInfo) => ({
                            title: (
                                <TextField
                                    id="outlined-name"
                                    value={rowInfo.node.title}
                                    onChange={(event) => {
                                        const title = event.target.value;
                                        if (rowInfo.node.id === -1) {
                                            setNewNode({
                                                ...rowInfo.node,
                                                title,
                                            });
                                        }
                                        setNavAll(
                                            changeNodeAtPath({
                                                treeData: navAll,
                                                path: rowInfo.path,
                                                getNodeKey: getNodeKey,
                                                newNode: {
                                                    ...rowInfo.node,
                                                    title,
                                                },
                                            })
                                        );
                                    }}
                                />
                            ),
                            buttons: [
                                droppableTypes.includes(rowInfo.node.type) && (
                                    <Tooltip title="Add">
                                        <IconButton
                                            color="primary"
                                            aria-label="Add new category"
                                            component="span"
                                            onClick={() => {
                                                setCurrentRowInfo(rowInfo);
                                                setShowAddNodeDialog(true);
                                            }}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                ),
                                <Tooltip title="Delete">
                                    <IconButton
                                        color="primary"
                                        aria-label="Delete selected"
                                        component="span"
                                        onClick={() => handleDelete(rowInfo)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>,
                            ],
                        })}
                        canDrop={({ node, nextParent }) => {
                            if (!droppableTypes.includes(node.type)) {
                                // node is an article, blog or product
                            }
                            if (
                                !nextParent ||
                                droppableTypes.includes(nextParent.type)
                            ) {
                                // next parent is root node or is a container type, allow dropping
                                return true;
                            }
                        }}
                    />
                    {selected.length === 0 && newNode && (
                        <ExternalNode
                            onNodeDropped={() => {
                                // setIsNewNodeDropped(true);
                            }}
                            node={{
                                id: maxId + 1,
                                title: newNode.title,
                                contentId: newNode.id,
                                parent: null,
                                originalParent: null,
                                originalTitle: newNode.title,
                                type: newNode.type,
                                position: 0,
                                originalPosition: 0,
                                children: [],
                            }}
                        />
                    )}
                </DndProvider>
                <AddNodeDialog
                    title="New node"
                    contentText="Provide here new node name"
                    textLabel="node"
                    onSubmit={handleAddNode}
                    open={showAddNodeDialog}
                    onClose={() => {
                        setShowAddNodeDialog(false);
                    }}
                    validationList={[]}
                    errorText={{
                        empty: "Node name cannot be empty",
                        alreadyExists: "Node already exists",
                    }}
                    parentNodeTitle={
                        currentRowInfo &&
                        currentRowInfo.node &&
                        currentRowInfo.node.title
                            ? currentRowInfo.node.title
                            : ""
                    }
                />
            </div>
        </TabbedPage>
    );
};

export default NavigationStep;
