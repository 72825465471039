import React, { useState, useEffect, useCallback } from "react";
import RichTextEditor from "../Content/RichTextEditor";
import {
    Autocomplete,
    Stack,
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import Loader from "src/components/loader/Loader";

// Formik
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import { useDispatch } from "react-redux";
import { get, post } from "src/services/content.service";
import Page from "src/components/Page";

import { showAlert } from "../../../../redux/slices/AlertSlice";

const schema = Yup.object().shape({
    subject: Yup.string().min(3).max(2000).required("Required"),
    body: Yup.string().min(10).max(2000).required("Required"),
});

const AdminSendEmailForm = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);

    const dispatch = useDispatch();

    const showErrorMessage = useCallback(
        (error) => {
            dispatch(
                showAlert({
                    message: error,
                    severity: "error",
                })
            );
        },
        [dispatch]
    );

    const formik = useFormik({
        initialValues: {
            subject: "",
            body: "",
            recipientsFilter: "users",
            recipients: [],
        },
        validationSchema: schema,
        onSubmit: async (values, actions) => {
            const { subject, body, recipientsFilter, recipients } = values;

            const reqBody = JSON.stringify({
                subject: subject,
                body: body,
                recipientsFilter: recipientsFilter,
                recipients: recipients.map((recipient) => recipient.id),
            });
            setLoading(true);

            try {
                await post(dispatch, `/api/admin/email/send`, reqBody);
                dispatch(
                    showAlert({
                        message: "Email sent",
                        severity: "success",
                    })
                );
            } catch (err) {
            } finally {
                setLoading(false);
                actions.setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);

        const mapUserData = (data) => {
            return data
                .filter((data) => {
                    return data.is_active && data.email;
                })
                .map((data) => {
                    const firstLetter = data.email[0].toUpperCase();
                    return {
                        id: data.id,
                        name: data.name,
                        email: data.email,
                        firstLetter: /[0-9]/.test(firstLetter)
                            ? "0-9"
                            : firstLetter,
                    };
                });
        };
        const fetchUsers = async () => {
            try {
                setLoading(true);
                const res = await get(dispatch, `/api/accounts/users/`);
                setUsers(mapUserData(res.data));
            } catch (err) {
                // console.log(err);
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, []);

    const {
        errors,
        touched,
        values,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        getFieldProps,
    } = formik;

    return (
        <Page title={`Send admin email | TZMO DE Extranet`}>
            <Box sx={{ display: "flex" }}>
                {loading ? (
                    <Loader />
                ) : (
                    <FormikProvider value={formik}>
                        <Form
                            autoComplete="off"
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <Stack spacing={2}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                        Select Recipients
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        name="recipientsFilterRg"
                                        value={values.recipientsFilter}
                                        onChange={(event) => {
                                            setFieldValue(
                                                "recipientsFilter",
                                                event.currentTarget.value
                                            );
                                        }}
                                    >
                                        <FormControlLabel
                                            value="users"
                                            control={<Radio />}
                                            label="All Users"
                                        />
                                        <FormControlLabel
                                            value="admins"
                                            control={<Radio />}
                                            label="Only Admins"
                                        />
                                        <FormControlLabel
                                            value="specific"
                                            control={<Radio />}
                                            label="Specific users"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Autocomplete
                                    disabled={
                                        values.recipientsFilter !== "specific"
                                    }
                                    multiple
                                    limitTags={2}
                                    options={users.sort(
                                        (a, b) =>
                                            -b.firstLetter.localeCompare(
                                                a.firstLetter
                                            )
                                    )}
                                    groupBy={(user) => user.firstLetter}
                                    getOptionLabel={(user) => user.email}
                                    defaultValue={[]}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Recipient address"
                                            placeholder="Select recipients"
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        setFieldValue("recipients", value);
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Subject"
                                    {...getFieldProps("subject")}
                                    error={Boolean(
                                        touched.subject && errors.subject
                                    )}
                                    helperText={
                                        touched.subject && errors.subject
                                    }
                                />

                                <RichTextEditor
                                    value={formik.values.body}
                                    setValue={(val) =>
                                        formik.setFieldValue("body", val)
                                    }
                                    error={Boolean(touched.body && errors.body)}
                                />

                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Submit
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                )}
            </Box>
        </Page>
    );
};

export default AdminSendEmailForm;
