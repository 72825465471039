import React from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import TabLayoutArticles from "src/components/dashboard/articles/TabLayoutArticles";
import ContentList from "./ContentList";

const Articles = () => {
    const { category } = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const renderArticles = () => {
        const tab = searchParams.get("tab");
        const page = searchParams.get("page");

        if (tab !== null && tab !== undefined) {
            return <TabLayoutArticles category={category} tab={Number(tab)} />;
        } else if (page !== null && page !== undefined) {
            return (
                <ContentList
                    altSlug={category}
                    endpoint="/api/content/list/"
                    page={Number(page)}
                />
            );
        } else {
            // not found
            navigate("/404");
        }
    };

    return <div>{renderArticles()}</div>;
};

export default Articles;
