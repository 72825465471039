import React from "react";
import { FileDrop } from "react-file-drop";
import "./DragDropFiles.css";
import { styled } from "@mui/material/styles";

const RootStyle = styled("div")(({ theme }) => ({
    border: "1px solid black",
    width: 600,
    color: "black",
    padding: 20,
}));

const DragDropFiles = ({ text, onDrop, preview, showPreview }) => {
    return (
        <RootStyle>
            <FileDrop
                onDrop={(files, event) =>
                    onDrop
                        ? onDrop(files)
                        : console.log("onDrop!", files, event)
                }
            >
                {text}
            </FileDrop>
            {/* {preview && <img src={`data:image;base64,${preview.file}`} />} */}
            {showPreview && preview && <img src={preview.file} />}
        </RootStyle>
    );
};

export default DragDropFiles;
