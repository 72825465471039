import { useEffect, useState } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { checkAuthenticated } from "./redux/slices/Auth";

import DashboardLayout from "./layouts/dashboard";

import ContentDetail from "./pages/ContentDetail";
import ContentList from "./pages/ContentList";
import NotFound from "./pages/Page404";
import Admin from "./pages/Admin";
import NotificationsAll from "./pages/NotificationsAll";

import Articles from "./pages/Articles";
import Search from "./pages/Search";
import Loader from "src/components/loader/Loader";
import VerifyAccount from "./pages/VerifyAccount";

import LandingPage from "./pages/LandingPage";
import Profile from "./pages/Profile";
import Contact from "./pages/Contact";
import LicenseGenerator from "./pages/LicenseGenerator";

// ----------------------------------------------------------------------

export default function Router() {
    const { isAuthenticated, loading, user } = useSelector(
        (state) => state.auth
    );
    const [previousRoute, setPreviousRoute] = useState(null);
    const dispatch = useDispatch();
    const { pathname } = useLocation();

    const authRoute = (Component) => {
        if (loading || pathname !== previousRoute) {
            return <Loader />;
        } else if (isAuthenticated) {
            return Component;
        }
        return <Navigate to="/" />;
    };

    const adminRoute = (Component) => {
        if (loading || pathname !== previousRoute) {
            return <Loader />;
        } else if (isAuthenticated && user && user.is_staff) {
            return Component;
        }
        return <Navigate to="/" />;
    };
    useEffect(() => {
        const verifyAuthentication = async () => {
            if (previousRoute !== pathname) {
                setPreviousRoute(pathname);
                if (
                    !loading &&
                    (pathname.includes("/dashboard") ||
                        pathname.includes("/admin"))
                ) {
                    // check if pathname is the protected one to reduce request
                    try {
                        await dispatch(checkAuthenticated());
                    } catch (rejectedValueOrSerializedError) {}
                }
            }
        };
        verifyAuthentication();
    }, [pathname, previousRoute, dispatch, loading]);

    return useRoutes([
        {
            path: "/admin",
            element: adminRoute(<DashboardLayout />),
            children: [
                { path: "panel", element: <Admin /> },
                {
                    path: "activate/:uid",
                    element: <VerifyAccount />,
                },
            ],
        },
        {
            path: "/dashboard",
            element: authRoute(<DashboardLayout />),
            children: [
                {
                    path: "",
                    element: (
                        <ContentList
                            label="Aktuelles"
                            altSlug="news/"
                            endpoint="/api/content/"
                        />
                    ),
                },
                {
                    path: "contents/:category",
                    element: <Articles />,
                },
                {
                    path: "content/:slug",
                    element: (
                        <ContentDetail
                            backButton={true}
                            pageTitle="Article"
                            endpoint="/api/content/content/"
                        />
                    ),
                },
                {
                    path: "product/:slug",
                    element: (
                        <ContentDetail
                            backButton={true}
                            pageTitle="Product"
                            endpoint="/api/content/content/"
                        />
                    ),
                },
                {
                    path: "search/:searchPhrase",
                    element: <Search />,
                },
                { path: "notifications", element: <NotificationsAll /> },
                { path: "profile", element: <Profile /> },
                { path: "contact", element: <Contact /> },
                { path: "license", element: <LicenseGenerator /> },
            ],
        },
        {
            path: "",
            element: !isAuthenticated ? (
                <LandingPage />
            ) : (
                <Navigate to="/dashboard" />
            ),
        },
        {
            path: "/",
            element: !isAuthenticated ? (
                <LandingPage />
            ) : (
                <Navigate to="/dashboard" />
            ),
            children: [
                { path: ":authStep", element: <LandingPage /> },
                // {
                //     path: "register",
                //     element: <LandingPage />,
                // },
                // {
                //     path: "reset_password",
                //     element: <LandingPage />,
                // },
                {
                    path: "password/reset/confirm/:uid/:token",
                    element: <LandingPage />,
                },
                { path: "404", element: <NotFound /> },
                { path: "/", element: <Navigate to="/login" /> },
                { path: "*", element: <Navigate to="/404" /> },
            ],
        },
        { path: "*", element: <Navigate to="/404" replace /> },
    ]);
}
