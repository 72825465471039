import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SwipeableViews from "react-swipeable-views";
import { useNavigate, useLocation } from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const TabbedPage = ({ children, orientation, tab, dialog, ...other }) => {
    const theme = useTheme();
    const orient = orientation ? orientation : "horizontal";
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (!dialog) {
            window.history.pushState(
                "",
                "",
                `${location.pathname}?tab=${newValue + 1}`
            );
        }
    };

    useEffect(() => {
        if (children.length > 0) {
            if (tab && (tab < 1 || tab > children.length)) {
                navigate("/404");
            } else if (tab == null) {
                setValue(0);
                if (!dialog) {
                    window.history.pushState(
                        "",
                        "",
                        `${location.pathname}?tab=${1}`
                    );
                }
            } else {
                setValue(tab - 1);
            }
        }
    }, []);

    const handleChangeIndex = (index) => {
        setValue(index);
        if (!dialog) {
            window.history.pushState(
                "",
                "",
                `${location.pathname}?tab=${index + 1}`
            );
        }
    };

    return (
        <Box
            sx={{
                width: "100%",
                display: orient === "vertical" ? "flex" : "inline",
            }}
        >
            <Tabs
                orientation={orient}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                sx={{
                    borderBottom: orient === "horizontal" ? 1 : 0,
                    borderRight: orient === "horizontal" ? 0 : 1,
                    borderColor: "divider",
                }}
            >
                {React.Children.map(children || null, (child, index) => {
                    return (
                        <Tooltip
                            title={
                                child.props.tooltip ? child.props.tooltip : ""
                            }
                            placement="top"
                        >
                            <Tab
                                label={child.props.label}
                                {...a11yProps(index)}
                                key={index}
                            />
                        </Tooltip>
                    );
                })}
            </Tabs>
            <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {React.Children.map(children, (child, index) => {
                    return (
                        <TabPanel
                            value={value}
                            index={index}
                            key={index}
                            dir={theme.direction}
                        >
                            {child}
                        </TabPanel>
                    );
                })}
            </SwipeableViews>
        </Box>
    );
};

TabbedPage.propTypes = {
    children: PropTypes.node,
};

export default TabbedPage;
