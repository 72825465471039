import React, { useState, forwardRef, useImperativeHandle } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router";
import { resetPassword } from "src/redux/slices/Auth";

import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
// material
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loader from "src/components/loader/Loader";

// ----------------------------------------------------------------------

const ResetPasswordForm = forwardRef(({ setSubmitting }, ref) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email("Bitte geben Sie eine gültige E-Mail Addresse ein")
            .required("*Pflichtfeld"),
    });

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: LoginSchema,
        onSubmit: (values, actions) => {
            setLoading(true);
            setSubmitting(true);
            const { email } = values;
            dispatch(resetPassword({ email }))
                .unwrap()
                .then(() => {
                    setLoading(false);
                    setSubmitting(false);
                    actions.setSubmitting(false);
                    actions.resetForm();
                    navigate("/dashboard", { replace: true });
                })
                .catch(() => {
                    setLoading(false);
                    setSubmitting(false);
                    actions.setSubmitting(false);
                });
        },
    });

    const {
        errors,
        touched,
        values,
        isSubmitting,
        handleSubmit,
        getFieldProps,
    } = formik;

    useImperativeHandle(ref, () => ({
        submitForm() {
            formik.handleSubmit();
        },
    }));

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {loading ? (
                    <Loader />
                ) : (
                    <TextField
                        sx={{ mb: 1 }}
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="E-Mail Addresse"
                        {...getFieldProps("email")}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                )}
            </Form>
        </FormikProvider>
    );
});

export default ResetPasswordForm;
