// import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PropTypes from "prop-types";
// material
import {
    Menu,
    MenuItem,
    IconButton,
    ListItemIcon,
    ListItemText,
    Icon,
} from "@mui/material";

// ----------------------------------------------------------------------

export default function MoreMenu({ options }) {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon width={20} height={20}>
                    <MoreVertIcon />
                </Icon>
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: "100%" },
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            setIsOpen(false);
                            option.handler();
                        }}
                        sx={{ color: "text.secondary" }}
                    >
                        <ListItemIcon>
                            <Icon width={24} height={24}>
                                {option.icon}
                            </Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={option.label}
                            primaryTypographyProps={{ variant: "body2" }}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}

MoreMenu.propTypes = {
    id: PropTypes.number,
    onEditClicked: PropTypes.func,
    onDeleteClicked: PropTypes.func,
};
