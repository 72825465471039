import React, { useState } from "react";
import { Fab } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

import AdminTable from "../utlis/AdminTable";
import filesize from "filesize";
import saveAs from "file-saver";
import JSZipUtils from "jszip-utils";

import DownloadButton from "../utlis/DownloadButton";
import axios from "axios";
import { Box } from "@mui/material";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InputTextDialog from "./InputTextDialog";

const TABLE_HEAD = [
    { id: "FileName", label: "Dateiname", alignRight: false, search: true },
    { id: "FilePath", label: "Dateipfad", alignRight: false, search: false },
    { id: "FileSize", label: "Dateigröße", alignRight: false, search: false },
    { id: "Type", label: "Dateityp", alignRight: false, search: false },
];

const AttachmentsStep = ({
    showErrorMessage,
    upload,
    remove,
    modified,
    tableRows,
}) => {
    const [loading, setLoading] = useState(false);

    const { uploadedFiles, setUploadedFiles } = upload;
    const { removedFiles, setRemovedFiles } = remove;
    const { modifiedFiles, setModifiedFiles } = modified;
    const { rows, setRows } = tableRows;
    const [selected, setSelected] = useState([]);
    const [progress, setProgress] = useState({});
    const [showEditFileNameDialog, setShowEditFileNameDialog] = useState(false);
    const [editedFile, setEditedFile] = useState(null);

    const downloadFile = async (filePath, fileName) => {
        saveAs(`${axios.defaults.baseURL}${filePath}`, fileName);
    };

    function urlToPromise(url) {
        return new Promise(function (resolve, reject) {
            JSZipUtils.getBinaryContent(url, function (err, data) {
                if (err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    const onDownloadClicked = async (downloadOption) => {
        const filesToDownload = [];
        for (const file of rows) {
            const { FilePath, FileName } = file;
            const isItemSelected = selected.indexOf(FileName) !== -1;
            if (downloadOption === "all" || isItemSelected) {
                filesToDownload.push({
                    fileName: FileName,
                    filePath: FilePath,
                });
            }
        }
        if (filesToDownload.length === 1) {
            downloadFile(
                filesToDownload[0].filePath,
                filesToDownload[0].fileName
            );
        } else {
            const zip = require("jszip")();

            const zipFilename = "attachments.zip";
            for (const file of filesToDownload) {
                const url = `${axios.defaults.baseURL}${file.filePath}`;
                const filename = file.fileName;
                zip.file(filename, urlToPromise(url), { binary: true });
            }

            setProgress({
                show: true,
                value: 0,
            });
            // when everything has been downloaded, we can trigger the dl
            zip.generateAsync(
                { type: "blob" },
                function updateCallback(metadata) {
                    setProgress({
                        show: true,
                        value: metadata.percent.toFixed(2),
                    });
                }
            ).then(
                function callback(blob) {
                    // see FileSaver.js
                    saveAs(blob, zipFilename);
                    setProgress({
                        show: false,
                        value: 0,
                    });
                },
                function (e) {
                    console.log(e);
                }
            );
        }
    };

    const onRemovelicked = (removeOption) => {
        const filesToRemove = removedFiles;
        let tempRows = [...rows];

        tempRows.forEach(function callback(file, index) {
            const { Id, FileName } = file;
            const isItemSelected = selected.indexOf(FileName) !== -1;
            if (removeOption === "all" || isItemSelected) {
                if (!filesToRemove.includes(Id)) {
                    // Id -1 means that the file was recently uploaded but not yet send to server
                    if (Id !== -1) {
                        filesToRemove.push(Id);
                    } else {
                        //user wants to remove temporary uploaded file and prevent from being uploaded to the server
                        const idx = uploadedFiles.findIndex(
                            (file) => file.name === FileName
                        );
                        if (idx >= -1) {
                            setUploadedFiles((uploadedFiles) =>
                                uploadedFiles.splice(index, 1)
                            );
                        }
                    }

                    tempRows.splice(index, 1);
                }
            }
        });
        setRows(tempRows);
        setRemovedFiles(filesToRemove);
    };

    const fileOptions =
        selected.length > 0
            ? [
                  {
                      name: "all",
                      label: "Alles herunterladen",
                      handler: onDownloadClicked,
                  },
                  {
                      name: "selected",
                      label: "Download selected",
                      handler: onDownloadClicked,
                  },
                  {
                      name: "remove",
                      label: "Remove selected",
                      handler: onRemovelicked,
                  },
                  {
                      name: "removeAll",
                      label: "Remove all",
                      handler: onRemovelicked,
                  },
                  {
                      name: "clear",
                      label: "Clear",
                      handler: () => {
                          setSelected([]);
                      },
                  },
              ]
            : [
                  {
                      name: "all",
                      label: "Download all",
                      handler: onDownloadClicked,
                  },
                  {
                      name: "removeAll",
                      label: "Remove all",
                      handler: onRemovelicked,
                  },
              ];

    const uploadFile = async (e) => {
        let file = e.target.files[0];
        const name = file.name.replace(/\s+/g, "_").toLowerCase();
        const size = file.size;
        const type = file.type;
        const toBase64 = (file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        setLoading(true);
        try {
            const originalFile = file;
            file = await toBase64(file);
            let uploaded = uploadedFiles;
            uploaded.push({
                name: name,
                size: size,
                type: type,
                file: file,
            });
            setUploadedFiles(uploaded);
            setRows((rows) => [
                ...rows,
                {
                    Id: -1,
                    FileName: name,
                    FilePath: "",
                    FileSize: filesize(size),
                    Type: type,
                    File: originalFile,
                },
            ]);
        } catch (err) {
            showErrorMessage(err);
        } finally {
            setLoading(false);
        }
    };

    const editFileName = (id) => {
        setEditedFile(rows[id]);
        setShowEditFileNameDialog(true);
    };

    const deleteFile = (index) => {
        const filesToRemove = removedFiles;
        let tempRows = [...rows];
        const file = rows[index];
        const { Id, FileName } = file;
        if (!filesToRemove.includes(Id)) {
            // Id -1 means that the file was recently uploaded but not yet send to server
            if (Id !== -1) {
                filesToRemove.push(Id);
            } else {
                //user wants to remove temporary uploaded file and prevent from being uploaded to the server
                const idx = uploadedFiles.findIndex(
                    (file) => file.name === FileName
                );
                if (idx >= -1) {
                    setUploadedFiles((uploadedFiles) =>
                        uploadedFiles.splice(index, 1)
                    );
                }
            }

            tempRows.splice(index, 1);
        }
        setRows(tempRows);
        setRemovedFiles(filesToRemove);
    };

    const handleFileNameChanged = (value) => {
        let tempRows = [...rows];
        let tempUploaded = [...uploadedFiles];

        const upIdx = uploadedFiles.findIndex(
            (file) => file.name === editedFile.FileName
        );

        const rowIdx = rows.findIndex(
            (file) => file.FileName === editedFile.FileName
        );

        if (upIdx > -1) {
            tempUploaded[upIdx].name = value;
            setUploadedFiles(tempUploaded);
        }

        if (rowIdx > -1) {
            tempRows[rowIdx].FileName = value;
            setRows(tempRows);
        }

        if (editedFile.Id > -1) {
            // editting already uploaded file. Add or edit modified list
            let tempModified = [...modifiedFiles];
            let modIdx = -1;
            if (tempModified.length > 0) {
                modIdx = tempModified.findIndex(
                    (file) => file.Id === editedFile.Id
                );
            }
            if (modIdx > -1) {
                tempModified[modIdx].FileName = value;
            } else {
                tempModified.push({ Id: editedFile.Id, FileName: value });
            }
            setModifiedFiles(tempModified);
        }

        setEditedFile(null);
    };

    return (
        <>
            <InputTextDialog
                title="Edit file name"
                initialValue={editedFile ? editedFile.FileName : ""}
                open={showEditFileNameDialog}
                onClose={() => {
                    setShowEditFileNameDialog(false);
                }}
                onSubmit={handleFileNameChanged}
                validationList={rows
                    .filter((row) => {
                        if (editedFile) {
                            return editedFile.FileName !== row.FileName;
                        }
                        return true;
                    })
                    .map((row) => row.FileName)}
                errorText={{
                    empty: "File name cannot be empty",
                    alreadyExists: "Already exists",
                }}
            />
            <Box mt={2} sx={{ height: "100%", width: "100%" }}>
                <AdminTable
                    key={rows.length}
                    searchPlaceholder="Suche..."
                    headers={TABLE_HEAD}
                    rows={rows}
                    selection={{
                        selected: selected,
                        setSelected: setSelected,
                    }}
                    progress={progress}
                    contextMenuOptions={[
                        {
                            label: "Edit file name",
                            icon: <EditOutlinedIcon />,
                            handler: (id) => {
                                editFileName(id);
                            },
                        },
                        {
                            label: "Delete",
                            icon: <DeleteOutlineOutlinedIcon />,
                            handler: (id) => {
                                deleteFile(id);
                            },
                        },
                    ]}
                >
                    <label htmlFor="upload-file">
                        <input
                            style={{ display: "none" }}
                            id="upload-file"
                            name="upload-file"
                            type="file"
                            onChange={uploadFile}
                        />
                        <Fab
                            color="primary"
                            size="small"
                            component="span"
                            aria-label="add"
                            // variant="extended"
                        >
                            <UploadFileIcon />
                        </Fab>
                    </label>
                    <DownloadButton options={fileOptions} />
                </AdminTable>
            </Box>
        </>
    );
};

export default AttachmentsStep;
