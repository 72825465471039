import { useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";

import Alert from "src/utils/Alert";
import AdminSpeedDial from "src/components/dashboard/admin/Content/AdminSpeedDial";
import PopupPreview from "../../components/dashboard/admin/Popup/PopupPreview";
import { useDispatch, useSelector } from "react-redux";
import { hideOfferPopup } from "src/redux/slices/Auth";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("lg")]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    const [open, setOpen] = useState(false);
    const { popup } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    return (
        <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar
                isOpenSidebar={open}
                onCloseSidebar={() => setOpen(false)}
            />
            <MainStyle>
                <Alert />
                <AdminSpeedDial />
                {popup && (
                    <PopupPreview
                        open={popup.active}
                        onClose={() => {
                            dispatch(hideOfferPopup());
                        }}
                        data={popup}
                    />
                )}
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
