import React from "react";
// material
import { Paper } from "@mui/material";
import { Box } from "@mui/system";

const ContentCard = ({ children }) => (
    <Paper elevation={3} sx={{ mb: 5 }}>
        <Box p={2}>{children}</Box>
    </Paper>
);

export default ContentCard;
