import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmDialog = ({ title, text, onSubmit, open, onClose }) => {
    // const handleSubmit = () => {
    //     onSubmit();
    //     onClose();
    // };

    const handleSubmit = useCallback(() => {
        onSubmit();
    }, [onSubmit]);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Abbrechen</Button>
                <Button
                    onClick={() => {
                        handleSubmit();
                    }}
                >
                    Bestätige
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmDialog;
