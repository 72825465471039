import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import { styled } from "@mui/material/styles";
import {
    Box,
    Toolbar,
    Typography,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(["box-shadow", "width"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
    "& fieldset": {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    },
}));

// ----------------------------------------------------------------------

SearchList.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    onFilterName: PropTypes.func,
    children: PropTypes.object,
};

export default function SearchList({
    numSelected,
    filterName,
    placeholder,
    onFilterName,
    onClearPressed,
    children,
}) {
    return (
        <RootStyle
            sx={{
                ...(numSelected > 0 && {
                    color: "primary.main",
                    bgcolor: "primary.lighter",
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} ausgewählt
                </Typography>
            ) : (
                <SearchStyle
                    value={filterName}
                    onChange={onFilterName}
                    placeholder={placeholder}
                    startAdornment={
                        <InputAdornment position="start">
                            <Box
                                component={Icon}
                                icon={searchFill}
                                sx={{ color: "text.disabled" }}
                            />
                        </InputAdornment>
                    }
                />
            )}
            {children}
        </RootStyle>
    );
}
