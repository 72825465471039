import React, { useState, useEffect, useCallback } from "react";
import RichTextEditor from "../Content/RichTextEditor";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import Loader from "src/components/loader/Loader";

// Formik
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";

import { useDispatch } from "react-redux";
import { get, put } from "src/services/content.service";
import Page from "src/components/Page";

import { showAlert } from "../../../../redux/slices/AlertSlice";

const schema = Yup.object().shape({
    // signature: Yup.string().min(3).max(2000).required("Required"),
});

const EmailSignature = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const showErrorMessage = useCallback(
        (error) => {
            dispatch(
                showAlert({
                    message: error,
                    severity: "error",
                })
            );
        },
        [dispatch]
    );

    const formik = useFormik({
        initialValues: { signature: "" },
        validationSchema: schema,
        onSubmit: async (values, actions) => {
            const { signature } = values;
            setLoading(true);

            const body = JSON.stringify({ signature });
            setLoading(true);

            try {
                await put(dispatch, `/api/admin/email/signature`, body);
                dispatch(
                    showAlert({
                        message: "Signature updated",
                        severity: "success",
                    })
                );
            } catch (err) {
            } finally {
                setLoading(false);
                actions.setSubmitting(false);
            }
        },
    });

    const { errors, touched, handleSubmit, isSubmitting } = formik;

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchSignature = async () => {
            try {
                setLoading(true);
                const res = await get(dispatch, `/api/admin/email/signature`);
                formik.setFieldValue("signature", res.data.body);
                // setSignature(res.data.body);
            } catch (err) {
                // console.log(err);
            } finally {
                setLoading(false);
            }
        };
        fetchSignature();
    }, []);

    return (
        <Page title={`E-Mail Signature | TZMO DE Extranet`}>
            <Box sx={{ display: "flex" }}>
                {loading ? (
                    <Loader />
                ) : (
                    <FormikProvider value={formik}>
                        <Form
                            autoComplete="off"
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <Stack spacing={2}>
                                <RichTextEditor
                                    value={formik.values.signature}
                                    setValue={(val) =>
                                        formik.setFieldValue("signature", val)
                                    }
                                />

                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                >
                                    Submit
                                </LoadingButton>
                            </Stack>
                        </Form>
                    </FormikProvider>
                )}
            </Box>
        </Page>
    );
};

export default EmailSignature;
