import { configureStore } from "@reduxjs/toolkit";
import alertReducer from "./slices/AlertSlice";
import authReducer from "./slices/Auth";
import contentReducer from "./slices/Content";

export default configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
        content: contentReducer,
    },
    devTools: true,
});
